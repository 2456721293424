import { Outlet } from 'react-router-dom'

import { SideBar } from './../../components/SideBar'
import { PrivateRoute } from '../../routes/PrivateRoute'

const Layout: React.FC = () => (
  <div className="w-screen h-screen flex flex-col lg:flex-row">
    {/* <PrivateRoute> */}
    <SideBar />
    {/* </PrivateRoute> */}
    <Outlet />
  </div>
)

export { Layout }
