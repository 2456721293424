import React, { ReactNode } from 'react'

interface PageContainerProps {
  children: ReactNode
  className?: string
}

const PageContainer: React.FC<PageContainerProps> = ({
  children,
  className,
}) => {
  return (
    <div
      className={`w-full p-4 lg:px-6 lg:py-8 bg-[#F3F3F3] flex justify-items-center ${className} overflow-y-auto`}
    >
      {children}
    </div>
  )
}

export { PageContainer }
