import React, { ReactNode } from 'react'
import '../styles/scrollbar.css'
import { X } from '@phosphor-icons/react'

interface ModalRightProps {
  title: string
  open: boolean | boolean
  setIsOpenController: () => void
  children: ReactNode
}

const ModalRight: React.FC<ModalRightProps> = ({
  title,
  open,
  setIsOpenController,
  children,
}) => {
  if (!open) return null
  return (
    <div className="absolute left-0 top-0 bottom-0 right-0 z-10 flex w-full justify-end modalTransform">
      <div
        onClick={setIsOpenController}
        className="hidden lg:block absolute left-0 top-0 bottom-0 right-0 z-10 opacity-20 bg-[black]"
      ></div>
      <div className="bg-[#EFEFFF] z-20 p-3 lg:p-6 lg:max-w-[60%] w-full overflow-scroll scroll">
        <div className="flex flex-col bg-white rounded-md p-2 lg:p-4">
          <div className="flex items-center justify-between">
            <span className="text-black text-md"> {title}</span>
            <div
              onClick={setIsOpenController}
              className="cursor-pointer bg-lightGray p-1 rounded-md"
            >
              <X color="#005588" size={30} />
            </div>
          </div>
          {children}
        </div>
      </div>
    </div>
  )
}

export { ModalRight }
