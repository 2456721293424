import React, { useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import api from '../services/api'
import { ButtonLoading } from '../components/ButtonLoading'
import { ArrowLeft } from '@phosphor-icons/react'
import { useVerifyRefCode } from '../components/hooks/queries/useRegister'
import { I18nComponent } from '../components/I18n/I18n'
import { Translator } from '../components/I18n/Translator'
import { useAuth } from '../components/hooks/provider/auth'
import packageJson from '../../package.json'

interface SignUpFormData {
  name: string
  lastname: string
  nickname: string
  gender: string
  email: string
  password: string
  ref_by_user_id: string
}

interface IResultRegister {
  data: {
    name: string
    lastname: string
    nickname: string
    gender: string
    email: string
    avatar?: string
    password: string
  }
}

const Register: React.FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const navigate = useNavigate()
  const { id } = useParams()
  const { login } = useAuth()

  const { data: verifyRefCode, isLoading: loadingRequest } = useVerifyRefCode(
    String(id),
  )

  const [page, setPage] = useState(1)

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<SignUpFormData>()

  const onSubmit: SubmitHandler<SignUpFormData> = async ({
    name,
    lastname,
    nickname,
    email,
    gender,
    password,
  }) => {
    if (page === 2) {
      setIsLoading(true)
      const returnRegister = (await api
        .post('/v1/register', {
          name,
          lastname,
          nickname,
          email,
          gender,
          password,
          ref_by_user_id: id,
        })
        .then((returnRegister) => {
          setIsLoading(false)
          return returnRegister
        })
        .catch((err) => {
          const { message, statusCode } = err.response.data
          // toast error information
          toast.error(`${statusCode} - ${message}`)
          setIsLoading(false)
          // redirect user to invite page
          navigate(`/invite/${id}`)
        })) as IResultRegister

      if (!returnRegister.data.name) {
        toast.error(<Translator path="invitePage.error" />)
        setIsLoading(false)
        navigate('/register')
      }

      login({ email, password })

      navigate('/selfKnowledge')
      toast.success(<Translator path="invitePage.success" />)
      setIsLoading(false)
    } else {
      setPage(2)
    }
  }

  return verifyRefCode ? (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="w-screen h-screen flex flex-col items-center justify-center gap-4 px-5 lg:px-0">
        <div className="overflow-y-hidden h-24 lg:h-28 flex items-center justify-center">
          <img
            className="w-96 lg:w-[30rem]"
            src={'/assets/LogoIPersonality.svg'}
            alt="logo"
          />
        </div>
        <span className="text-lg font-bold m-2">
          <Translator path="invitePage.title" />
        </span>
        <I18nComponent />
        {page === 1 ? (
          <>
            <div className="w-full lg:w-1/4  flex flex-col">
              <span>
                <Translator path="invitePage.name" />
              </span>
              <input
                className="h-11 my-1 px-3 border border-gray rounded-md focus:outline-none"
                placeholder="Insira seu nome"
                type="text"
                id="name"
                {...register('name', { required: true })}
              />
              {errors.name && (
                <span className="text-red text-xs">
                  <Translator path="invitePage.errorName" />
                </span>
              )}
            </div>
            <div className="w-full lg:w-1/4 flex flex-col">
              <span>
                <Translator path="invitePage.lastName" />
              </span>
              <input
                className="h-11 my-1 px-3 border border-gray rounded-md focus:outline-none"
                placeholder="Insira seu sobrenome"
                type="text"
                id="lastname"
                {...register('lastname', { required: true })}
              />
              {errors.lastname && (
                <span className="text-red text-xs">
                  <Translator path="invitePage.errorLastName" />
                </span>
              )}
            </div>
            <div className="w-full lg:w-1/4 flex flex-col">
              <span>
                <Translator path="invitePage.emailAddress" />
              </span>
              <input
                className="h-11 my-1 px-3 border border-gray rounded-md focus:outline-none"
                placeholder="Insira o seu endereço de email"
                type="text"
                id="email"
                {...register('email', { required: true })}
              />
              {errors.email && (
                <span className="text-red text-xs">
                  <Translator path="invitePage.errorEmail" />
                </span>
              )}
            </div>
            <div className="w-full lg:w-1/4 flex flex-col ">
              <div className="flex items-center justify-between">
                <span>
                  <Translator path="invitePage.password" />
                </span>
                {/* <span
                      className="text-mediumBlue font-bold cursor-pointer"
                      onClick={() => {
                        window.location.href = 'http://localhost:5173/forgotPassword'
                      }}
                    >
                      Forgot Password?
                    </span>
                */}
              </div>
              <input
                className="h-11 my-1 px-3 border border-gray rounded-md focus:outline-none"
                placeholder="Insira sua senha"
                type="password"
                id="password"
                {...register('password', { required: true })}
              />
              {errors.password && (
                <span className="text-red text-xs">
                  <Translator path="invitePage.errorPassword" />
                </span>
              )}
            </div>
          </>
        ) : (
          <>
            <div className="w-full lg:w-1/4 flex flex-col">
              <span>
                <Translator path="invitePage.nickname" />
              </span>
              <input
                className="h-11 my-1 px-3 border border-gray rounded-md focus:outline-none"
                placeholder="Insira seu nickname"
                type="text"
                id="nickname"
                {...register('nickname', { required: true })}
              />
            </div>
            <div className="w-full lg:w-1/4 flex flex-col">
              <span className="text-md leading-7">
                <Translator path="invitePage.gender" />
              </span>
              <div className="flex gap-2">
                <div className="w-full lg:w-1/2 flex items-center p-2 space-x-3 border border-gray rounded cursor-pointer">
                  <input
                    id="male"
                    type="radio"
                    value="male"
                    required
                    {...register('gender', { required: true })}
                  />
                  <label
                    htmlFor="male"
                    className="w-full text-md cursor-pointer"
                  >
                    <Translator path="invitePage.male" />
                  </label>
                </div>

                <div className="w-full lg:w-1/2 flex items-center p-2 space-x-3 border border-gray rounded cursor-pointer">
                  <input
                    id="female"
                    type="radio"
                    value="female"
                    required
                    {...register('gender', { required: true })}
                  />
                  <label
                    htmlFor="female"
                    className="w-full text-md cursor-pointer"
                  >
                    <Translator path="invitePage.female" />
                  </label>
                </div>
              </div>
            </div>
          </>
        )}

        {page === 1 ? (
          <button
            type="submit"
            className="w-full lg:w-1/4 bg-lightBlue px-20 font-semibold py-2 rounded-md text-white hover:bg-lightBlueHover"
          >
            <Translator path="invitePage.btnNext" />
          </button>
        ) : (
          <div className="w-full lg:w-1/4 flex gap-2">
            <button
              onClick={() => setPage(1)}
              className="px-4 lg:px-0 lg:w-1/4 flex justify-center items-center bg-buttonRed font-semibold py-2 rounded-md text-white hover:bg-darkRed"
            >
              <ArrowLeft size={32} />
            </button>
            <ButtonLoading
              isLoading={isLoading}
              title={<Translator path="invitePage.btnRegister" />}
              css_class="w-full lg:w-3/4 bg-lightBlue font-semibold py-2 rounded-md text-white hover:bg-lightBlueHover"
            />
          </div>
        )}

        <div className="flex gap-2 items-center">
          <span>© {new Date().getFullYear()} IPersonality</span>
          <span className="text-sm">V: {packageJson.version}</span>
        </div>
      </div>
    </form>
  ) : (
    <div className="flex h-screen justify-center items-center">
      {loadingRequest ? (
        <svg
          aria-hidden="true"
          className="inline max-w-[5rem] max-h-[5rem] mr-2 text-darkGray animate-spin dark:text-gray-600 fill-blue-600"
          viewBox="0 0 100 101"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
            fill="currentColor"
          />
          <path
            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
            fill="#4b5563"
          />
        </svg>
      ) : (
        <span className="text-4xl text-darkGray font-semibold">
          <Translator path="invitePage.errorPage" />
        </span>
      )}
    </div>
  )
}

export { Register }
