export default {
  translations: {
    login: {
      title: 'Ingrese a su cuenta',
      email: 'Dirección de correo electrónico',
      errorEmail: 'El correo electrónico es obligatorio',
      password: 'Contraseña',
      errorPassword: 'La contraseña es obligatoria',
      forgotPassword: '¿Olvido la contraseña?',
      btn: 'Entrar',
    },
    invitePage: {
      title: 'Regístrate para crear tu cuenta',
      name: 'Nombre',
      errorName: 'El nombre es obligatorio',
      lastName: 'Apellido',
      errorLastName: 'El apellido es obligatorio',
      emailAddress: 'Correo electrónico',
      errorEmail: 'El correo electrónico es obligatorio',
      password: 'Contraseña',
      errorPassword: 'La contraseña es obligatoria',
      nickname: 'Apodo',
      gender: 'Género',
      male: 'Masculino',
      female: 'Femenina',
      errorPage: 'No se encontró la referencia de la invitación.',
      btnNext: 'Próximo',
      btnRegister: 'Inscribirse',
      error: '¡Error al crear el registro!',
      success: '¡Registro completado exitosamente!',
    },
    sideBar: {
      plan: 'Plan',
      home: 'Comenzar',
      profile: 'Perfil',
      selfKnowledge: 'Autoconocimiento',
      documents: 'Documentos',
      developYourself: 'Desarrollate',
      community: 'Comunidad',
      chat: 'Chat',
      marketplace: 'Mercado',
      events: 'Eventos',
      financial: 'Financiero',
      myConscience: 'Mi conciencia',
      careerPath: 'Plano de carrera',
      logout: 'Salir',
    },
    home: {
      dashboard: 'Panel',
      cardOne: 'Total de evaluaciones primarias y (%) respondidas',
      cardTwo: 'Reseñas totales por invitación (%) respondidas',
      cardThree: 'Para recibir el perfil IPersonality',
      cardFour: 'Σ de transacciones registradas',
      graphic: 'Cantidad y % de usuarios',
      requestError:
        'Se produjo un error al obtener los datos, inténtelo nuevamente más tarde.',
      msg: 'Datos no encontrados',
      salutation: 'Bienvenida',
      videoTitle: 'Vídeo de bienvenida',
      inviteTitle:
        'Ayude a más personas a descubrirse a sí mismas compartiendo su enlace a continuación.',
      errorShare:
        'Se produjo un error al generar el enlace para compartir. Por favor, inténtelo de nuevo más tarde.',
    },
    profile: {
      title: 'Perfil',
      typeAccess: 'Tipo de acceso/graduación',
      name: 'Nombre',
      surname: 'Apelido',
      nickname: 'Apodos',
      informationNickname:
        ' *Este es el nombre que se mostrará a otros usuarios y en los informes generados por la plataforma.',
      gender: 'Género',
      genderMen: 'Masculino',
      genderWoman: 'Femenino',
      email: 'Correo electrónico',
      code: 'Código de afiliación',
      theCode: 'Código',
      informationCode:
        'se utilizará para identificar a los usuarios que invite a la plataforma.',
      buttonCancel: 'Cancelar',
      buttonSave: 'Ahorrar',
      buttonUpdate: 'Actualizar',
      success: 'perfil actualizado con éxito',
      avatar: 'Avatar actualizado exitosamente',
      errorAvatar: 'Error al actualizar avatar',
    },
    selfKnowledge: {
      initial: {
        title: '¡Descubre tu código cognitivo conductual!',
        description: 'Inicie el inventario ahora y descubra sus rasgos únicos',
        btn: 'Descubrir',
      },
      introduction: {
        hello: 'Hola, ',
        firstText:
          'Código Cognitivo Conductual',
        secondText:
          'Cuando EMPIEZAS, puedes explorar 10 grupos de palabras con cuatro adjetivos diferentes en cada grupo;',
        thirdText:
          'En cada pantalla, puntúa los adjetivos del 1 al 4 y clasifícalos según los siguientes criterios:',
        list: {
          one: 'Puntúa 01 en 1º lugar para el adjetivo que mejor te describe.',
          two: 'Puntúa 02 en 2º lugar para el adjetivo que mejor te identifique en secuencia.',
          three:
            'Puntúa 03 en 3º lugar y puntúa 04 para el 4º y último adjetivo que menos te describe.',
          four: '',
        },
        fourthText:
          'Si lo necesitas, haz clic en el icono 🕕 para leer el significado del adjetivo en caso de duda.',
        btn: 'Comenzar',
      },
      form: {
        step: 'Paso',
        btnHowAnswer: 'como responder',
        btnNext: 'Siguiente sección',
        btnBack: 'Volver',
        success: 'Cuestionario completado con éxito',
        error:
          'Se produjo un error al completar el cuestionario, inténtelo nuevamente o comuníquese con nuestro soporte.',
        howAnswer: {
          title: '¿Cómo responder el cuestionario?',
          step: {
            one: 'En cada pantalla se presentarán 4 adjetivos para que los enumeres según tu grado de identificación.',
            two: 'En cada pantalla se presentarán 10 adjetivos para que los enumeres según tu grado de identificación.',
          },
          textOne: 'Cada número corresponde a un grado de relevancia, siendo:',
          list: {
            veryRelevant: 'Muy relevante',
            relevant: 'Relevante',
            someRelevance: 'Alguna relevancia',
            lowRelevance: 'Baja relevancia',
          },
          textTwo:
            'Selecciona un número <b>haciendo clic o tocando el botón</b> correspondiente a tu grado de identificación con el adjetivo. Para deshacer la selección, simplemente haga clic o toque el botón nuevamente.',
          textThree:
            'Si tienes dudas sobre el significado de un adjetivo, haz clic en el icono 🕕 para conocer la definición y comprobar si es el adjetivo que mejor te identifica.',
          btn: 'Cerrar',
        },
      },
      nextStage: {
        first: {
          firstText:
            'Responde de nuevo al mapeo de características, pero esta vez retratando cómo crees que las personas que conviven contigo en tu día a día personal y profesional necesitan que seas para tener una mejor conexión, rendimiento y resultados.',
          secondText:
            'Ahora, el propósito es puntuar las características de 1 a 4 en la posición que retrata cómo OTROS esperan que seas y actúes.',
        },
        second: {
          firstText:
            'A partir de ahora, te traemos 10 etapas, cada una con 10 colocaciones que puedes puntuar del 1 al 10 de la mejor manera que creas que debes para retratar tus motivadores. Siempre clasificando del 1, primero y más alto (más importante) al 10, último y más bajo (menos relevante).',
          secondText:
            'Piensa exactamente qué es lo significativo en tu vida, los principios y valores que utilizas como brújula para tomar tus decisiones, lo que te motiva y guía tus acciones diarias.',
        },
        btnStart: 'Comezar',
        btnBack: 'Volver',
      },
      result: {
        title: 'Código cognitivo conductual',
        description:
          'Descarga gratis tu Código Cognitivo Conductual en PDF haciendo clic en el botón de abajo',
        btnTitle: 'Informe',
        btnLoading: 'Generando su informe',
        btnCreated: 'Descarga tu informe',
        videoTitle: 'Autoconocimiento',
      },
    },
    developYourself: {
      title: 'Desarrollate',
      noPlan: 'No se encontraron planes',
      requestError:
        'Hubo un error al buscar planos. Por favor, inténtelo de nuevo más tarde.',
      purchase: 'Compra',
      acquired: 'Adquirido',
    },
    community: {
      community: 'Comunidad',
      select: 'Seleccione la opción para filtrar',
      notStarted: 'NO EMPEZADO',
      inProgress: 'EN PROGRESO',
      concluded: 'CONCLUIDO',
      name: 'Nombre',
      userPlan: 'Plan de usuario',
      testStatus: 'Avance',
      seeData: 'Ver datos',
      loading: 'Cargando...',
      yourCommunity: 'Tu comunidad',
      inviteTitle: 'Haz conexiones',
      inviteDescription:
        'Haz clic aquí y selecciona un usuario para chatear dentro de la plataforma',
      noUser:
        'No tienes un usuario en tu comunidad, usa tu enlace de invitación para invitar a nuevos usuarios.',
      requestError:
        'Hubo un error al buscar comunidades. Por favor, inténtelo de nuevo más tarde.',
      modal: {
        name: 'Nombre de usuario',
        seeResult: 'Ver el estado del informe',
        testDone: 'Autoconocimiento realizado',
        testNotDone: 'Autoconocimiento incumplido',
        status: 'Autoconocimiento incumplido',
        info: 'Información y datos de tu comunidad',
        errorInfo:
          'No tienes un usuario en tu comunidad, usa tu enlace de invitación para invitar a nuevos usuarios.',
      },
    },
    recoverPassword: {
      redefinePassword: 'Redefinir contraseña',
      emailAddress: 'Correo electrónico',
      password: 'Contraseña',
      confirmPassword: 'Confirmar seña',
      changePassword: 'Cambiar contraseña',
      success: '¡Contraseña actualizada exitosamente!',
    },
    forgotPassword: {
      title: 'Recuperar contraseña',
      email: 'Dirección de correo electrónico',
      btnRecover: 'Enviar enlace de recuperación',
      questionRemember: '¿Recordaste la contraseña?',
      return: 'Ir a iniciar sesión',
      success:
        'Se te ha enviado un correo electrónico para recuperar tu contraseña.',
    },
    component: {
      inviteCard: {
        info: 'Enlace copiado al portapapeles',
        error:
          'Se produjo un error al generar el enlace para compartir. Por favor, inténtelo de nuevo más tarde.',
      },
      privateRoute: 'Error: ¡Debes iniciar sesión para acceder a esta ruta!',
    },
  },
}
