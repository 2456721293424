/* eslint-disable prettier/prettier */
export const questionES = ([
  ['step', 'type', 'name', 'description'],
  [3,'KNO','Aprender cosas nuevas',null],
  [3,'PRO','Trabajar duro para construir una carrera exitosa',null],
  [3,'EXP','Apreciar la naturaleza, las artes y aprender sobre nuevas culturas',null],
  [3,'CON','Compartir recursos, tiempo y habilidades para ayudar a otros',null],
  [3,'ACH','Lograr cosas significativas en la vida',null],
  [3,'PUR','Entender tu propósito y el sentido de la vida',null],
  [3,'SPI','Expresar mis creencias y experimentar la verdadera espiritualidad',null],
  [3,'FRE','Pensar fuera de la caja en busca de nuevas ideas y perspectivas',null],
  [3,'JUS','Hacer lo que es correcto y justo en todas las situaciones',null],
  [3,'HEA','Mantener una dieta equilibrada y saludable con atención en la calidad de los alimentos',null],
  [3,'KNO','Descubrir cómo funcionan las cosas',null],
  [3,'PRO','Tener una vida financiera exitosa',null],
  [3,'EXP','Vivir nuevas experiencias en mi vida',null],
  [3,'CON','Contribuir con causas sociales',null],
  [3,'ACH','Dejar un legado y hacer una diferencia en el mundo',null],
  [3,'PUR','Dar significado a tus elecciones y acciones',null],
  [3,'SPI','Buscar el sentido más profundo de la existencia humana',null],
  [3,'FRE','Valoro la creatividad, la capacidad de cambiar de opinión o planes si es necesario',null],
  [3,'JUS','Actuar siempre con un fuerte sentido de ética y moralidad',null],
  [3,'HEA','Dedicar tiempo y recursos para mantener una buena higiene personal y salud bucal',null],
  [3,'KNO','Adquirir nuevos conocimientos',null],
  [3,'PRO','Aumentar mis ingresos invirtiendo en buenas oportunidades',null],
  [3,'EXP','Vivir cada momento y disfrutar de las experiencias que ofrece la vida',null],
  [3,'CON','Participar en actividades voluntarias y acciones sociales',null],
  [3,'ACH','Liderar a las personas hacia objetivos audaces',null],
  [3,'PUR','Encontrar mi propósito, tengo un fuerte interés en conocerme aún más',null],
  [3,'SPI','Tener una rutina de meditación y oración para conectarse con lo divino',null],
  [3,'FRE','Facilidad para adaptarme a nuevas situaciones ya que no tengo apego a cosas',null],
  [3,'JUS','Luchar contra las desigualdades e injusticias sociales, en favor de causas justas e igualitarias',null],
  [3,'HEA','Evitar el uso de sustancias nocivas, como drogas, alcohol y tabaco',null],
  [3,'KNO','Ser un líder que le gusta aprender y enseñar',null],
  [3,'PRO','Ser un líder productivo en busca de más resultados en menos tiempo',null],
  [3,'EXP','Ser un líder que valora experiencias enriquecedoras',null],
  [3,'CON','Ser un líder que sirve a sus seguidores y contribuye a la sociedad',null],
  [3,'ACH','Ser un líder que sabe inspirar y motivar a otros a seguir objetivos audaces',null],
  [3,'PUR','Ser un líder con un alto sentido de propósito arraigado en mis valores',null],
  [3,'SPI','Ser un líder que valora cuestiones relacionadas con la espiritualidad',null],
  [3,'FRE','Ser un líder que valora la libertad de elección e individualidades de sus seguidores',null],
  [3,'JUS','Ser un líder que valora la integridad, optando por lo correcto y justo en todas las situaciones',null],
  [3,'HEA','Ser un líder que se preocupa por el bienestar y la salud física y mental de los seguidores',null],
  [3,'KNO','Estar en busca de nuevas teorías',null],
  [3,'PRO','Expandir mis ganancias significativamente',null],
  [3,'EXP','Viajar a lugares que me permitan vivir experiencias culturales y artísticas',null],
  [3,'CON','Dedicar tiempo y recursos en apoyo a acciones comunitarias',null],
  [3,'ACH','Seguir una visión a largo plazo sabiendo que las acciones y decisiones de hoy afectarán el futuro',null],
  [3,'PUR','Vivir en una búsqueda constante de un propósito mayor en la vida',null],
  [3,'SPI','Entender la profundidad de diferentes creencias espirituales',null],
  [3,'FRE','Conocer lo nuevo a través de personas, viajes y aventurarme en nuevas experiencias',null],
  [3,'JUS','Entender el punto de vista de los demás y tratar a las personas de manera justa e igualitaria',null],
  [3,'HEA','Realizar actividades que contribuyan a mi bienestar físico y mental',null],
  [3,'KNO','Conectar diferentes ideas y conceptos',null],
  [3,'PRO','Potenciar mi tiempo y aprovechar recursos financieros',null],
  [3,'EXP','Mantenerme en armonía con la naturaleza y el medio ambiente',null],
  [3,'CON','Ayudar a instituciones benéficas en ayuda a los más necesitados',null],
  [3,'ACH','Ser reconocido por el impacto que causó y por sus grandes logros',null],
  [3,'PUR','Conectarme con personas, causas y actividades que tienen un propósito mayor',null],
  [3,'SPI','Estilo de vida con menos excesos materiales y más alineación espiritual',null],
  [3,'FRE','Poder tomar mis propias decisiones y hacer elecciones sin interferencias externas',null],
  [3,'JUS','Tener un fuerte respeto por la ley y el orden, no soportar injusticias y discriminación',null],
  [3,'HEA','Cuidar de mi salud física y mental buscando ayuda profesional cuando lo necesite',null],
  [3,'KNO','Expandir mi mente con nuevos aprendizajes',null],
  [3,'PRO','Alcanzar la máxima cantidad de recursos posible ahora para el futuro',null],
  [3,'EXP','Disfrutar de bellas obras de arte y ambientes agradables',null],
  [3,'CON','Contribuir a la vida de personas necesitadas',null],
  [3,'ACH','Dejar tu legado al mundo y ser recordado por tus logros',null],
  [3,'PUR','Comprender mi relación con el mundo y vivir mi propósito y sentido de vida',null],
  [3,'SPI','Priorizar las cosas que considero más importantes para mi vida espiritual',null],
  [3,'FRE','Poder vivir mi vida de la manera que quiero, sin ser limitado por otras personas',null],
  [3,'JUS','Movilizar a otras personas en busca de un objetivo común que considera justo',null],
  [3,'HEA','Practicar actividades físicas y relajantes, dormir bien y tener una rutina de sueño regular',null],
  [3,'KNO','Aprender nuevos conceptos y aplicarlos',null],
  [3,'PRO','Buscar maneras de ganar dinero por cuenta propia',null],
  [3,'EXP','Tener una alimentación saludable y natural con alimentos orgánicos y frescos',null],
  [3,'CON','Participar como voluntario en grupos de apoyo a causas sociales',null],
  [3,'ACH','Actividades que tienen un propósito mayor, liderar personas en pro de grandes objetivos',null],
  [3,'PUR','Preferencia por realizar actividades que tienen un propósito mayor',null],
  [3,'SPI','Buscar algo mayor, una conexión con el universo, una sensación de paz y plenitud',null],
  [3,'FRE','Explorar y experimentar cosas nuevas, viajar a lugares donde nunca antes he estado',null],
  [3,'JUS','Practicar la verdad y la transparencia, siempre actuando con honestidad e integridad',null],
  [3,'HEA','Realizar chequeos médicos regularmente y buscar tratamientos preventivos de enfermedades',null],
  [3,'KNO','Compartir mis conocimientos con otros',null],
  [3,'PRO','Usar mi tiempo de manera eficiente y productiva',null],
  [3,'EXP','Conectarme con la naturaleza y experimentar la belleza de las artes',null],
  [3,'CON','Servir a los más necesitados contribuyendo a una sociedad mejor',null],
  [3,'ACH','Ser un líder inspirador que busca dejar un legado positivo en todas las áreas de la vida',null],
  [3,'PUR','Sentir que cada día puedo hacer la diferencia y contribuir a algo más grande que yo mismo',null],
  [3,'SPI','Introspección para conectarme con lo divino, encontrar la paz interior y la claridad mental',null],
  [3,'FRE','Aprender sobre nuevas culturas de diferentes partes del mundo y seguir mis pasiones e intereses',null],
  [3,'JUS','Sentido de responsabilidad social, contribuyendo a una sociedad más justa',null],
  [3,'HEA','Tener una alimentación saludable, con alimentos naturales, integrales y nutritivos',null],
  [3,'KNO','Buscar información relevante para mi crecimiento',null],
  [3,'PRO','Buscar independencia financiera y tiempo productivo',null],
  [3,'EXP','Buscar vivir cada experiencia como si fuera única',null],
  [3,'CON','Buscar resolver el dolor del prójimo y problemas sociales',null],
  [3,'ACH','Buscar logros, reconocimiento y dejar un legado positivo',null],
  [3,'PUR','Buscar cumplir un propósito mayor en la vida',null],
  [3,'SPI','Buscar seguir las enseñanzas y prácticas espirituales en todas las áreas de mi vida',null],
  [3,'FRE','Buscar independencia, autonomía y todo lo que me permita ser libre y auténtico',null],
  [3,'JUS','Buscar hacer lo que es justo, correcto e íntegro en la vida cotidiana',null],
  [3,'HEA','Buscar terapias y prácticas que promuevan la salud física y mental',null],
  [2,'RF','DETERMINADO','Persona resiliente que tiende a ser orientada hacia objetivos audaces y muy enfocada en lograr resultados concretos.'],
  [2,'RB','PERSUASIVO','Persona convincente, con una habilidad natural para persuadir e influir en otros.'],
  [2,'LB','PACIENTE','Persona que tiene la capacidad de soportar con calma y tranquilidad situaciones difíciles o estresantes, sin irritarse o agitarse.'],
  [2,'LF','ORGANIZADO','Persona que es capaz de mantener orden y estructura en sus acciones, manteniendo todo en su lugar y tiempo adecuado.'],
  [2,'RF','CONTROLADOR','Persona que tiende a tomar el control de situaciones y una vez que toma la iniciativa puede no dar espacio a otros.'],
  [2,'RB','IMPULSIVO','Persona impulsiva para tomar decisiones rápidamente, sin considerar todas las opciones disponibles.'],
  [2,'LB','LENTA','Persona que tiene un ritmo de vida más tranquilo y menos acelerado. Puede tomar más tiempo para tomar decisiones, realizar tareas o completar proyectos.'],
  [2,'LF','PESIMISTA','Persona extremadamente realista y que puede ser muy crítica consigo misma y con los demás.'],
  [2,'RF','ASERTIVO','Persona que se posiciona y tiende a expresarse de manera firme y clara, sin dudarlo.'],
  [2,'RB','SOCIABLE','Persona que se siente cómoda en situaciones sociales, como eventos o fiestas, y siempre está abierta a conocer y socializar con nuevas personas.'],
  [2,'LB','CALMADO','Persona que mantiene la calma incluso en situaciones estresantes o desafiantes. Capaz de controlar sus reacciones de forma serena.'],
  [2,'LF','ANALÍTICO','Persona con un fuerte pensamiento crítico, capaz de identificar problemas, establecer conexiones y diseñar soluciones basadas en hechos y datos.'],
  [2,'RF','EJECUTOR','Persona orientada a la acción y a tomar medidas inmediatas. Suele buscar resultados rápidos y tangibles.'],
  [2,'RB','CREATIVO','Persona que tiene muchas ideas y le gusta innovar, pensar fuera de la caja y encontrar soluciones originales a los problemas.'],
  [2,'LB','CONSERVADOR','Persona que valora la seguridad y es cautelosa en sus decisiones, suele oponerse a cambios repentinos.'],
  [2,'LF','RACIONAL','Persona que tiene un enfoque metódico y sistemático para investigar y comprender ideas, problemas y situaciones.'],
  [2,'RF','DECIDIDO','Persona que toma decisiones rápidamente y va al grano. No duda en actuar para alcanzar sus objetivos.'],
  [2,'RB','OPTIMISTA','Persona que tiende a ver el lado positivo de las cosas y también puede ser visionaria, audaz y alentadora.'],
  [2,'LB','COOPERATIVO','Persona que valora y practica la colaboración, ayuda mutua y el trabajo en equipo. Busca soluciones que beneficien a todos.'],
  [2,'LF','DETALLISTA','Persona que presta mucha atención a los detalles en todos los aspectos de su vida. Se concentra en cada elemento individual de una tarea o proyecto.'],
  [2,'RF','ÁGIL','Persona con un fuerte sentido de urgencia y tiende a querer que las cosas se hagan rápidamente y a su ritmo.'],
  [2,'RB','ENTUSIASTA','Persona con una actitud positiva y entusiasta hacia la vida. Siempre tiende a estar motivada e inspirada por nuevas oportunidades.'],
  [2,'LB','COMPRESIVO','Persona que es tolerante y capaz de escuchar con atención e interés, mostrando preocupación e interés genuino por las dificultades de los demás.'],
  [2,'LF','LÓGICO','Persona que valora la razón y el pensamiento crítico en su toma de decisiones. Siempre coherente en su análisis y evaluación.'],
  [2,'RF','CONFIADO','Persona que tiene confianza en sí misma y en sus habilidades. No se intimida fácilmente y puede manejar situaciones desafiantes sin dificultad.'],
  [2,'RB','EXPRESIVO','Persona motivada por la interacción y un fuerte deseo de conectarse con otros. Disfruta participando en eventos sociales, fiestas y reuniones.'],
  [2,'LB','CONSTANTE','Persona que actúa de manera educada y amistosa, esforzándose siempre por tratar a las personas con respeto y consideración.'],
  [2,'LF','FORMAL','Persona que suele ser educada y conservadora en su lenguaje y comportamiento, alguien que sigue las convenciones y normas sociales.'],
  [2,'RF','ENÉRGICO','Persona activa que le gusta sentirse productiva y suele ser firme en sus opiniones y convicciones.'],
  [2,'RB','FLEXIBLE','Persona colaborativa y diplomática en su comportamiento, que suele buscar opiniones y consejos de otros antes de tomar cualquier decisión.'],
  [2,'LB','EMPÁTICO','Persona que tiende a preocuparse por el bienestar de los demás y busca soluciones pacíficas a los problemas, poniéndose en el lugar del otro.'],
  [2,'LF','INTROVERTIDO','Persona reservada en su comunicación y comportamiento, prefiriendo conversaciones más profundas y significativas que charlas superficiales e informales.'],
  [2,'RF','ENFOCADO','Persona centrada en los resultados y con un enfoque pragmático para resolver problemas y alcanzar objetivos.'],
  [2,'RB','COMUNICATIVO','Persona extrovertida a la que le gusta hablar con los demás y se siente cómoda en situaciones sociales.'],
  [2,'LB','PLANIFICADOR','Persona capaz de pensar de manera estratégica todos los pasos necesarios para cumplir sus responsabilidades y alcanzar sus objetivos de manera consistente.'],
  [2,'LF','CAUTO','Persona que evalúa cuidadosamente la información disponible antes de tomar cualquier decisión, tratando siempre de prever las consecuencias de sus acciones.'],
  [2,'RF','COMPETITIVO','Persona altamente competitiva que se esfuerza al máximo en cualquier actividad para superar a los demás y salir victorioso.'],
  [2,'RB','EXTROVERTIDO','Persona que se siente energizada en interacciones sociales. Casi siempre alegre, animada, comunicativa y expresiva.'],
  [2,'LB','DIPLOMÁTICO','Persona que evita conflictos y sabe manejar divergencias de opiniones de manera constructiva, buscando soluciones que satisfagan a todas las partes involucradas.'],
  [2,'LF','RESERVADO','Persona discreta que tiende a compartir poco de sí misma. Sus opiniones y sentimientos están reservados solo para las personas que considera de confianza.'],
  [1,'RF','DETERMINADO','Persona resiliente que tiende a orientarse hacia objetivos ambiciosos y también muy centrada en lograr resultados concretos.'],
  [1,'RB','PERSUASIVO','Persona convincente, con una habilidad natural de persuadir e influenciar a los demás.'],
  [1,'LB','PACIENTE','Persona que tiene la capacidad de soportar con calma y tranquilidad situaciones difíciles o estresantes, sin irritarse o agitarse.'],
  [1,'LF','ORGANIZADO','Persona capaz de mantener un orden y estructura en sus acciones, manteniendo todo en su lugar y en su tiempo adecuado.'],
  [1,'RF','CONTROLADOR','Persona que tiende a tomar el control de las situaciones y una vez que toma las riendas puede no dejar espacio a otros.'],
  [1,'RB','IMPULSIVO','Persona impulsiva al tomar decisiones rápidamente, sin considerar todas las opciones disponibles.'],
  [1,'LB','TRANQUILO','Persona que tiene un ritmo de vida más sosegado y menos acelerado. Puede tardar más tiempo en tomar decisiones, realizar tareas o completar proyectos.'],
  [1,'LF','PESIMISTA','Persona extremadamente realista y que puede ser altamente crítica consigo misma y con los demás.'],
  [1,'RF','ASERTIVO','Persona que se posiciona y tiende a expresarse de manera firme y clara, sin vacilar.'],
  [1,'RB','SOCIAL','Persona que se siente cómoda en situaciones sociales, como eventos o fiestas, y siempre está dispuesta a conocer y socializar con nuevas personas.'],
  [1,'LB','CALMADO','Persona que logra mantener la calma incluso en situaciones estresantes o desafiantes. Capaz de controlar sus reacciones de forma serena.'],
  [1,'LF','ANALÍTICO','Persona con un pensamiento crítico sólido, capaz de identificar problemas, establecer conexiones y diseñar soluciones basadas en hechos y datos.'],
  [1,'RF','EJECUTOR','Persona orientada a la acción y a la toma de medidas inmediatas. Generalmente busca resultados rápidos y tangibles.'],
  [1,'RB','CREATIVO','Persona que tiene muchas ideas y le gusta innovar, pensar fuera de lo convencional y encontrar soluciones originales a los problemas.'],
  [1,'LB','CONSERVADOR','Persona que valora la seguridad y la prudencia en sus decisiones, y que generalmente se opone a cambios repentinos.'],
  [1,'LF','RACIONAL','Persona que tiene un enfoque metódico y sistemático para investigar y comprender ideas, problemas y situaciones.'],
  [1,'RF','DECIDIDO','Persona que toma decisiones rápidamente y va al grano. No duda en actuar para alcanzar sus objetivos.'],
  [1,'RB','OPTIMISTA','Persona que tiende a ver el lado positivo de las cosas y también puede ser visionaria, audaz y alentadora.'],
  [1,'LB','COOPERADOR','Persona que valora y practica la colaboración, la ayuda mutua y el trabajo en equipo. Busca soluciones que beneficien a todos.'],
  [1,'LF','DETALLISTA','Persona que presta mucha atención a los detalles en todos los aspectos de su vida. Se centra en cada elemento individual de una tarea o proyecto.'],
  [1,'RF','ÁGIL','Persona con un fuerte sentido de urgencia y que tiende a querer que las cosas se hagan rápidamente y a su ritmo.'],
  [1,'RB','ENTUSIASTA','Persona con una actitud positiva y entusiasta hacia la vida. Tiende a estar siempre motivada e inspirada por nuevas oportunidades.'],
  [1,'LB','COMPRESIVO','Persona tolerante y capaz de escuchar atentamente, mostrando preocupación e interés genuino por las dificultades de los demás.'],
  [1,'LF','LÓGICO','Persona que valora la razón y el pensamiento crítico en su toma de decisiones. Siempre coherente en su análisis y evaluación.'],
  [1,'RF','CONFIADO','Persona que tiene confianza en sí misma y en sus habilidades. No se intimida fácilmente y es capaz de enfrentar situaciones desafiantes con facilidad.'],
  [1,'RB','EXPRESIVO','Persona motivada por la interacción y un fuerte deseo de conectarse con otras personas. Le gusta participar en eventos sociales, fiestas y reuniones.'],
  [1,'LB','CONSTANTE','Persona que actúa de manera educada y amigable, esforzándose siempre por tratar a las personas con respeto y consideración.'],
  [1,'LF','FORMAL','Persona que generalmente es cortés y conservadora en su lenguaje y comportamiento, alguien que sigue las convenciones y reglas sociales.'],
  [1,'RF','ENÉRGICO','Persona activa que le gusta sentirse productiva y tiende a ser firme en sus opiniones y convicciones.'],
  [1,'RB','FLEXIBLE','Persona colaborativa y diplomática en su comportamiento, que suele buscar opiniones y consejos de otras personas antes de tomar cualquier decisión.'],
  [1,'LB','EMPÁTICO','Persona que tiende a preocuparse por el bienestar de los demás y busca soluciones pacíficas a los problemas, poniéndose en el lugar del otro.'],
  [1,'LF','INTROVERTIDO','Persona reservada en su comunicación y comportamiento, prefiriendo conversaciones más profundas y significativas que charlas superficiales e informales.'],
  [1,'RF','CENTRADO','Persona enfocada en resultados y generalmente con un enfoque pragmático para resolver problemas y alcanzar metas.'],
  [1,'RB','COMUNICATIVO','Persona extrovertida que disfruta hablando con otros y se siente cómoda en situaciones sociales.'],
  [1,'LB','PLANIFICADOR','Persona capaz de pensar de forma estratégica sobre todos los pasos necesarios para cumplir sus responsabilidades y alcanzar sus objetivos de manera consistente.'],
  [1,'LF','CAUTO','Persona que evalúa con cuidado la información disponible antes de tomar cualquier decisión, siempre tratando de prever las consecuencias de sus acciones.'],
  [1,'RF','COMPETITIVO','Persona altamente competitiva que lucha por destacar en cualquier actividad, superar a los demás y salir victoriosa.'],
  [1,'RB','EXTROVERTIDO','Persona que se siente energizada en interacciones sociales. Casi siempre alegre, animada, comunicativa y expresiva.'],
  [1,'LB','DIPLOMÁTICO','Persona que evita conflictos y sabe manejar divergencias de opiniones de manera constructiva, buscando soluciones que satisfagan a todas las partes involucradas.'],
  [1,'LF','RESERVADO','Persona discreta que tiende a compartir poco de sí misma. Sus opiniones y sentimientos están reservados solo para las personas que considera de confianza.'],
]);
