import React from 'react'
import { useTranslation } from 'react-i18next'
// import { BrasilFlag, EuaFlag } from '/assets/flags'
// useTranslation é um hook
// que devolve uma função de tradução (t) e a instância do i18n

// Importa as bandeiras (imagens e componente)
import { I18nFlag } from './Flag'

const I18nComponent: React.FC = () => {
  const { i18n } = useTranslation()
  // Instância do i18n

  function handleChangeLanguage(language: any) {
    // Trocando o idioma na chamada da função
    i18n.changeLanguage(language)
  }

  const selectedLanguage = i18n.language // Idioma selecionado

  return (
    <div className="flex w-fit cursor-pointer gap-1">
      <div className="w-7 h-7">
        <I18nFlag
          image={'/assets/flags/brasil-flag.svg'}
          isSelected={selectedLanguage === 'pt-BR'} // Verifica o idioma escolhido
          onClick={() => handleChangeLanguage('pt-BR')} // Troca o idioma para pt-BR
        />
      </div>
      <div className="w-7 h-7">
        <I18nFlag
          image={'/assets/flags/eua-flag.svg'}
          isSelected={selectedLanguage === 'en-US'} // Verifica o idioma escolhido
          onClick={() => handleChangeLanguage('en-US')} // Troca o idioma para en-US
        />
      </div>
      <div className="w-7 h-7">
        <I18nFlag
          image={'/assets/flags/es-flag.svg'}
          isSelected={selectedLanguage === 'es-ES'} // Verifica o idioma escolhido
          onClick={() => handleChangeLanguage('es-ES')} // Troca o idioma para en-US
        />
      </div>
    </div>
  )
}

export { I18nComponent }
