import React from 'react'

interface ModalProps {
  image?: string
  title: string
  button?: boolean
  buttons?: boolean
  open: boolean
  onclick: () => void
}

const ImageModal: React.FC<ModalProps> = ({
  image,
  title,
  button,
  buttons,
  open,
  onclick,
}) => {
  !image ? undefined : image
  return (
    <>
      {open ? (
        <div className=" fixed left-1/2 top-20 z-[1055] flex flex-col items-center justify-center text-center text-textGray bg-white rounded-lg p-3 w-64 h-fit gap-2">
          <img src={image} alt="image modal" />
          <span className="text-sm font-semibold ">{title}</span>
          {button ? (
            <button
              onClick={onclick}
              className="text-white bg-darkBlue rounded-md w-24 leading-9"
            >
              OK
            </button>
          ) : null}
          {buttons ? (
            <div className="flex gap-4">
              <button
                onClick={onclick}
                className="text-white bg-mediumGray rounded-md w-24 leading-9"
              >
                Não
              </button>
              <input
                type="submit"
                value="Sim"
                onClick={onclick}
                className="text-white bg-darkBlue rounded-md w-24 leading-9"
              ></input>
            </div>
          ) : null}
        </div>
      ) : null}
    </>
  )
}

export { ImageModal }
