import { X } from '@phosphor-icons/react'
import React from 'react'
import { Translator } from './I18n/Translator'
import { Trans } from 'react-i18next'

interface HowAnswerProps {
  close: () => void
  step: number
}

const HowAnswer: React.FC<HowAnswerProps> = ({ close, step }) => {
  return (
    <div className="flex flex-col gap-2 bg-[#F3F4F6] rounded-lg pt-5 px-4 pb-3 max-w-3xl">
      <h3 className="text-xl font-semibold">
        <Translator path="selfKnowledge.form.howAnswer.title" />
      </h3>
      {step != 2 ? (
        <p>
          <Translator path="selfKnowledge.form.howAnswer.step.one" />
        </p>
      ) : (
        <p>
          <Translator path="selfKnowledge.form.howAnswer.step.two" />
        </p>
      )}
      <p>
        <Translator path="selfKnowledge.form.howAnswer.textOne" />
      </p>
      <ol>
        {step != 2 ? (
          <>
            <li className="font-semibold">
              1 -{' '}
              <Translator path="selfKnowledge.form.howAnswer.list.veryRelevant" />
            </li>
            <li className="font-semibold">
              2 -{' '}
              <Translator path="selfKnowledge.form.howAnswer.list.relevant" />
            </li>
            <li className="font-semibold">
              3 -{' '}
              <Translator path="selfKnowledge.form.howAnswer.list.someRelevance" />
            </li>
            <li className="font-semibold">
              4 -{' '}
              <Translator path="selfKnowledge.form.howAnswer.list.lowRelevance" />
            </li>
          </>
        ) : (
          <>
            <li className="font-semibold">
              1 -{' '}
              <Translator path="selfKnowledge.form.howAnswer.list.veryRelevant" />
            </li>
            <li className="font-semibold">
              2 -{' '}
              <Translator path="selfKnowledge.form.howAnswer.list.relevant" />
            </li>
            <li className="font-semibold">
              3 -{' '}
              <Translator path="selfKnowledge.form.howAnswer.list.relevant" />
            </li>
            <li className="font-semibold">
              4 -{' '}
              <Translator path="selfKnowledge.form.howAnswer.list.someRelevance" />
            </li>
            <li className="font-semibold">
              5 -{' '}
              <Translator path="selfKnowledge.form.howAnswer.list.someRelevance" />
            </li>
            <li className="font-semibold">
              6 -{' '}
              <Translator path="selfKnowledge.form.howAnswer.list.someRelevance" />
            </li>
            <li className="font-semibold">
              7 -{' '}
              <Translator path="selfKnowledge.form.howAnswer.list.someRelevance" />
            </li>
            <li className="font-semibold">
              8 -{' '}
              <Translator path="selfKnowledge.form.howAnswer.list.lowRelevance" />
            </li>
            <li className="font-semibold">
              9 -{' '}
              <Translator path="selfKnowledge.form.howAnswer.list.lowRelevance" />
            </li>
            <li className="font-semibold">
              10 -{' '}
              <Translator path="selfKnowledge.form.howAnswer.list.lowRelevance" />
            </li>
          </>
        )}
      </ol>
      <p className="">
        <Trans
          i18nKey="selfKnowledge.form.howAnswer.textTwo"
          components={{ b: <b /> }}
        />
      </p>
      <img
        className="max-h-36 object-contain w-3/4 mx-auto"
        src={'/assets/br_adjective_select_example.gif'}
        alt=""
      />
      <p>
        <Translator path="selfKnowledge.form.howAnswer.textThree" />
      </p>
      <img
        className="max-h-36 object-contain w-3/4 mx-auto"
        src={'/assets/br_adjective_description_example.gif'}
        alt=""
      />
      <button
        onClick={close}
        className="flex w-fit gap-4 justify-center items-center rounded-lg bg-red text-[white] border border-[#white] py-2 px-5 mx-auto"
      >
        <X size={24} />
        <Translator path="selfKnowledge.form.howAnswer.btn" />
      </button>
    </div>
  )
}

export { HowAnswer }
