/* eslint-disable prettier/prettier */
export const question = ([
  ['step', 'type', 'name', 'description'],
  [3,'KNO','Aprender coisas novas',null],
  [3,'PRO','Trabalhar muito para construir uma carreira de sucesso',null],
  [3,'EXP','Apreciar a natureza, as artes e aprender sobre novas culturas',null],
  [3,'CON','Compartilhar recursos, tempo e habilidades para ajudar os outros',null],
  [3,'ACH','Realizar coisas significativas na vida',null],
  [3,'PUR','Entender o seu propósito e o sentido da vida ',null],
  [3,'SPI','Expressar minhas convicções e vivenciar a verdadeira espiritualidade',null],
  [3,'FRE','Pensar fora da caixa em busca de novas ideias e perspectivas',null],
  [3,'JUS','Fazer o que é certo e justo em todas as situações',null],
  [3,'HEA','Manter uma dieta equilibrada e saudável com atenção na qualidade dos alimentos',null],
  [3,'KNO','Descobrir como as coisas funcionam',null],
  [3,'PRO','Ter uma vida financeira bem sucedida',null],
  [3,'EXP','Vivenciar novas experiências em minha vida',null],
  [3,'CON','Contribuir com causas sociais',null],
  [3,'ACH','Deixar um legado e fazer a diferença no mundo',null],
  [3,'PUR','Dar um significado para suas escolhas e ações',null],
  [3,'SPI','Buscar o sentido mais profundo da existência humana',null],
  [3,'FRE','Valorizo a criatividade, capacidade de mudar de ideia ou de planos caso necessário',null],
  [3,'JUS','Agir sempre com um forte senso de ética e moralidade',null],
  [3,'HEA','Dedicar tempo e recursos para manter uma boa higiene pessoal e saúde bucal ',null],
  [3,'KNO','Adquirir novos conhecimentos',null],
  [3,'PRO','Aumentar minha renda investindo em boas oportunidades',null],
  [3,'EXP','Viver cada momento e aproveitar as experiências que a vida oferece',null],
  [3,'CON','Envolver-se em atividades voluntárias e ações sociais',null],
  [3,'ACH','Liderar pessoas rumo a objetivos audaciosos',null],
  [3,'PUR','Encontrar meu propósito, tenho um forte interesse em me conhecer ainda mais',null],
  [3,'SPI','Ter uma rotina de meditação e oração para se conectar com o divino',null],
  [3,'FRE','Facilidade de se adaptar a novas situações, pois não tenho apego a coisas',null],
  [3,'JUS','Lutar contra as desigualdades e injustiças sociais, a favor de causas justas e igualitárias',null],
  [3,'HEA','Evitar o uso de substâncias nocivas, como drogas, álcool e tabaco',null],
  [3,'KNO','Ser um Líder que gosta de aprender e ensinar',null],
  [3,'PRO','Ser um Líder produtivo em busca de mais resultados em menos tempo',null],
  [3,'EXP','Ser um Líder que preza por experiências enriquecedoras ',null],
  [3,'CON','Ser um Líder que serve aos seus liderados e contribui com a sociedade',null],
  [3,'ACH','Ser um Líder que sabe inspirar e motivar os outros a seguir objetivos ousados',null],
  [3,'PUR','Ser um Líder com um alto senso de propósito enraizado em meus valores',null],
  [3,'SPI','Ser um Líder que valoriza questões relacionadas à espiritualidade',null],
  [3,'FRE','Ser um Líder que preza pela liberdade de escolha e individualidades de seus liderados',null],
  [3,'JUS','Ser um Líder valoriza integridade, optando pelo certo e justo em todas as situações',null],
  [3,'HEA','Ser um Líder que se preocupa com o bem estar e a saúde física e mental dos liderados',null],
  [3,'KNO','Estar em busca de novas teorias',null],
  [3,'PRO','Expandir meus ganhos significativamente',null],
  [3,'EXP','Viajar a locais que permitam vivenciar experiências culturais e artísticas',null],
  [3,'CON','Dedicar tempo e recursos em apoio a ações comunitárias',null],
  [3,'ACH','Seguir uma visão de longo prazo ciente que suas ações e decisões hoje afetarão o futuro',null],
  [3,'PUR','Viver numa busca constante por um propósito maior na vida ',null],
  [3,'SPI','Compreender a profundidade das diferentes crenças espirituais',null],
  [3,'FRE','Conhecer o novo através de pessoas, viagens e me aventurar em novas experiências ',null],
  [3,'JUS','Entender ponto de vista dos outros e tratar as pessoas de forma justa e igualitária',null],
  [3,'HEA','Fazer atividades que contribuam para o meu bem-estar físico e mental',null],
  [3,'KNO','Conectar diferentes ideias e conceitos',null],
  [3,'PRO','Potencializar meu tempo e alavancar recursos financeiros',null],
  [3,'EXP','Manter-se em harmonia com a natureza e o meio ambiente',null],
  [3,'CON','Ajudar instituições de caridade em auxílio aos mais necessitados',null],
  [3,'ACH','Ser reconhecido pelo impacto que causou e por seus grandes feitos',null],
  [3,'PUR','Se conectar com pessoas, causas e atividades que possuam um propósito maior',null],
  [3,'SPI','Estilo de vida com menos excessos materiais e mais alinhamento espiritual ',null],
  [3,'FRE','Poder tomar minhas próprias decisões e fazer escolhas sem interferências externas',null],
  [3,'JUS','Ter um forte respeito pela lei e pela ordem, não suporta injustiças e discriminação',null],
  [3,'HEA','Cuidar da minha saúde física e mental buscando ajuda profissional quando preciso',null],
  [3,'KNO','Expandir minha mente com novos aprendizados',null],
  [3,'PRO','Alcançar o máximo de recursos possível agora para o futuro',null],
  [3,'EXP','Desfrutar de belas obras de arte e ambientes agradáveis',null],
  [3,'CON','Contribuir com a vida de pessoas carentes',null],
  [3,'ACH','Deixar seu legado para o mundo e ser lembrado(a) por suas realizações',null],
  [3,'PUR','Compreender a minha relação com o mundo e viver meu propósito e sentido de vida',null],
  [3,'SPI','Priorizar as coisas que considera mais importantes para minha vida espiritual',null],
  [3,'FRE','Poder viver minha vida da maneira que eu quero, sem ser limitado por outras pessoas ',null],
  [3,'JUS','Mobilizar outras pessoas em busca de um objetivo comum que considera ser justo',null],
  [3,'HEA','Praticar atividades físicas e relaxantes, dormir bem e ter uma rotina de sono regular',null],
  [3,'KNO','Aprender novos conceitos e aplicá-los',null],
  [3,'PRO','Procurar maneiras de ganhar dinheiro por conta própria',null],
  [3,'EXP','Ter uma alimentação saudável e natural com alimentos orgânicos e frescos',null],
  [3,'CON','Participar como voluntário de grupos de apoio a causas sociais',null],
  [3,'ACH','Atividades que tenham um propósito maior, liderar pessoas em prol de grandes objetivos',null],
  [3,'PUR','Preferência por realizar atividades que possuam um propósito maior',null],
  [3,'SPI','Busca por algo maior, uma conexão com o universo, uma sensação de paz e plenitude',null],
  [3,'FRE','Explorar e experimentar coisas novas, viajar para lugares que nunca fui antes ',null],
  [3,'JUS','Praticar a verdade e a transparência, sempre agindo com honestidade e integridade',null],
  [3,'HEA','Fazer exames médicos regularmente e buscar tratamento preventivo de doenças',null],
  [3,'KNO','Compartilhar seus conhecimentos com os outros',null],
  [3,'PRO','Usar meu tempo de forma eficiente e produtiva',null],
  [3,'EXP','Me conectar a natureza e experienciar a beleza das artes',null],
  [3,'CON','Servir aos mais necessitados contribuindo para uma sociedade melhor',null],
  [3,'ACH','Ser um líder inspirador que busca deixar um legado positivo em todas as áreas da vida',null],
  [3,'PUR','Sentir que cada dia posso fazer a diferença e contribuir para algo maior do que eu mesmo',null],
  [3,'SPI','Introspecção para me conectar ao divino, encontrar a paz interior e a clareza mental.',null],
  [3,'FRE','Aprender novas culturas de diferentes partes do mundo e seguir minhas paixões e interesses',null],
  [3,'JUS','Senso de responsabilidade social, contribuindo para uma sociedade mais justa',null],
  [3,'HEA','Ter uma alimentação saudável, com alimentos naturais, integrais e nutritivos',null],
  [3,'KNO','Busca por informações relevantes ao meu crescimento',null],
  [3,'PRO','Busca por independência financeira e tempo produtivo',null],
  [3,'EXP','Busca viver cada experiência como se fosse única',null],
  [3,'CON','Busca por resolver a dor do próximo e problemas sociais',null],
  [3,'ACH','Busca por realizações, reconhecimento e deixar um legado positivo',null],
  [3,'PUR','Busca cumprir um propósito maior na vida ',null],
  [3,'SPI','Busca seguir os ensinamentos e práticas espirituais em todas as áreas da minha vida',null],
  [3,'FRE','Busca por independência, autonomia e tudo que me permitem ser livre e autêntico',null],
  [3,'JUS','Busca fazer o que é justo, correto e integro no cotidiano',null],
  [3,'HEA','Busca por terapias e práticas que promovam saúde física e mental',null],
  [2,'RF','DETERMINADO','Pessoa resiliente que tende a ser orientada para objetivos ousados e também muito focada em alcançar resultados concretos.'],
  [2,'RB','PERSUASIVO','Pessoa convincente, com uma habilidade natural de persuadir e influenciar os outros.'],
  [2,'LB','PACIENTE','Pessoa que tem a capacidade de suportar com calma e tranquilidade situações difíceis ou estressantes, sem ficar irritada ou agitada.'],
  [2,'LF','ORGANIZADO','Pessoa que é capaz de manter uma ordem e estrutura em suas ações, mantendo tudo em seu devido lugar e em seu devido tempo.'],
  [2,'RF','CONTROLADOR','Pessoa que tende a assumir o controle das situações e depois que assume a frente da situação pode não dar espaço a outras pessoas.'],
  [2,'RB','IMPULSIVO','Pessoa impulsiva para tomar decisões rapidamente, sem considerar todas as opções disponíveis.'],
  [2,'LB','DEVAGAR','Pessoa que tem um ritmo de vida mais tranquilo e menos acelerado. Pode levar mais tempo para tomar decisões, realizar tarefas ou completar projetos.'],
  [2,'LF','PESSIMISTA','Pessoa extremamente realista e que pode ser altamente crítica de si mesma e dos outros'],
  [2,'RF','ASSERTIVO','Pessoa que se posiciona e tende a se expressar de forma firme e clara, sem hesitação.'],
  [2,'RB','SOCIÁVEL','Pessoa que se sente confortável em situações sociais, como eventos ou festas e está sempre aberta conhecer socializar com novas pessoas.'],
  [2,'LB','CALMO','Pessoa que consegue manter a tranquilidade mesmo em situações estressantes ou desafiadoras. Capaz de controlar as suas reações de forma serena.'],
  [2,'LF','ANALÍTICO','Pessoa tem um forte pensamento crítico sendo capaz de identificar problemas, fazer conexões, e elaborar soluções baseadas em fatos e dados.'],
  [2,'RF','EXECUTOR','Pessoa orientada para a ação e a tomada de medidas imediatas. Geralmente busca resultados rápidos e tangíveis.'],
  [2,'RB','CRIATIVO','Pessoa que têm muitas ideias e gosta de ser inovadora, pensar fora da caixa e encontrar soluções originais para os problemas.'],
  [2,'LB','CONSERVADOR','Pessoa que preza pela segurança e pé no chão em suas decisões, geralmente se opõe a mudanças repentinas. '],
  [2,'LF','RACIONAL','Pessoa que possui uma abordagem metódica e sistemática para investigar e entender ideias, problemas e situações.'],
  [2,'RF','DECIDIDO','Pessoa que toma decisões rapidamente e vai direto ao ponto. Não hesita em agir para alcançar seus objetivos.'],
  [2,'RB','OTIMISTA','Pessoa que tendem a ver o lado positivo das coisas e também pode ser visionária, ousada e encorajadora.'],
  [2,'LB','COOPERADOR','Pessoa que valoriza e pratica a colaboração, a ajuda mútua e o trabalho em equipe. Busca encontrar soluções que beneficiem a todos.'],
  [2,'LF','DETALHISTA','Pessoa que presta muita atenção aos detalhes em todos os aspectos de sua vida. Concentra-se em cada elemento individual de uma tarefa ou projeto.'],
  [2,'RF','ÁGIL','Pessoa com um forte senso de urgência e tende a querer que as coisas sejam feitas rapidamente e no seu tempo.'],
  [2,'RB','ENTUSIASMADO','Pessoa com uma atitude positiva e entusiasta em relação à vida. Tende a estar sempre motivada e inspirada por novas oportunidades.'],
  [2,'LB','COMPREENSIVO','Pessoa que é tolerante e capaz de ouvir com atenção e interesse, mostrando preocupação e interesse genuíno pelas dificuldades dos outros.'],
  [2,'LF','LÓGICO','Pessoa que valoriza a razão e o pensamento crítico em sua tomada de decisão. Sempre com coerência em sua análise e avaliação.'],
  [2,'RF','CONFIANTE','Pessoa que tem confiança em si mesmo e nas suas habilidades. Não se intimida facilmente e é capaz de lidar sem dificuldades com situações desafiadoras.'],
  [2,'RB','EXPRESSIVO','Pessoa motivada pela interação e um forte desejo de conectar-se com outras pessoas. Gosta de participar de eventos sociais, festas e reuniões.'],
  [2,'LB','CONSTANTE','Pessoa que é capaz de agir de forma educada e amigável, sempre se esforçando para tratar as pessoas com respeito e consideração.'],
  [2,'LF','FORMAL','Pessoa que geralmente é polida e conservadora em sua linguagem e comportamento, alguem que segue as convenções e regras sociais.'],
  [2,'RF','ENÉRGICO','Pessoa ativa que gosta de se sentir produtiva e tende a ser firme em suas opiniões e convicções.'],
  [2,'RB','FLEXÍVEL','Pessoa colaborativa e política em seu comportamento, que costuma buscar opiniões e conselhos de outras pessoas antes de qualquer decisão.'],
  [2,'LB','EMPÁTICO','Pessoa que tende a se preocupar com o bem-estar dos outros e a buscar soluções pacíficas para os problemas se colocando no lugar do outro.'],
  [2,'LF','INTROVERTIDO','Pessoa reservada em sua comunicação e comportamento, preferindo conversas mais profundas e significativas do que conversas superficiais e informais.'],
  [2,'RF','FOCADO','Pessoa com foco em resultados e geralmente com uma abordagem pragmática para resolver problemas e alcançar metas.'],
  [2,'RB','COMUNICATIVO','Pessoa extrovertida que gosta de falar com os outros e se sente à vontade em situações sociais.'],
  [2,'LB','PLANEJADOR','Pessoa capaz de pensar de forma estratégica todas as etapas necessárias para cumprir suas responsabilidades e atingir suas metas de forma consistente.'],
  [2,'LF','CAUTELOSO','Pessoa que avalia cuidadosamente as informações disponíveis antes de qualquer decisão, buscando sempre prever as consequências de suas ações.'],
  [2,'RF','COMPETITIVO','Pessoa altamente competitiva que se esforça ao máximo em qualquer que seja a atividade para superar os outros e sair vencedor.'],
  [2,'RB','EXTROVERTIDO','Pessoa que se sente energizada em interações sociais. Quase sempre alegre, animada, comunicativa e expressiva.'],
  [2,'LB','DIPLOMÁTICO','Pessoa que evita conflitos e consegue lidar com divergências de opiniões de forma construtiva, procurando soluções que satisfaçam todas as partes envolvidas. '],
  [2,'LF','RESERVADO','Pessoa discreta que tende a compartilhar pouco de si mesma. Suas opiniões e sentimentos são restrito apenas a pessoas que considera de sua confiança.'],
  [1,'RF','DETERMINADO','Pessoa resiliente que tende a ser orientada para objetivos ousados e também muito focada em alcançar resultados concretos.'],
  [1,'RB','PERSUASIVO','Pessoa convincente, com uma habilidade natural de persuadir e influenciar os outros.'],
  [1,'LB','PACIENTE','Pessoa que tem a capacidade de suportar com calma e tranquilidade situações difíceis ou estressantes, sem ficar irritada ou agitada.'],
  [1,'LF','ORGANIZADO','Pessoa que é capaz de manter uma ordem e estrutura em suas ações, mantendo tudo em seu devido lugar e em seu devido tempo.'],
  [1,'RF','CONTROLADOR','Pessoa que tende a assumir o controle das situações e depois que assume a frente da situação pode não dar espaço a outras pessoas.'],
  [1,'RB','IMPULSIVO','Pessoa impulsiva para tomar decisões rapidamente, sem considerar todas as opções disponíveis.'],
  [1,'LB','DEVAGAR','Pessoa que tem um ritmo de vida mais tranquilo e menos acelerado. Pode levar mais tempo para tomar decisões, realizar tarefas ou completar projetos.'],
  [1,'LF','PESSIMISTA','Pessoa extremamente realista e que pode ser altamente crítica de si mesma e dos outros'],
  [1,'RF','ASSERTIVO','Pessoa que se posiciona e tende a se expressar de forma firme e clara, sem hesitação.'],
  [1,'RB','SOCIÁVEL','Pessoa que se sente confortável em situações sociais, como eventos ou festas e está sempre aberta conhecer socializar com novas pessoas.'],
  [1,'LB','CALMO','Pessoa que consegue manter a tranquilidade mesmo em situações estressantes ou desafiadoras. Capaz de controlar as suas reações de forma serena.'],
  [1,'LF','ANALÍTICO','Pessoa tem um forte pensamento crítico sendo capaz de identificar problemas, fazer conexões, e elaborar soluções baseadas em fatos e dados.'],
  [1,'RF','EXECUTOR','Pessoa orientada para a ação e a tomada de medidas imediatas. Geralmente busca resultados rápidos e tangíveis.'],
  [1,'RB','CRIATIVO','Pessoa que têm muitas ideias e gosta de ser inovadora, pensar fora da caixa e encontrar soluções originais para os problemas.'],
  [1,'LB','CONSERVADOR','Pessoa que preza pela segurança e pé no chão em suas decisões, geralmente se opõe a mudanças repentinas. '],
  [1,'LF','RACIONAL','Pessoa que possui uma abordagem metódica e sistemática para investigar e entender ideias, problemas e situações.'],
  [1,'RF','DECIDIDO','Pessoa que toma decisões rapidamente e vai direto ao ponto. Não hesita em agir para alcançar seus objetivos.'],
  [1,'RB','OTIMISTA','Pessoa que tendem a ver o lado positivo das coisas e também pode ser visionária, ousada e encorajadora.'],
  [1,'LB','COOPERADOR','Pessoa que valoriza e pratica a colaboração, a ajuda mútua e o trabalho em equipe. Busca encontrar soluções que beneficiem a todos.'],
  [1,'LF','DETALHISTA','Pessoa que presta muita atenção aos detalhes em todos os aspectos de sua vida. Concentra-se em cada elemento individual de uma tarefa ou projeto.'],
  [1,'RF','ÁGIL','Pessoa com um forte senso de urgência e tende a querer que as coisas sejam feitas rapidamente e no seu tempo.'],
  [1,'RB','ENTUSIASMADO','Pessoa com uma atitude positiva e entusiasta em relação à vida. Tende a estar sempre motivada e inspirada por novas oportunidades.'],
  [1,'LB','COMPREENSIVO','Pessoa que é tolerante e capaz de ouvir com atenção e interesse, mostrando preocupação e interesse genuíno pelas dificuldades dos outros.'],
  [1,'LF','LÓGICO','Pessoa que valoriza a razão e o pensamento crítico em sua tomada de decisão. Sempre com coerência em sua análise e avaliação.'],
  [1,'RF','CONFIANTE','Pessoa que tem confiança em si mesmo e nas suas habilidades. Não se intimida facilmente e é capaz de lidar sem dificuldades com situações desafiadoras.'],
  [1,'RB','EXPRESSIVO','Pessoa motivada pela interação e um forte desejo de conectar-se com outras pessoas. Gosta de participar de eventos sociais, festas e reuniões.'],
  [1,'LB','CONSTANTE','Pessoa que é capaz de agir de forma educada e amigável, sempre se esforçando para tratar as pessoas com respeito e consideração.'],
  [1,'LF','FORMAL','Pessoa que geralmente é polida e conservadora em sua linguagem e comportamento, alguem que segue as convenções e regras sociais.'],
  [1,'RF','ENÉRGICO','Pessoa ativa que gosta de se sentir produtiva e tende a ser firme em suas opiniões e convicções.'],
  [1,'RB','FLEXÍVEL','Pessoa colaborativa e política em seu comportamento, que costuma buscar opiniões e conselhos de outras pessoas antes de qualquer decisão.'],
  [1,'LB','EMPÁTICO','Pessoa que tende a se preocupar com o bem-estar dos outros e a buscar soluções pacíficas para os problemas se colocando no lugar do outro.'],
  [1,'LF','INTROVERTIDO','Pessoa reservada em sua comunicação e comportamento, preferindo conversas mais profundas e significativas do que conversas superficiais e informais.'],
  [1,'RF','FOCADO','Pessoa com foco em resultados e geralmente com uma abordagem pragmática para resolver problemas e alcançar metas.'],
  [1,'RB','COMUNICATIVO','Pessoa extrovertida que gosta de falar com os outros e se sente à vontade em situações sociais.'],
  [1,'LB','PLANEJADOR','Pessoa capaz de pensar de forma estratégica todas as etapas necessárias para cumprir suas responsabilidades e atingir suas metas de forma consistente.'],
  [1,'LF','CAUTELOSO','Pessoa que avalia cuidadosamente as informações disponíveis antes de qualquer decisão, buscando sempre prever as consequências de suas ações.'],
  [1,'RF','COMPETITIVO','Pessoa altamente competitiva que se esforça ao máximo em qualquer que seja a atividade para superar os outros e sair vencedor.'],
  [1,'RB','EXTROVERTIDO','Pessoa que se sente energizada em interações sociais. Quase sempre alegre, animada, comunicativa e expressiva.'],
  [1,'LB','DIPLOMÁTICO','Pessoa que evita conflitos e consegue lidar com divergências de opiniões de forma construtiva, procurando soluções que satisfaçam todas as partes envolvidas. '],
  [1,'LF','RESERVADO','Pessoa discreta que tende a compartilhar pouco de si mesma. Suas opiniões e sentimentos são restrito apenas a pessoas que considera de sua confiança.'],
]);
