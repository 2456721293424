import React, { useEffect, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useNavigate, useSearchParams } from 'react-router-dom'
import api from '../services/api'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { toast } from 'react-toastify'
import { Translator } from '../components/I18n/Translator'
import { useTranslation } from 'react-i18next'
import packageJson from '../../package.json'

const RecoverPasswordSchema = z
  .object({
    password: z
      .string()
      .min(1, 'Password is required')
      .min(8, 'Password must have more than 8 characters'),
    confirmPassword: z.string().min(1, 'Password confirmation is required'),
  })
  .refine((data) => data.password === data.confirmPassword, {
    path: ['confirmPassword'],
    message: 'Passwords do not match',
  })

type RecoverPasswordSchemaType = z.infer<typeof RecoverPasswordSchema>

const RecoverPassword: React.FC = () => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const [email, setEmail] = useState('')
  const { i18n } = useTranslation()

  const {
    register,
    handleSubmit,
    formState: { errors, isDirty, isValid },
  } = useForm<RecoverPasswordSchemaType>({
    resolver: zodResolver(RecoverPasswordSchema),
    mode: 'onChange',
  })

  const onSubmit: SubmitHandler<RecoverPasswordSchemaType> = async ({
    password,
  }) => {
    try {
      const token = searchParams.get('token')
      await api.post('/v1/recover-password', {
        token,
        password,
      })
      toast.success(`Senha atualizada com sucesso!`)
      navigate('/')
    } catch (err: any) {
      const { data, status } = err.response
      toast.error(`${status} - ${data}`)
    }
  }

  useEffect(() => {
    const token = searchParams.get('token')
    const fetchVerifyToken = async (token: string) => {
      const { data } = await api.post('/v1/verify-token', {
        token,
      })
      setEmail(data.user?.email)
    }
    fetchVerifyToken(token!).catch((err) => {
      console.log('Error fetching', err)
      const { data, status } = err.response
      toast.error(`${status} - ${data}`)
    })
  }, [searchParams])

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="w-screen h-screen p-4 lg:p-0  flex flex-col items-center justify-center gap-4">
        <div className="overflow-y-hidden h-24 lg:h-28 flex items-center justify-center">
          <img
            className="w-96 lg:w-[30rem]"
            src={'/assets/LogoIPersonality.svg'}
            alt="logo"
          />
        </div>
        <span className="text-lg font-bold m-5">
          <Translator path="recoverPassword.redefinePassword" />
        </span>
        <div className="w-full lg:w-1/4 flex flex-col">
          <span>
            <Translator path="recoverPassword.emailAddress" />
          </span>
          <input
            className="h-11 my-1 px-3 border border-gray rounded-md focus:outline-none disabled:opacity-50 disabled:bg-white"
            placeholder="Enter your email address"
            type="text"
            id="email"
            value={email}
            disabled
          />
        </div>
        <div className="w-full lg:w-1/4 flex flex-col ">
          <div className="flex items-center justify-between">
            <span>
              <Translator path="recoverPassword.password" />
            </span>
          </div>
          <input
            className="h-11 my-1 px-3 border border-gray rounded-md focus:outline-none"
            placeholder="Enter your password"
            type="password"
            id="password"
            {...register('password', { required: true })}
          />
          {errors.password ? (
            <span className="text-red text-xs">{errors.password.message}</span>
          ) : (
            <span className="text-white text-xs">
              <Translator path="recoverPassword.password" />
            </span>
          )}
        </div>
        <div className="w-full lg:w-1/4 flex flex-col ">
          <div className="flex items-center justify-between">
            <span>Confirm Password</span>
          </div>
          <input
            className="h-11 my-1 px-3 border border-gray rounded-md focus:outline-none"
            placeholder="Confirm your password"
            type="password"
            id="confirmPassword"
            {...register('confirmPassword', { required: true })}
          />
          {errors.confirmPassword ? (
            <span className="text-red text-xs">
              {errors.confirmPassword.message}
            </span>
          ) : (
            <span className="text-white text-xs">
              <Translator path="recoverPassword.confirmPassword" />
            </span>
          )}
        </div>
        <button
          disabled={!isDirty || !isValid}
          className="w-full lg:w-1/4 bg-[#0284C7] px-20 font-semibold py-2 rounded-md text-white hover:bg-[#0ea5e9] disabled:opacity-50"
        >
          <Translator path="recoverPassword.changePassword" />
        </button>
        <div className="flex gap-2 items-center">
          <span>© {new Date().getFullYear()} IPersonality</span>
          <span className="text-sm">V: {packageJson.version}</span>
        </div>
      </div>
    </form>
  )
}

export { RecoverPassword }
