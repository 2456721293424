import { useQuery } from 'react-query'
import api from '../../../services/api'

interface User {
  id: string
  name: string
  lastname: string
  email: string
  ref_by_user_id: string
  wiaard_step: string
  ref_code: string
  avatar: string
  phone: string
  gender: string
  plan_id: string
  nickname: string
  fileName: string
}

async function getProfile(id: string): Promise<User> {
  const { data } = await api.get(`/v1/profile/${id}`)

  const user = {
    id: data.id,
    name: data.name,
    lastname: data.lastname,
    email: data.email,
    ref_by_user_id: data.ref_by_user_id,
    wiaard_step: data.wiaard_step,
    ref_code: data.ref_code,
    avatar: data.avatar,
    phone: data.phone,
    gender: data.gender,
    plan_id: data.plan_id,
    nickname: data.nickname,
    fileName: data.fileName,
  }

  return user
}

export function useProfile(id: string) {
  return useQuery(['profile', id], () => getProfile(id))
}
