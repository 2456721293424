// Sidebar refatorada
import React, { useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import packageJson from '../../package.json'
import { useAuth } from './hooks/provider/auth'
import {
  ChartLineUp,
  ChatCenteredDots,
  CurrencyCircleDollar,
  FolderStar,
  House,
  LineSegments,
  List,
  Newspaper,
  ShoppingCart,
  SignOut,
  User,
  UserCircle,
  UserCircleGear,
  UsersThree,
  X,
} from '@phosphor-icons/react'

// import MenuArrow from '/assets/MenuArrow.svg'

import '../styles/scrollbar.css'
import { I18nComponent } from './I18n/I18n'
import { Translator } from './I18n/Translator'

interface IconProps {
  className?: string
  size?: number
}

interface SubRoute {
  id: number
  title: string | React.ReactNode
  path: string
  Icon: React.ComponentType<IconProps> | React.ComponentType
  span?: boolean
  title_span?: string | React.ReactNode
  color?: string
  active?: boolean
}

interface Route {
  id: number
  title: string | React.ReactNode
  path: string
  span?: boolean
  title_span?: string | React.ReactNode
  color?: string
  Icon: React.ComponentType<IconProps> | React.ComponentType
  subRoutes?: SubRoute[]
  active?: boolean
}

const freePlanRoutes: Route[] = [
  {
    id: 1,
    title: <Translator path="sideBar.home" />,
    path: 'home',
    Icon: House,
    active: true,
  },
  {
    id: 2,
    title: <Translator path="sideBar.profile" />,
    path: 'profile',
    Icon: UserCircle,
    active: true,
  },
  {
    id: 3,
    title: <Translator path="sideBar.selfKnowledge" />,
    path: 'selfKnowledge',
    Icon: User,
    span: true,
    title_span: <Translator path="sideBar.new" />,
    color: 'bg-[#37aa02]',
    active: true,
  },
  {
    id: 4,
    title: <Translator path="sideBar.documents" />,
    path: '#',
    Icon: Newspaper,
    span: true,
    title_span: <Translator path="sideBar.brief" />,
    color: 'bg-[#f6b206]',
    active: false,
  },
  {
    id: 5,
    title: <Translator path="sideBar.developYourself" />,
    path: 'development',
    Icon: UserCircleGear,
    active: true,
  },
  {
    id: 6,
    title: <Translator path="sideBar.community" />,
    path: 'community',
    Icon: UsersThree,
    active: true,
    span: true,
    title_span: <Translator path="sideBar.viewMore" />,
    color: 'bg-[#37aa02]',
  },
  {
    id: 7,
    title: <Translator path="sideBar.chat" />,
    path: '#',
    Icon: ChatCenteredDots,
    span: true,
    title_span: <Translator path="sideBar.brief" />,
    color: 'bg-[#f6b206]',
    active: false,
  },
  {
    id: 8,
    title: <Translator path="sideBar.marketplace" />,
    path: '#',
    Icon: ShoppingCart,
    span: true,
    title_span: <Translator path="sideBar.brief" />,
    color: 'bg-[#f6b206]',
    active: true,
  },
  // {
  //   id: 9,
  //   title: <Translator path="sideBar.events" />,
  //   path: '/events',
  //   Icon: CalendarPlus,
  //   span: true,
  //   title_span: <Translator path="sideBar.brief" />,
  //   color: 'bg-[#f6b206]',
  //   active: true,
  // },
]

const adminPlanRoutes: Route[] = [
  {
    id: 9,
    title: <Translator path="sideBar.financial" />,
    path: 'financial',
    Icon: CurrencyCircleDollar,
    active: true,
  },
  {
    id: 10,
    title: <Translator path="sideBar.myConscience" />,
    path: '#',
    Icon: LineSegments,
    span: true,
    title_span: <Translator path="sideBar.brief" />,
    color: 'bg-[#f6b206]',
    active: false,
  },
  {
    id: 11,
    title: <Translator path="sideBar.careerPath" />,
    path: 'careerPath',
    Icon: FolderStar,
    active: true,
  },
  {
    id: 12,
    title: <Translator path="sideBar.sicTehBusiness" />,
    Icon: ChartLineUp,
    path: '#',
    span: true,
    title_span: <Translator path="sideBar.brief" />,
    color: 'bg-[#f6b206]',
    active: false,
  },
]

const otherPlanRoutes: Route[] = [
  {
    id: 9,
    title: <Translator path="sideBar.financial" />,
    path: 'financial',
    Icon: CurrencyCircleDollar,
    active: true,
  },
  {
    id: 10,
    title: <Translator path="sideBar.myConscience" />,
    path: '#',
    Icon: LineSegments,
    span: true,
    title_span: 'Breve',
    color: 'bg-[#f6b206]',
    active: false,
  },
  {
    id: 11,
    title: <Translator path="sideBar.sicTehBusiness" />,
    Icon: ChartLineUp,
    path: '#',
    span: true,
    title_span: 'Breve',
    color: 'bg-[#f6b206]',
    active: false,
  },
]

const SideBar: React.FC = () => {
  const [url, setUrl] = useState(
    window.location.pathname.substring(
      window.location.pathname.indexOf('/') + 1,
    ),
  )
  const [icon, setIcon] = useState(false)

  const { user, logout, plan } = useAuth()

  const containerRef = useRef<HTMLDivElement>(null)
  const infoRef = useRef<HTMLDivElement>(null)
  const navBarRef = useRef<HTMLDivElement>(null)
  const logoutRef = useRef<HTMLDivElement>(null)

  const changeVisibility = (mobile: boolean) => {
    // if(mobile) {
    const info = infoRef.current
    const navBar = navBarRef.current
    const logout = logoutRef.current
    const container = containerRef.current
    const visibilityInfo = getComputedStyle(info as unknown as Element).display
    const containerWidth = getComputedStyle(
      container as unknown as Element,
    ).width
    // console.log('teste', Number(containerWidth.split('px')[0]))
    if (
      info &&
      navBar &&
      logout &&
      container &&
      Number(containerWidth.split('px')[0]) < 900 &&
      Number(containerWidth.split('px')[0]) > 360
    ) {
      setIcon(!icon)
      if (visibilityInfo === 'none') {
        container.style.position = 'absolute'
        container.style.bottom = '0'
        container.style.top = '0'
        info.style.display = 'flex'
        navBar.style.display = 'flex'
        logout.style.display = 'flex'
      } else {
        container.style.position = 'static'
        info.style.display = 'none'
        navBar.style.display = 'none'
        logout.style.display = 'none'
      }
    }

    // }
  }

  // const plano = localStorage.getItem('@ipersonality:plan')
  const plano = plan
  const parsePlano = plano || null
  const isFreePlan = plano ? parsePlano.name === 'FREE' : false

  return (
    <div
      ref={containerRef}
      className="w-full lg:static lg:w-[360px] lg:h-screen z-50 flex flex-col items-center justify-between py-2 px-4 lg:py-2 lg:px-3 lg:pr-2 gap-3 bg-white lg:overflow-y-auto"
    >
      <div className="flex w-full items-center justify-between lg:justify-center">
        <Link
          to={'/home'}
          className="overflow-y-hidden h-14 lg:h-16 flex items-center justify-center"
        >
          <img
            className="w-48 lg:w-80"
            src={'/assets/LogoIPersonality.svg'}
            alt="logo"
          />
        </Link>
        <button className="lg:hidden" onClick={() => changeVisibility(true)}>
          {icon ? <X size={24} /> : <List size={24} />}
        </button>
      </div>
      <div
        ref={infoRef}
        className="hidden lg:flex flex-col items-center gap-2 mb-2"
      >
        {/* {user?.avatar ? ( */}
        {/*  <Link to={'/profile'}> */}
        {/*    <img */}
        {/*      src={user?.avatar} */}
        {/*      alt="profile" */}
        {/*      className="rounded-full w-20 h-20 object-cover" */}
        {/*    /> */}
        {/*  </Link> */}
        {/* ) : ( */}
        <img src={'/assets/ProfileLogo.svg'} alt="profile" />
        {/* )} */}
        <span className="text-sm hidden lg:flex items-center flex-col">
          <h4>{user?.name}</h4>
          {import.meta.env.VITE_PARTNER_IDS?.split(',').includes(user?.id) && (
            <span className="font-semibold uppercase">Licenciado</span>
          )}
        </span>
        <Link to={'/profile'}>
          <span className="text-sm">
            <b>Email</b>: <span>{user?.email}</span>
          </span>
        </Link>
        <I18nComponent />
      </div>

      <div
        className="h-full w-full hidden lg:flex flex-col gap-1 overflow-y-scroll scroll"
        ref={navBarRef}
      >
        {(isFreePlan
          ? freePlanRoutes
          : parsePlano?.name === 'ADMIN'
          ? [...freePlanRoutes, ...adminPlanRoutes]
          : [...freePlanRoutes, ...otherPlanRoutes]
        ).map((route) => (
          <Link
            key={route.id}
            to={route.active === true ? route.path : '#'}
            onClick={() => {
              setUrl(route.path)
              changeVisibility(false)
            }}
            className={`${
              url === route.path ? 'bg-lightGray' : ''
            } w-full flex items-center gap-3 hover:bg-lightGray h-10 active:bg-lightGray cursor-pointer focus:bg-lightGray rounded-lg p-2`}
          >
            <route.Icon className="text-mediumGray" size={25} />
            <span>{route.title}</span>
            {route.span === true ? (
              <span
                className={`${route.color} ml-2 text-xs text-white px-2 py-1 rounded-md truncate`}
              >
                {' '}
                {route.title_span}{' '}
              </span>
            ) : null}
          </Link>
        ))}
      </div>
      <div
        ref={logoutRef}
        className="w-full hidden lg:flex flex-col items-center gap-2"
      >
        <Link
          to={''}
          onClick={logout}
          className="w-full flex items-center gap-3 hover:bg-lightGray h-10 cursor-pointer focus:bg-lightGray rounded-lg p-1"
        >
          <SignOut className="text-mediumGray" size={25} />
          <span>
            <Translator path="sideBar.logout" />
          </span>
        </Link>
        <div className="flex gap-2 items-center">
          <span>© {new Date().getFullYear()} IPersonality</span>
          <span className="text-sm">V: {packageJson.version}</span>
        </div>
      </div>
    </div>
  )
}

export { SideBar }
