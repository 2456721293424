import React, {
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'

import { ProductCard } from '../components/ProductCard'
import { YoutubeFrame } from '../components/YoutubeFrame'

import { Gear, UploadSimple, ShoppingCart } from '@phosphor-icons/react'

import api from '../services/api'
import { toast } from 'react-toastify'
import { ModalRight } from '../components/ModalRight'
import { useForm } from 'react-hook-form'
import { ButtonLoading } from '../components/ButtonLoading'

export interface IMarketplaceDTO {
  id?: string
  title: string
  description?: string
  price_full: number
  portion: number
  img_background?: string
  user_id: string
}

export interface ICreateMarketplaceDTO {
  title: string
  description?: string
  price_full: number
  portion: number
  img_background?: string
  user_id: string
}

const LoadMarketplace = () => {
  const [marketplace, setMarketplace] = useState<IMarketplaceDTO[]>(
    [] as IMarketplaceDTO[],
  )
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [error, setError] = useState<string | null>(null)

  useEffect(() => {
    let ignore = false
    const fetchData = async () => {
      setIsLoading(true)
      try {
        const { data } = await api.get('/v1/marketplace/find-all')
        if (!ignore) {
          if (!data?.marketplace || data.marketplace.length === 0) {
            const errorMsg = 'Nenhum marketplace encontrado'
            toast.error(errorMsg)
            setError(errorMsg)
          }
          setMarketplace(data.marketplace)
        }
      } catch (error) {
        const errorMsg =
          'Houve um erro ao buscar as marketplaces. Por favor, tente novamente mais tarde.'
        toast.error(errorMsg)
        console.error('Erro ao buscar marketplaces:', error)
        setError(errorMsg)
      } finally {
        setIsLoading(false)
      }
    }

    fetchData()
    return () => {
      ignore = true
    }
  }, [])

  const [isOpenProduct, setIsOpenProduct] = useState(false)
  const setIsOpenProductController = () => setIsOpenProduct((state) => !state)

  if (isLoading) return <p>Carregando...</p>

  if (error)
    return (
      <div className="border border-red-400 rounded-b bg-[#ffd1d1] px-4 py-3 text-red">
        {error}
      </div>
    )

  if (!marketplace) return null

  return (
    <>
      {marketplace?.map((item) => (
        <ProductCard
          key={item.id}
          image={
            item.img_background
              ? item.img_background
              : '/assets/MarketPlaceCard.svg'
          }
          title={item.title}
          description={item.description}
          price={item.price_full}
          juros={item.portion.toString()}
          onclick={setIsOpenProductController}
        />
      ))}
    </>
  )
}

const Marketplace: React.FC = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ICreateMarketplaceDTO>()

  const [isOpen, setIsOpen] = useState(false)
  const setIsOpenController = () => setIsOpen((state) => !state)

  const [isLoading, setIsLoading] = useState<boolean>(false)

  const parcelas = [
    { id: 1, value: 2 },
    { id: 2, value: 4 },
    { id: 3, value: 6 },
    { id: 4, value: 8 },
    { id: 5, value: 10 },
    { id: 6, value: 12 },
  ]

  const toggleOpen = (setValue: any) => {
    setValue((prev: any) => !prev)
  }

  const handleCreateMarkeplace = async (data: ICreateMarketplaceDTO) => {
    try {
      setIsLoading(true)
      const result = await api.post('/v1/marketplace/create', data)

      if (result.status === 201) {
        toast.success('Marketplace criado com sucesso')
      }
      setIsLoading(false)
    } catch (error) {
      toast.error('Erro ao criar o markeplace, tente novamente mais tarde!')
    } finally {
      setIsLoading(false)
    }

    setIsOpenController()
  }

  const [isOpenProduct, setIsOpenProduct] = useState(false)
  const setIsOpenProductController = () => setIsOpenProduct((state) => !state)
  return (
    <div className="w-full h-full flex flex-col p-7 bg-lighterGray gap-4 overflow-y-scroll scroll">
      <ModalRight
        title="Cadastra novo markeplace"
        open={isOpen}
        setIsOpenController={() => toggleOpen(setIsOpenController)}
      >
        <form onSubmit={handleSubmit(handleCreateMarkeplace)}>
          <div className="flex flex-col gap-4">
            <div className="flex flex-col text-textGray w-full">
              <span className="text-sm leading-7">Nome do produto</span>
              <input
                className="p-2 bg-white border border-borderColor rounded text-sm outline-0"
                type="text"
                id="title"
                {...register('title', { required: true })}
                placeholder="Favor digitar o nome do produto!"
              />
              {errors.title && (
                <span className="text-red text-xs px-2 py-1">
                  * Título é obrigatório !
                </span>
              )}
            </div>
            <div className="flex flex-col text-textGray w-full">
              <span className="text-sm leading-7">Imagem do Produto</span>
              <div className="flex items-center gap-4">
                <div className="w-1/3 flex justify-between bg-white text-sm text-black p-2 rounded-md border border-borderColor">
                  <input
                    id="img_background"
                    type="file"
                    className="hidden"
                    placeholder="imagem de backuground"
                    {...register('img_background', { required: false })}
                  />
                  <span>Lorem ipsum</span>
                  <UploadSimple color="#000" size={20} />
                </div>
                {errors.title && (
                  <span className="text-red text-xs px-2 py-1">
                    * Imagem é obrigatório!
                  </span>
                )}
                <span className="text-red text-xs">
                  {' '}
                  * Arquivo de até 50MB do tipo .png
                </span>
              </div>
            </div>
            <div className="flex flex-col text-textGray w-full mb-2">
              <span className="text-sm leading-7">
                Texto promovendo o produto
              </span>
              <textarea
                className="resize-none p-2 h-44 bg-white border border-borderColor rounded text-sm outline-0"
                id="description"
                {...register('description', { required: true })}
                placeholder="Descreva a descrição do seu produto."
              />
              {errors.description && (
                <span className="text-red text-xs px-2 py-1">
                  * Descrição do produto é obrigatório!
                </span>
              )}
            </div>

            <div className="flex w-1/2 gap-4">
              <div className="flex flex-col text-textGray w-full">
                <span className="text-sm leading-7">Valor do produto</span>

                <input
                  className="w-full p-2 bg-white border border-borderColor rounded text-sm outline-0 mt-2"
                  type="text"
                  id="price_full"
                  {...register('price_full', { required: true })}
                  placeholder="preço do produto"
                />
                {errors.price_full && (
                  <span className="text-red text-xs px-2 py-1">
                    * Preço do produto é obrigatório!
                  </span>
                )}
              </div>
              <div className="flex flex-col text-textGray w-full">
                <span className="text-sm leading-7">Parcelas disponíveis</span>
                <select
                  className="w-full p-2 bg-white border border-borderColor rounded text-sm outline-0 mt-2"
                  id="portion"
                  placeholder="quantidade de parcelas"
                  {...register('portion', { required: true })}
                >
                  {parcelas.map((item) => (
                    <option value={item.id} key={item.id}>
                      {item.value}
                    </option>
                  ))}
                </select>

                {errors.portion && (
                  <span className="text-red text-xs px-2 py-1">
                    * Parcelas é obrigatório!
                  </span>
                )}
              </div>
            </div>
            <div className="self-end">
              <ButtonLoading
                type="submit"
                isLoading={isLoading}
                title="Criar Markeplace"
                css_class="flex justify-between bg-buttonBlue text-sm text-white px-10 py-1.5 rounded-md"
              />
              {/* <button
                onClick={setIsOpenController}
                className="flex justify-between bg-buttonBlue text-sm text-white px-10 py-1.5 rounded-md"
              >
                Salvar documento
              </button> */}
            </div>
          </div>
        </form>
      </ModalRight>
      <ModalRight
        title="Detalhes do produto"
        open={isOpenProduct}
        setIsOpenController={() => toggleOpen(setIsOpenProductController)}
      >
        <div className="flex flex-col mt-4 gap-4">
          <div className="flex gap-4 items-center">
            <img src={'/assets/MarketplaceProduct.svg'} alt="marketplace" />
            <div className="flex flex-col">
              <span className="font-bold text-lg mb-2">Evento Lorem Ipsum</span>
              <span className="text-xs text-mediumGray">
                Lorem ipsum dolor sit amet consectetur. Nec nibh aliquam cursus
                nunc lorem velit tristique. Arcu duis non morbi volutpat nunc
                pellentesque integer ligula accumsan. Eu massa condimentum
                auctor dolor lectus diam adipiscing est dolor. Purus eget
                ultricies posuere nam ante eget. Dictumst nulla gravida
                consequat massa nulla eget metus amet. Nullam vestibulum
                facilisi neque massa donec. Adipiscing congue aliquam gravida
                nulla faucibus eget cursus varius tristique. Enim odio in at
                aliquam suspendisse amet risus placerat faucibus. Porta viverra
                curabitur imperdiet sem. Cras rhoncus nunc risus urna mattis
                odio eget eget. Adipiscing diam nec hendrerit pellentesque
                mauris cursus ultricies. Viverra dignissim justo tortor
                adipiscing.
              </span>
            </div>
          </div>
          <div className="flex justify-between">
            <div className="flex flex-col">
              <span className="font-bold text-lg mb-2">
                Ver vídeo explicativo
              </span>
              <video
                className={'w-full h-44 mx-auto lg:w-[500px] lg:h-[275px]'}
                controls
                autoPlay={false}
              >
                <source
                  src={
                    'https://ipersonality.app/assets/deibson-entrada-plataforma-ipersonality.mp4'
                  }
                  type="video/mp4"
                />
              </video>
            </div>
            <div className="self-end text-darkBlueGreen flex flex-col border border-borderColor rounded-lg p-4 gap-3 w-64 h-48">
              <div className="flex flex-col gap-1">
                {' '}
                <span>
                  <strong className=" font-extrabold text-xl">R$ 522,35</strong>
                  à vista
                </span>
                <span className="text-xs">ou 3x de R$ 174,11 sem juros</span>
              </div>
              <button className="h-8 gap-4 flex items-center justify-center text-white bg-darkGreen rounded-md text-sm">
                Comprar agora
                <ShoppingCart size={22} color="#fff" />
              </button>
              <hr className="text-gray" />
              <span className="text-xs text-mediumGray">
                Este é um produto digital, você receberá as informações para
                acessá-lo através do email.
              </span>
            </div>
          </div>
        </div>
      </ModalRight>
      <div className="w-full bg-white p-3 rounded-lg border-2 border-borderColor flex items-center justify-between">
        <span className="font-semibold text-base">Marketplace</span>
        <button
          onClick={setIsOpenController}
          className="gap-4 flex items-center justify-between self-center bg-darkBlueGreen text-sm text-white px-4 py-1 rounded-md"
        >
          Cadastrar Novo Produto
          <Gear color="#fff" size={20} />
        </button>
      </div>
      {/* <div className="w-full flex items-center justify-around flex-wrap gap-4"> */}
      <div className="w-full grid gap-4 gridAuto">
        <LoadMarketplace />
      </div>
    </div>
  )
}

export { Marketplace }
