/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react'
import { QuestionInfo } from '../pages/SelfKnowledge/Form'
import { Popover } from '@headlessui/react'

interface ChoiceCardProps {
  question: string
  type: string
  description: string
  questions: QuestionInfo[]
  onAnswerQuestion: (type: string, value: number) => void
  handleGetAnswerStatus: (
    type: string,
    value: number,
  ) => 'DISABLED' | 'ENABLED' | 'QUESTION_ENABLED'
  step: number
}

const ChoiceCard: React.FC<ChoiceCardProps> = ({
  question,
  type,
  description,
  questions,
  onAnswerQuestion,
  handleGetAnswerStatus,
  step,
}) => {
  return (
    <div className="flex flex-col bg-[#D1D5DB] rounded pt-5 px-4 pb-3">
      {step !== 3 ? (
        <div className="flex justify-between items-center mb-2 font-semibold">
          <span className="flex">{question}</span>
          <Popover className={`flex relative w-full justify-end`}>
            <Popover.Button className={''}>
              <img src={'/assets/Info.svg'} alt="" />
            </Popover.Button>

            {/* <Popover.Panel className="absolute z-10 bottom-10 right-2 rounded-md w-full"> */}
            <Popover.Panel
              className={
                'absolute w-full bg-darkGray bottom-7 right-3 min-w-[16rem] h-fit rounded-md'
              }
            >
              <span className="relative flex p-2 text-white text-sm rounded-md">
                {description}
              </span>
            </Popover.Panel>
          </Popover>
          {/* <button onClick={() => setShowDescription(!showDescription)}>
          <img src={'/assets/Info.svg'} alt="" />
        </button> */}
        </div>
      ) : (
        <span className="flex justify-between items-center mb-2 font-semibold">
          {question}
        </span>
      )}
      <hr className="w-full text-[#BDBDBD] mt-2" />
      <div className="flex flex-col lg:flex-row items-center justify-between gap-2 lg:gap-4">
        {questions.map((item, index) => {
          return (
            <button
              key={index + 1}
              value={index + 1}
              className={`${
                handleGetAnswerStatus(type, index) === 'QUESTION_ENABLED'
                  ? 'bg-[#075985]'
                  : 'bg-[#0EA5E9]'
              } w-full text-white rounded-lg px-4 font-semibold cursor-pointer mt-2 py-3 items-center justify-center flex disabled:bg-white disabled:text-[#c9ccd0] disabled:cursor-not-allowed`}
              disabled={handleGetAnswerStatus(type, index) === 'DISABLED'}
              onClick={() => onAnswerQuestion(type, index)}
            >
              {index + 1}
            </button>
          )
        })}
      </div>
    </div>
  )
}

export { ChoiceCard }
