import React, { useState } from 'react'

import { UploadSimple, Gear } from '@phosphor-icons/react'

import { ModalRight } from '../components/ModalRight'
import { ImageModal } from '../components/ImageModal'

const Documents: React.FC = () => {
  const [isOpenUpload, setIsOpenUpload] = useState(false)
  const setIsOpenUploadController = () => setIsOpenUpload((state) => !state)

  const [isOpenGear, setIsOpenGear] = useState(false)
  const setIsOpenGearController = () => setIsOpenGear((state) => !state)

  const [isOpen, setIsOpen] = useState(false)
  const setIsOpenController = () => setIsOpen((state) => !state)

  const [isOpenEdit, setIsOpenEdit] = useState(false)
  const setIsOpenEditController = () => setIsOpenEdit((state) => !state)

  const [isOpenUpdate, setIsOpenUpdate] = useState(false)
  const setIsOpenUpdateController = () => setIsOpenUpdate((state) => !state)

  const toggleOpen = (
    setValue: React.Dispatch<React.SetStateAction<boolean>>,
  ) => {
    setValue((prev: boolean) => !prev)
  }
  return (
    <div className="w-full h-full flex flex-col p-7 bg-lighterGray gap-4 overflow-hidden">
      <ModalRight
        title="Upload de documento"
        open={isOpenUpload}
        setIsOpenController={() => toggleOpen(setIsOpenUploadController)}
      >
        <div className="flex flex-col text-textGray w-1/2">
          <span className="text-sm leading-7">Nome do documento</span>
          <input
            className="p-2 bg-white border border-borderColor rounded text-sm outline-0"
            type="text"
            name="name"
            id="name"
            placeholder="Lorem ipsum"
          />
        </div>
        <div className="flex flex-col text-textGray w-1/2">
          <span className="text-sm leading-7">Upload de documento</span>
          <button className="w-1/2 flex justify-between bg-white text-sm text-black p-2 rounded-md border border-borderColor">
            Lorem ipsum
            <UploadSimple color="#000" size={20} />
          </button>
        </div>
        <div className="self-end">
          <button
            onClick={setIsOpenController}
            className="flex justify-between bg-buttonBlue text-sm text-white px-10 py-1.5 rounded-md"
          >
            Salvar documento
          </button>
        </div>
      </ModalRight>
      <ModalRight
        title="Upload de documento"
        open={isOpenGear}
        setIsOpenController={() => toggleOpen(setIsOpenGearController)}
      >
        <div className="flex flex-col text-textGray w-1/2">
          <span className="text-sm leading-7">Nome do documento</span>
          <input
            className="p-2 bg-white border border-borderColor rounded text-sm outline-0"
            type="text"
            name="name"
            id="name"
            placeholder="Lorem ipsum"
          />
        </div>
        <div className="flex flex-col text-textGray w-1/2">
          <span className="text-sm leading-7">Upload de documento</span>
          <button className="w-1/2 flex justify-between bg-white text-sm text-black p-2 rounded-md border border-borderColor">
            Lorem ipsum
            <UploadSimple color="#000" size={20} />
          </button>
        </div>
        <div className="flex gap-2 mt-4 self-end">
          {isOpenEdit ? (
            <>
              <button
                onClick={() => {
                  toggleOpen(setIsOpenEditController)
                  toggleOpen(setIsOpenUpdateController)
                }}
                className="flex justify-between bg-buttonBlue text-sm text-white px-10 py-1.5 rounded-md"
              >
                Atualizar documento
              </button>
            </>
          ) : (
            <>
              <button
                onClick={setIsOpenEditController}
                className="flex justify-between bg-buttonBlue text-sm text-white px-10 py-1.5 rounded-md"
              >
                Editar Documento
              </button>
              <button className="flex justify-between bg-buttonRed text-sm text-white px-10 py-1.5 rounded-md">
                Deletar documento
              </button>
            </>
          )}
        </div>
      </ModalRight>
      <ImageModal
        open={isOpen}
        image={'/assets/ModalImage.svg'}
        onclick={() => {
          toggleOpen(setIsOpenUploadController)
          toggleOpen(setIsOpenController)
        }}
        title="Você salvou um novo
        documento."
        button={true}
      ></ImageModal>
      <ImageModal
        open={isOpenUpdate}
        image={'/assets/ModalImage.svg'}
        onclick={() => {
          toggleOpen(setIsOpenGearController)
          toggleOpen(setIsOpenUpdateController)
        }}
        title="Você atualizou o 
        documento."
        button={true}
      ></ImageModal>
      <div className="w-full bg-white p-3 rounded-lg border-2 border-borderColor flex items-center justify-between">
        <span className="font-semibold text-base">
          Documentos da plataforma
        </span>
        <button
          onClick={setIsOpenUploadController}
          className="gap-4 flex items-center justify-between self-center bg-lightGreen text-sm text-black px-4 py-1 rounded-md"
        >
          Upload de documento
          <UploadSimple color="#000" size={20} />
        </button>
      </div>
      <div className="w-1/3 bg-white p-3 rounded-lg border-2 border-borderColor flex items-center justify-between">
        <span className="font-semibold text-base">Termos de uso</span>
        <Gear
          onClick={setIsOpenGearController}
          className="cursor-pointer"
          color="#000"
          size={20}
        />
      </div>
    </div>
  )
}

export { Documents }
