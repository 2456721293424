import { BrowserRouter } from 'react-router-dom'
import { RoutesPage } from './routes'
import { ToastContainer } from 'react-toastify'
import AppProvider from './components/hooks/provider'
import 'react-toastify/dist/ReactToastify.css'
import { ReactQueryDevtools } from 'react-query/devtools'
import { QueryClientProvider } from 'react-query'
import { queryClient } from './services/queryClient'
import './i18n'

const App: React.FC = () => {
  return (
    <>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <AppProvider>
            <RoutesPage />
          </AppProvider>
        </BrowserRouter>
        <ReactQueryDevtools />
      </QueryClientProvider>
      <ToastContainer />
    </>
  )
}

export { App }
