import React, { useEffect, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import packageJson from '../../package.json' // Verifique o caminho correto para o seu arquivo package.json

import { useAuth } from '../components/hooks/provider/auth'
import { ButtonLoading } from '../components/ButtonLoading'
import { Link } from 'react-router-dom'
import { I18nComponent } from '../components/I18n/I18n'
import { Translator } from '../components/I18n/Translator'

interface SignInFormData {
  email: string
  password: string
}

const Login: React.FC = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<SignInFormData>()
  const { login, token } = useAuth()
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const onSubmit: SubmitHandler<SignInFormData> = async ({
    email,
    password,
  }) => {
    try {

      setIsLoading(true)

      await login({
        email,
        password,
      })

      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
    } finally {
      setIsLoading(false)
    }
  }

  // useEffect(() => {
  //   if (token) {

  //     window.location.href = '/home'
  //   }
  // }, [token])

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="w-screen h-screen p-4 lg:p-0 flex flex-col items-center justify-center gap-4">
        <div className="overflow-y-hidden h-24 lg:h-28 flex items-center justify-center">
          <img
            className="w-96 lg:w-[30rem]"
            src={'/assets/LogoIPersonality.svg'}
            alt="logo"
          />
        </div>
        <I18nComponent />
        <span className="text-lg font-bold m-5">
          <Translator path="login.title" />
        </span>
        <div className="w-full lg:w-1/4 flex flex-col">
          <span>
            <Translator path="login.email" />
          </span>
          <input
            className="h-11 my-1 px-3 border border-gray rounded-md focus:outline-none"
            placeholder="Enter your email address"
            type="text"
            id="email"
            {...register('email', { required: true })}
          />
          {errors.email && (
            <span className="text-red text-xs">
              <Translator path="login.errorEmail" />
            </span>
          )}
        </div>
        <div className="w-full lg:w-1/4 flex flex-col ">
          <div className="flex items-center justify-between">
            <span>
              <Translator path="login.password" />
            </span>
            <Link
              to={'/forgotPassword'}
              className="text-mediumBlue font-bold cursor-pointer"
            >
              <Translator path="login.forgotPassword" />
            </Link>
          </div>
          <input
            className="h-11 my-1 px-3 border border-gray rounded-md focus:outline-none"
            placeholder="Enter your password"
            type="password"
            id="password"
            {...register('password', { required: true })}
          />
          {errors.password && (
            <span className="text-red text-xs">
              <Translator path="login.errorPassword" />
            </span>
          )}
        </div>

        <ButtonLoading
          isLoading={isLoading}
          title={<Translator path="login.btn" />}
          css_class='w-full lg:w-1/4                                                      bg-lightBlue px-20 font-semibold py-2 rounded-md text-white hover:bg-lightBlueHover"'
        />
        <div className="flex gap-2 items-center">
          <span>© {new Date().getFullYear()} IPersonality</span>
          <span className="text-sm">V: {packageJson.version}</span>
        </div>
      </div>
    </form>
  )
}

export { Login }
