import React from 'react'
import { ArrowRight } from '@phosphor-icons/react'
import { useAuth } from '../../components/hooks/provider/auth'
import { Translator } from '../../components/I18n/Translator'
import { Trans, useTranslation } from 'react-i18next'

interface IntroductionProps {
  next: () => void
}

const Introduction: React.FC<IntroductionProps> = ({ next }) => {
  const { user } = useAuth()
  const { i18n } = useTranslation()
  return (
    <div className="w-full lg:min-w-[30rem] lg:w-[45%] h-fit p-3 lg:px-6 lg:py-8 gap-4 lg:gap-6 bg-white rounded-lg flex flex-col items-center text-center">
      <span className="flex items-center gap-2 w-fit text-xl">
        <img src={'/assets/Beckon.svg'} alt="" />
        <Translator path="selfKnowledge.introduction.hello" />
        <b>{user?.name}</b>
      </span>
      {/* <p className='leading-5 text-lg'><b>Usuário</b> convida-o a explorar o seu código cognitivo-comportamental com o iPersonality</p> */}
      <p className="leading-5 text-lg">
        <Trans
          i18nKey="selfKnowledge.introduction.firstText"
          components={{ b: <b /> }}
        />
      </p>
      <p className="leading-5 text-lg">
        <Trans
          i18nKey="selfKnowledge.introduction.secondText"
          components={{ b: <b /> }}
        />
      </p>
      <p className="leading-5 text-lg mb-2">
        <Translator path="selfKnowledge.introduction.thirdText" />
      </p>
      <ul className="flex flex-col gap-4 lg:gap-6 px-3">
        {/* <li className='flex gap-10 text-justify text-base leading-5'><img className='w-10 h-10 bg-[#3CABDE] bg-opacity-25 rounded-full' src={'/assets/Check.svg'} alt="" /><p><Translator path="selfKnowledge.introduction.list.one" /></p></li> */}
        <li className="flex gap-10 text-justify text-base leading-5">
          <img
            className="w-10 h-10 bg-[#3CABDE] bg-opacity-25 rounded-full"
            src={'/assets/Check.svg'}
            alt=""
          />
          <p>
            <Trans
              i18nKey="selfKnowledge.introduction.list.one"
              components={{ b: <b /> }}
            />
          </p>
        </li>
        <li className="flex gap-10 text-justify text-base leading-5">
          <img
            className="w-10 h-10 bg-[#3CABDE] bg-opacity-25 rounded-full"
            src={'/assets/Check.svg'}
            alt=""
          />
          <p>
            <Trans
              i18nKey="selfKnowledge.introduction.list.two"
              components={{ b: <b /> }}
            />
          </p>
        </li>
        <li className="flex gap-10 text-justify text-base leading-5">
          <img
            className="w-10 h-10 bg-[#3CABDE] bg-opacity-25 rounded-full"
            src={'/assets/Check.svg'}
            alt=""
          />
          <p>
            <Trans
              i18nKey="selfKnowledge.introduction.list.three"
              components={{ b: <b /> }}
            />
          </p>
        </li>
      </ul>
      <p className="text-lg mt-2 leading-5">
        <Translator path="selfKnowledge.introduction.fourthText" />
      </p>
      <hr className="w-full text-[#BDBDBD]" />
      <button
        onClick={next}
        className="w-full bg-[#0369A1] p-4 flex items-center justify-center gap-4 text-white text-lg rounded-lg"
      >
        <Translator path="selfKnowledge.introduction.btn" />
        <ArrowRight size={24} />
      </button>
    </div>
  )
}

export { Introduction }
