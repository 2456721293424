import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'

// Buscando as nossas traduções da pasta locales
import translations from './locales'

const i18nConfig = {
  resources: translations, // resources são as nossas traduções
  fallbackLng: 'en-US', //comentado e refatorado por xarlys dia 08/02 fallbackLng: 'pt-BR', // fallbackLng é o idioma padrão caso o browser não consiga detectar sozinho
  defaultNS: 'translations', // defaultNS é o namespace padrão, podemos usar 'translations',
  react: {
    // https://react.i18next.com/latest/trans-component#trans-props
    transSupportBasicHtmlNodes: true,
    transKeepBasicHtmlNodesFor: ['br', 'strong', 'b', 'i'],
  },
}

i18n
  .use(LanguageDetector) // Usa o detector de idioma do seu browser
  .use(initReactI18next) // Usa o pacote do i18n específico para React
  .init(i18nConfig) // Usa nossas configurações

export default i18n
