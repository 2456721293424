import React from 'react'

interface MessageProps {
  message: string
  sent: boolean
}

const Message: React.FC<MessageProps> = ({ message, sent }) => {
  return (
    <div
      className={` ${sent ? ' self-end' : 'self-start'} ${
        sent ? 'right-0' : 'left-0'
      } gap-2 w-fit relative m-w-1/3 p-2 rounded-lg flex items-center after:content-none after:absolute after:top-0`}
    >
      {sent ? null : (
        <img className="rounded-full" src={'/assets/BrainLogo.svg'} alt="" />
      )}

      <div
        className={` ${
          sent ? 'bg-messageGreen self-end' : 'bg-messageGray self-start'
        } w-fit relative m-w-1/3 p-2 rounded-lg flex items-center after:content-none after:absolute after:top-0 after:border-solid
        after:border-1`}
      >
        <p>{message}</p>
      </div>
      {sent ? (
        <img className="rounded-full" src={'/assets/BrainLogo.svg'} alt="" />
      ) : null}
    </div>
  )
}

export { Message }
