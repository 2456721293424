import { ArrowRight } from '@phosphor-icons/react'
import React from 'react'
import { Translator } from '../../components/I18n/Translator'

interface InitialProps {
  next: () => void
}

const Initial: React.FC<InitialProps> = ({ next }) => {
  return (
    <div className="w-full h-fit bg-white p-5 lg:p-20 lg:gap-20 flex flex-col lg:flex-row items-center justify-between rounded-lg border-2 border-borderColor">
      <img src={'/assets/SelfKnowledgeImage.svg'} alt="" />
      <div className="flex flex-col gap-2">
        <span className="text-2xl font-extrabold">
          <Translator path="selfKnowledge.initial.title" />
        </span>
        <span>
          <Translator path="selfKnowledge.initial.description" />
        </span>
        <button
          onClick={next}
          className="w-full lg:w-fit py-2 px-5 flex items-center justify-center gap-4 bg-lighterBlue text-darkBlue rounded-lg"
        >
          <Translator path="selfKnowledge.initial.btn" />{' '}
          <ArrowRight size={25} color="#006398" />
        </button>
      </div>
    </div>
  )
}

export { Initial }
