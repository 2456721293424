export default {
  translations: {
    // Mesmo valor usado nas configurações (defaultNS)
    login: {
      title: 'Sign in to your account',
      email: 'Email Address',
      errorEmail: 'Email is required',
      password: 'Password',
      errorPassword: 'Password is required',
      forgotPassword: 'Forgot Password?',
      btn: 'Login',
    },
    invitePage: {
      title: 'Sign up to create your account',
      name: 'Name',
      errorName: 'Name is required',
      lastName: 'Last Name',
      errorLastName: 'Last Name is required',
      emailAddress: 'Email Address',
      errorEmail: 'Email is required',
      password: 'Password',
      errorPassword: 'Password is required',
      nickname: 'Nickname',
      gender: 'Gender',
      male: 'Male',
      female: 'Female',
      errorPage: 'Invitation reference not found.',
      btnNext: 'Next',
      btnRegister: 'Sign up',
      error: 'Error creating record!',
      success: 'Registration completed successfully!',
    },
    sideBar: {
      plan: 'Plan',
      home: 'Home',
      profile: 'Profile',
      selfKnowledge: 'Self knowledge',
      documents: 'Documents',
      developYourself: 'Develop yourself',
      community: 'Community',
      chat: 'Chat',
      marketplace: 'Marketplace',
      events: 'Events',
      financial: 'Financial',
      myConscience: 'My conscience',
      careerPath: 'Career path',
      sicTehBusiness: 'SicTehBusiness',
      viewMore: 'View more',
      brief: 'Brief',
      new: 'New',
      logout: 'Logout',
    },
    home: {
      dashboard: 'Dashboard',
      cardOne: 'Total Primary Assessments and (%) Responded',
      cardTwo: 'Total Reviews per Invitation (%) Responded',
      cardThree: 'To receive IPersonality profile',
      cardFour: 'Σ of recorded transactions',
      graphic: 'Quantity and % of users',
      requestError:
        'There was an error fetching the data, please try again later.',
      msg: 'No data found',
      salutation: 'Welcome',
      videoTitle: 'Welcome video',
      inviteTitle:
        'Help more people discover themselves by sharing your link below.',
      errorShare:
        'An error occurred while generating the sharing link. Please try again later.',
    },
    profile: {
      title: 'Profile',
      typeAccess: 'Type of access/graduation',
      name: 'Name',
      surname: 'Last name',
      nickname: 'Nickname',
      informationNickname:
        '* This is the name that will be displayed to other users and in reports generated by the platform.',
      gender: 'Gender',
      genderMen: 'Male',
      genderWoman: 'Female',
      email: 'E-mail',
      code: 'Affiliate code',
      theCode: 'The code',
      informationCode:
        'will be used to identify users you invite to the platform.',
      buttonCancel: 'Cancel',
      buttonSave: 'To save',
      buttonUpdate: 'To update',
      success: 'Profile updated successfully',
      avatar: 'Avatar updated successfully',
      errorAvatar: 'Error updating avatar',
    },
    selfKnowledge: {
      initial: {
        title: 'Discover your cognitive behavioral code!',
        description: 'Start inventory now and uncover your unique traits',
        btn: 'Discover',
      },
      introduction: {
        hello: 'Hello, ',
        firstText:
          'Cognitive Behavioral Code',
        secondText:
          'At <b>START</b>, you can explore ten groups of words with four different adjectives in each group;',
        thirdText:
          'On each screen, score the adjectives from 1 to 4 and classify them according to the following criteria:',
        list: {
          one: 'Score 01 in 1st place for the adjective that best describes you.',
          two: 'Score 02 in 2nd place for the adjective that best identifies you in sequence.',
          three:
            'Score 03 in 3rd place and score 04 for the 4th and final adjective that describes you the least.',
          four: '',
        },
        fourthText:
          'Click on the 🕕 icon to read the meaning of the adjective if in doubt',
        btn: 'Start',
      },
      form: {
        step: 'Step',
        btnHowAnswer: 'how to respond',
        btnNext: 'Next section',
        btnBack: 'Back',
        success: 'Questionnaire completed successfully',
        error:
          'There was an error filling out the questionnaire, please try again, or contact our support',
        howAnswer: {
          title: 'How to answer the questionnaire?',
          step: {
            one: 'On each screen, 4 adjectives will be presented for you to list according to your degree of identification.',
            two: 'On each screen, 10 adjectives will be presented for you to list according to your degree of identification.',
          },
          textOne: 'Each number corresponds to a degree of relevance, being:',
          list: {
            veryRelevant: 'Very relevant',
            relevant: 'Relevant',
            someRelevance: 'Some relevance',
            lowRelevance: 'Low relevance',
          },
          textTwo:
            'Select a number by <b>clicking or tapping the button</b> corresponding to your degree of identification with the adjective. To undo the selection, simply click or tap the button again.',
          textThree:
            'If you have any doubts regarding the meaning of an adjective, click on the 🕕 icon to find out the definition and check if it is the adjective that best identifies you.',
          btn: 'Close',
        },
      },
      nextStage: {
        first: {
          firstText:
            'Respond again to the mapping of characteristics, but this time portraying how you believe the people who live with you in your day-to-day personal and professional life need you to be in order to have a better connection, performance and results.',
          secondText:
            'Now, the purpose is to score the characteristics from 1 to 4 in the position that portrays how OTHERS expect you to be and act.',
        },
        second: {
          firstText:
            "From now on we'll bring you 10 stages, each with 10 placements that you can score from 1 to 10 in the best way you believe you should to portray your motivators. Always ranking from 1, first and highest (most important) to 10, last and lowest (least relevant).",
          secondText:
            'Think about exactly what is significant in your life, the principles and values you use as a compass to make your decisions, what motivates you and guides your daily actions.',
        },
        btnStart: 'Start',
        btnBack: 'Back',
      },
      result: {
        title: 'Behavioral cognitive code',
        description:
          'Download your Cognitive Behavioral Code in PDF for free by clicking the button below',
        btnTitle: 'Report',
        btnLoading: 'Generating your report',
        btnCreated: 'Download your report',
        videoTitle: 'Self knowledge',
      },
    },
    developYourself: {
      title: 'Develop yourself',
      noPlan: 'No plans found.',
      requestError:
        'There was an error when searching for plans. Please try again later.',
      purchase: 'Purchase',
      acquired: 'Acquired',
    },
    community: {
      community: 'Community',
      select: 'Select Option to Filter',
      notStarted: 'NOT STARTED',
      inProgress: 'IN PROGRESS',
      concluded: 'CONCLUDED',
      name: 'Name',
      userPlan: 'User Plan',
      testStatus: 'Test Status',
      seeData: 'See data',
      loading: 'Loading...',
      yourCommunity: 'Your community',
      inviteTitle: 'Make connections',
      inviteDescription:
        'Click here and select a user to chat within the platform',
      noUser:
        "You don't have a user in your community, use your invite link to invite new users.",
      requestError:
        'There was an error when searching for communities. Please try again later.',
      modal: {
        name: 'Username',
        seeResult: 'See status report',
        testDone: 'fulfilled self-knowledge',
        testNotDone: 'Unfulfilled self-knowledge',
        status: 'Unfulfilled self-knowledge',
        info: 'Information and data from your community',
        errorInfo:
          "You don't have a user in your community, use your invite link to invite new users.",
      },
    },
    recoverPassword: {
      redefinePassword: 'Redefine password',
      emailAddress: 'Email Address',
      password: 'Password',
      confirmPassword: 'Confirm Password',
      changePassword: 'Change Password',
      success: 'Password updated successfully!',
    },
    forgotPassword: {
      title: 'Recover Password',
      email: 'Email address',
      btnRecover: 'Send Recovery Link',
      questionRemember: 'Did you remember the password?',
      return: 'Go to login',
      success: 'An email has been sent to you to recover your password',
    },
    component: {
      inviteCard: {
        info: 'Link copied to clipboard',
        error:
          'There was an error generating sharing link. Please try again later.',
      },
      privateRoute: 'Error - You must be logged in to access this route!',
    },
  },
}
