import { ReactNode, useCallback } from 'react'
import { Navigate } from 'react-router-dom'
import { useAuth } from '../components/hooks/provider/auth'
import { toast } from 'react-toastify'
import { Translator } from '../components/I18n/Translator'

interface ReturnProps {
  children: ReactNode | any
  url?: any
}

function PrivateRoute({ children, url }: ReturnProps) {
  const { user } = useAuth()

  const privateRoute = useCallback(() => {
    try {
      return user ? children : <Navigate to="/" replace />
    } catch (error) {
      toast.error(<Translator path="component.privateRoute" />)
    }
  }, [user, children])

  return privateRoute()
}

export { PrivateRoute }
