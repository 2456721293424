/* eslint-disable prettier/prettier */
export const questionEN = ([
  ['step', 'type', 'name', 'description'],
  [3,'KNO','Learn new things',null],
  [3,'PRO','Work hard to build a successful career',null],
  [3,'EXP','Appreciate nature, the arts, and learn about new cultures',null],
  [3,'CON','Share resources, time, and skills to help others',null],
  [3,'ACH','Achieve meaningful things in life',null],
  [3,'PUR','Understand your purpose and the meaning of life',null],
  [3,'SPI','Express my beliefs and experience true spirituality',null],
  [3,'FRE','Think outside the box in search of new ideas and perspectives',null],
  [3,'JUS','Do what is right and fair in all situations',null],
  [3,'HEA','Maintain a balanced and healthy diet with a focus on food quality',null],
  [3,'KNO','Discover how things work',null],
  [3,'PRO','Have a successful financial life',null],
  [3,'EXP','Experience new things in my life',null],
  [3,'CON','Contribute to social causes',null],
  [3,'ACH','Leave a legacy and make a difference in the world',null],
  [3,'PUR','Give meaning to your choices and actions',null],
  [3,'SPI','Seek the deeper meaning of human existence',null],
  [3,'FRE','I value creativity, the ability to change my mind or plans if necessary',null],
  [3,'JUS','Always act with a strong sense of ethics and morality',null],
  [3,'HEA','Dedicate time and resources to maintaining good personal hygiene and oral health',null],
  [3,'KNO','Acquire new knowledge',null],
  [3,'PRO','Increase my income by investing in good opportunities',null],
  [3,'EXP','Live every moment and enjoy the experiences that life offers',null],
  [3,'CON','Engage in volunteer activities and social actions',null],
  [3,'ACH','Lead people towards audacious goals',null],
  [3,'PUR','Find my purpose, I have a strong interest in knowing myself even more',null],
  [3,'SPI','Have a meditation and prayer routine to connect with the divine',null],
  [3,'FRE','Ease of adapting to new situations as I am not attached to things',null],
  [3,'JUS','Fight against social inequalities and injustices, in favor of fair and egalitarian causes',null],
  [3,'HEA','Avoid the use of harmful substances, such as drugs, alcohol, and tobacco',null],
  [3,'KNO','Be a leader who loves to learn and teach',null],
  [3,'PRO','Be a productive leader in search of more results in less time',null],
  [3,'EXP','Be a leader who values enriching experiences',null],
  [3,'CON','Be a leader who serves their followers and contributes to society',null],
  [3,'ACH','Be a leader who knows how to inspire and motivate others to pursue bold objectives',null],
  [3,'PUR','Be a leader with a strong sense of purpose rooted in my values',null],
  [3,'SPI','Be a leader who values issues related to spirituality',null],
  [3,'FRE','Be a leader who values the freedom of choice and individualities of their followers',null],
  [3,'JUS','Be a leader who values integrity, opting for what is right and fair in all situations',null],
  [3,'HEA','Be a leader who cares about the well-being and physical and mental health of the followers',null],
  [3,'KNO','Be in search of new theories',null],
  [3,'PRO','Expand my earnings significantly',null],
  [3,'EXP','Travel to places that allow me to experience cultural and artistic experiences',null],
  [3,'CON','Dedicate time and resources in support of community actions',null],
  [3,'ACH','Follow a long-term vision aware that todays actions and decisions will affect the future',null],
  [3,'PUR','Live in constant search for a greater purpose in life',null],
  [3,'SPI','Understand the depth of different spiritual beliefs',null],
  [3,'FRE','Meet the new through people, travels, and venture into new experiences',null],
  [3,'JUS','Understand the point of view of others and treat people fairly and equally',null],
  [3,'HEA','Engage in activities that contribute to my physical and mental well-being',null],
  [3,'KNO','Connect different ideas and concepts',null],
  [3,'PRO','Maximize my time and leverage financial resources',null],
  [3,'EXP','Stay in harmony with nature and the environment',null],
  [3,'CON','Help charitable institutions in aid to the most needy',null],
  [3,'ACH','Be recognized for the impact you caused and for your great achievements',null],
  [3,'PUR','Connect with people, causes, and activities that have a greater purpose',null],
  [3,'SPI','Lifestyle with fewer material excesses and more spiritual alignment',null],
  [3,'FRE','Be able to make my own decisions and choices without external interference',null],
  [3,'JUS','Have a strong respect for the law and order, cannot stand injustices and discrimination',null],
  [3,'HEA','Take care of my physical and mental health seeking professional help when needed',null],
  [3,'KNO','Expand my mind with new learnings',null],
  [3,'PRO','Achieve as many resources as possible now for the future',null],
  [3,'EXP','Enjoy beautiful works of art and pleasant environments',null],
  [3,'CON','Contribute to the lives of needy people',null],
  [3,'ACH','Leave your legacy to the world and be remembered for your achievements',null],
  [3,'PUR','Understand my relationship with the world and live my purpose and sense of life',null],
  [3,'SPI','Prioritize the things I consider most important for my spiritual life',null],
  [3,'FRE','To be able to live my life the way I want, without being limited by others',null],
  [3,'JUS','Mobilize others in pursuit of a common goal that is considered fair',null],
  [3,'HEA','Practice physical and relaxing activities, sleep well and have a regular sleep routine',null],
  [3,'KNO','Learn new concepts and apply them',null],
  [3,'PRO','Look for ways to make money on my own',null],
  [3,'EXP','Have a healthy and natural diet with organic and fresh foods',null],
  [3,'CON','Participate as a volunteer in social support groups',null],
  [3,'ACH','Activities that have a greater purpose, lead people towards major objectives',null],
  [3,'PUR','Preference for carrying out activities that have a greater purpose',null],
  [3,'SPI','Search for something bigger, a connection with the universe, a feeling of peace and fulfillment',null],
  [3,'FRE','Explore and experience new things, travel to places Ive never been before',null],
  [3,'JUS','Practice truth and transparency, always acting with honesty and integrity',null],
  [3,'HEA','Have regular medical check-ups and seek preventive disease treatment',null],
  [3,'KNO','Share my knowledge with others',null],
  [3,'PRO','Use my time efficiently and productively',null],
  [3,'EXP','Connect to nature and experience the beauty of the arts',null],
  [3,'CON','Serve those most in need contributing to a better society',null],
  [3,'ACH','Be an inspiring leader who seeks to leave a positive legacy in all areas of life',null],
  [3,'PUR','Feel that every day I can make a difference and contribute to something bigger than myself',null],
  [3,'SPI','Introspection to connect with the divine, find inner peace and mental clarity',null],
  [3,'FRE','Learn about new cultures from different parts of the world and follow my passions and interests',null],
  [3,'JUS','Sense of social responsibility, contributing to a fairer society',null],
  [3,'HEA','Have a healthy diet, with natural, whole and nutritious foods',null],
  [3,'KNO','Search for information relevant to my growth',null],
  [3,'PRO','Search for financial independence and productive time',null],
  [3,'EXP','Seek to live each experience as if it were unique',null],
  [3,'CON','Seek to address the pain of others and social problems',null],
  [3,'ACH','Search for achievements, recognition and leave a positive legacy',null],
  [3,'PUR','Seek to fulfill a greater purpose in life',null],
  [3,'SPI','Seek to follow spiritual teachings and practices in all areas of my life',null],
  [3,'FRE','Seek independence, autonomy and everything that allows me to be free and authentic',null],
  [3,'JUS','Seek to do what is just, correct and integral in everyday life',null],
  [3,'HEA','Search for therapies and practices that promote physical and mental health',null],
  [2,'RF','DETERMINED','Resilient person who tends to be goal-oriented and very focused on achieving tangible results.'],
  [2,'RB','PERSUASIVE','Convincing person, with a natural ability to persuade and influence others.'],
  [2,'LB','PATIENT','Person who can endure difficult or stressful situations calmly and without getting irritated or agitated.'],
  [2,'LF','ORGANIZED','Person who can maintain order and structure in their actions, keeping everything in its place and time.'],
  [2,'RF','CONTROLLING','Person who tends to take control of situations and once they take the lead might not give space to others.'],
  [2,'RB','IMPULSIVE','Impulsive person to make decisions quickly, without considering all available options.'],
  [2,'LB','SLOW','Person who has a more tranquil and less accelerated pace of life. May take more time to make decisions, complete tasks, or finish projects.'],
  [2,'LF','PESSIMISTIC','A person who is extremely realistic and can be highly critical of themselves and others.'],
  [2,'RF','ASSERTIVE','A person who takes a stand and tends to express themselves in a firm and clear manner, without hesitation.'],
  [2,'RB','SOCIABLE','A person who feels comfortable in social situations, such as events or parties, and is always open to meeting and socializing with new people.'],
  [2,'LB','CALM','A person who can maintain calm even in stressful or challenging situations. Able to control their reactions serenely.'],
  [2,'LF','ANALYTICAL','A person with strong critical thinking, able to identify problems, make connections, and devise solutions based on facts and data.'],
  [2,'RF','EXECUTOR','A person action-oriented and inclined to take immediate measures. Typically seeks quick and tangible results.'],
  [2,'RB','CREATIVE','A person who has many ideas and likes to be innovative, thinks outside the box, and finds original solutions to problems.'],
  [2,'LB','CONSERVATIVE','A person who values safety and being grounded in their decisions, usually opposes sudden changes.'],
  [2,'LF','RATIONAL','A person with a methodical and systematic approach to investigating and understanding ideas, problems, and situations.'],
  [2,'RF','DECISIVE','A person who makes decisions quickly and gets straight to the point. Does not hesitate to act to achieve their goals.'],
  [2,'RB','OPTIMISTIC','A person who tends to see the positive side of things and can also be visionary, bold, and encouraging.'],
  [2,'LB','COOPERATIVE','A person who values and practices collaboration, mutual aid, and teamwork. Seeks solutions that benefit everyone.'],
  [2,'LF','DETAIL-ORIENTED','A person who pays close attention to details in all aspects of their life. Focuses on each individual element of a task or project.'],
  [2,'RF','AGILE','A person with a strong sense of urgency and tends to want things done quickly and on their schedule.'],
  [2,'RB','ENTHUSIASTIC','A person with a positive and enthusiastic attitude towards life. Tends to always be motivated and inspired by new opportunities.'],
  [2,'LB','UNDERSTANDING','A person who is tolerant and able to listen attentively and with interest, showing genuine concern and interest in the difficulties of others.'],
  [2,'LF','LOGICAL','A person who values reason and critical thinking in their decision-making. Always coherent in their analysis and evaluation.'],
  [2,'RF','CONFIDENT','A person who has confidence in themselves and their abilities. Not easily intimidated and can handle challenging situations without difficulty.'],
  [2,'RB','EXPRESSIVE','A person motivated by interaction and a strong desire to connect with others. Enjoys attending social events, parties, and gatherings.'],
  [2,'LB','CONSISTENT','A person who is able to act politely and friendly, always striving to treat people with respect and consideration.'],
  [2,'LF','FORMAL','A person who is usually polite and conservative in their language and behavior, someone who follows conventions and social rules.'],
  [2,'RF','ENERGETIC','An active person who likes to feel productive and tends to be firm in their opinions and beliefs.'],
  [2,'RB','FLEXIBLE','A collaborative and diplomatic person in their behavior, who usually seeks opinions and advice from others before any decision.'],
  [2,'LB','EMPATHETIC','A person who tends to be concerned about the well-being of others and seeks peaceful solutions to problems, putting themselves in anothers shoes.'],
  [2,'LF','INTROVERTED','A reserved person in their communication and behavior, preferring deeper and more meaningful conversations over superficial and informal chats.'],
  [2,'RF','FOCUSED','A person focused on results and usually with a pragmatic approach to solving problems and achieving goals.'],
  [2,'RB','COMMUNICATIVE','An extroverted person who likes to talk to others and feels comfortable in social situations.'],
  [2,'LB','PLANNER','A person able to think strategically about all the necessary steps to fulfill their responsibilities and consistently achieve their goals.'],
  [2,'LF','CAUTIOUS','A person who carefully evaluates available information before any decision, always trying to foresee the consequences of their actions.'],
  [2,'RF','COMPETITIVE','A highly competitive person who strives to excel in any activity to outperform others and come out on top.'],
  [2,'RB','EXTROVERTED','A person who feels energized in social interactions. Almost always cheerful, lively, communicative, and expressive.'],
[2,'LB','DIPLOMATIC','A person who avoids conflicts and can deal with differences of opinion constructively, seeking solutions that satisfy all parties involved.'],
[2,'LF','RESERVED','A discreet person who tends to share little of themselves. Their opinions and feelings are limited only to people they consider trustworthy.'],
[1,'RF','DETERMINED','A resilient person who tends to be oriented towards bold goals and is also very focused on achieving concrete results.'],
[1,'RB','PERSUASIVE','A convincing person, with a natural ability to persuade and influence others.'],
[1,'LB','PATIENT','A person who can calmly and serenely endure difficult or stressful situations without getting irritated or agitated.'],
[1,'LF','ORGANIZED','A person who is able to maintain order and structure in their actions, keeping everything in its place and time.'],
[1,'RF','CONTROLLING','A person who tends to take control of situations and, once leading, may not leave room for others.'],
[1,'RB','IMPULSIVE','An impulsive person who makes decisions quickly, without considering all available options.'],
[1,'LB','SLOW','A person who has a more relaxed and less accelerated pace of life. May take longer to make decisions, complete tasks, or finish projects.'],
[1,'LF','PESSIMISTIC','An extremely realistic person who can be highly critical of themselves and others.'],
[1,'RF','ASSERTIVE','A person who takes a stand and tends to express themselves firmly and clearly, without hesitation.'],
[1,'RB','SOCIABLE','A person who feels comfortable in social situations, such as events or parties, and is always open to meeting and socializing with new people.'],
[1,'LB','CALM','A person who can maintain calm even in stressful or challenging situations. Able to control their reactions serenely.'],
[1,'LF','ANALYTICAL','A person with strong critical thinking who can identify problems, make connections, and come up with solutions based on facts and data.'],
[1,'RF','DOER','A person oriented towards action and immediate measures. Typically seeks quick and tangible results.'],
[1,'RB','CREATIVE','A person with many ideas who likes to innovate, think outside the box, and find original solutions to problems.'],
[1,'LB','CONSERVATIVE','A person who values safety and groundedness in their decisions, usually opposing abrupt changes.'],
[1,'LF','RATIONAL','A person who takes a methodical and systematic approach to investigating and understanding ideas, problems, and situations.'],
[1,'RF','DECISIVE','A person who makes decisions quickly and gets to the point. Does not hesitate to act to achieve their goals.'],
[1,'RB','OPTIMISTIC','A person who tends to see the positive side of things and can also be visionary, bold, and encouraging.'],
[1,'LB','COOPERATIVE','A person who values and practices collaboration, mutual help, and teamwork. Seeks solutions that benefit everyone.'],
[1,'LF','DETAIL-ORIENTED','A person who pays close attention to details in all aspects of their life. Focuses on each individual element of a task or project.'],
[1,'RF','AGILE','A person with a strong sense of urgency who wants things done quickly and on their terms.'],
[1,'RB','ENTHUSIASTIC','A person with a positive and enthusiastic attitude towards life. Tends to always be motivated and inspired by new opportunities.'],
[1,'LB','UNDERSTANDING','A person who is tolerant and can listen attentively and with interest, showing genuine concern and interest in others difficulties.'],
[1,'LF','LOGICAL','A person who values reason and critical thinking in their decision-making. Always coherent in their analysis and evaluation.'],
[1,'RF','CONFIDENT','A person who has confidence in themselves and their abilities. They are not easily intimidated and can handle challenging situations without difficulty.'],
[1,'RB','EXPRESSIVE','A person motivated by interaction and a strong desire to connect with others. Enjoys attending social events, parties, and meetings.'],
[1,'LB','CONSISTENT','A person who can act in a polite and friendly manner, always striving to treat people with respect and consideration.'],
[1,'LF','FORMAL','A person who is generally polite and conservative in their language and behavior, one who follows social conventions and rules.'],
[1,'RF','ENERGETIC','An active person who likes to feel productive and tends to have strong opinions and convictions.'],
[1,'RB','FLEXIBLE','A collaborative and politic person in their behavior, who usually seeks opinions and advice from others before any decision.'],
[1,'LB','EMPATHETIC','A person who tends to care about the well-being of others and seeks peaceful solutions to problems by putting themselves in anothers place.'],
[1,'LF','INTROVERTED','A reserved person in communication and behavior, preferring deeper and more meaningful conversations over superficial and informal chats.'],
[1,'RF','FOCUSED','A person focused on results and usually with a pragmatic approach to problem-solving and goal achievement.'],
[1,'RB','COMMUNICATIVE','An extroverted person who enjoys talking to others and feels at ease in social situations.'],
[1,'LB','PLANNER','A person able to think strategically about all necessary steps to fulfill their responsibilities and achieve their goals consistently.'],
[1,'LF','CAUTIOUS','A person who carefully evaluates available information before any decision, always trying to foresee the consequences of their actions.'],
[1,'RF','COMPETITIVE','A highly competitive person who strives to excel in any activity to surpass others and come out on top.'],
[1,'RB','EXTROVERTED','A person who feels energized in social interactions. Almost always cheerful, lively, communicative, and expressive.'],
[1,'LB','DIPLOMATIC','A person who avoids conflicts and can handle differences of opinion constructively, seeking solutions that satisfy all parties involved.'],
[1,'LF','RESERVED','A discreet person who tends to share little of themselves. Their opinions and feelings are limited only to people they consider trustworthy.'],
]);
