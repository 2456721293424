import React from 'react'
import { Link } from 'react-router-dom'

const NotFound: React.FC = () => {
  return (
    <div className="w-screen h-screen flex flex-col items-center justify-center gap-4">
      <div className="overflow-y-hidden h-24 lg:h-28 flex items-center justify-center">
        <img
          className="w-96 lg:w-[30rem]"
          src={'/assets/LogoIPersonality.svg'}
          alt="logo"
        />
      </div>
      <h1 className="text-8xl font-bold text-red">404</h1>
      <span className="text-lg font-bold m-5">NOT FOUND</span>

      <Link to={'/home'} className="font-semibold py-2 text-dark">
        Voltar
      </Link>

      <span>© {new Date().getFullYear()} iPersonality</span>
    </div>
  )
}

export { NotFound }
