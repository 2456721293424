import React, { useCallback, useEffect, useState } from 'react'

import { StarFour, HandCoins } from '@phosphor-icons/react'
import api from '../services/api'
import { NumericFormat } from 'react-number-format'
import { toast } from 'react-toastify'
import { useAuth } from '../components/hooks/provider/auth'
// import { ImageModal } from '../components/ImageModal'

export interface ISellDTO {
  id: string
  name: string
  count: number
  sumValue: number
}

export interface IFinancialMovementDTO {
  id: string
  value: number
  transaction: 'INCOME' | 'OUTCOME'
  transaction_type: 'DIRECT' | 'INDIRECT' | 'IPERSONALITY' | 'WITHDRAW'
  withdraw_status: 'REFUSED' | 'PENDING' | 'COMPLETED'
  plan?: {
    name: string
  }
  user_money_destination?: {
    name: string
    lastname: string
  }
}

export interface IAdminDashboard {
  totalBalance: {
    value: number
    total_amount: number
  }
  totalQuantity: number
  allWithdraw: IFinancialMovementDTO[]
}

const Financial: React.FC = () => {
  const [directSells, setDirectSells] = useState([])
  const [indirectSells, setIndirectSells] = useState([])
  const [totalAvaiable, setTotalAvaiable] = useState(0)
  const [extractLoading, setExtractLoading] = useState(false)
  const [extract, setExtract] = useState([])
  const [adminDashboard, setAdminDashboard] = useState<IAdminDashboard>(
    {} as IAdminDashboard,
  )

  const [withdrawValue, setWithdrawValue] = useState<number | undefined>(
    undefined,
  )

  const [fileExport, setFileExport] = useState<[]>([])
  // console.log('🚀fileExport:', fileExport)
  // const plan = JSON.parse(
  //   String(localStorage.getItem('@ipersonality:plan')),
  // ) as Plan

  const { plan } = useAuth()

  // const [isOpenModal, setIsOpenModal] = useState(true)
  // const setIsOpenModalController = () => setIsOpenModal((state) => !state)

  const loadData = useCallback(async () => {
    const { data: direct } = await api.get('/v1/users/balance-plan', {
      params: { transaction_type: 'DIRECT' },
    })
    setDirectSells(direct)

    const { data: indirect } = await api.get('/v1/users/balance-plan', {
      params: { transaction_type: 'INDIRECT' },
    })
    setIndirectSells(indirect)

    const { data: totalAvaiable } = await api.get('/v1/users/balance')
    setTotalAvaiable(totalAvaiable.userTotal)

    const { data: extract } = await api.get('/v1/users/extract')
    setExtract(extract)
  }, [])

  const loadAdminData = useCallback(async () => {
    const { data: dashboard } = await api.get('/v1/financial/dashboard/balance')
    setAdminDashboard(dashboard)
    // console.log(dashboard)
  }, [])

  useEffect(() => {
    // console.log('PLAN', plan)
    if (plan.name === 'ADMIN') {
      loadAdminData().catch((err) => {
        console.log('Error fetching', err)
        // const { data, status } = err.response
        // toast.error(`${status} - ${data}`)
      })
    } else {
      loadData().catch((err) => {
        console.log('Error fetching', err)
        // const { data, status } = err.response
        // toast.error(`${status} - ${data}`)
      })
    }
  }, [loadData])

  const handleWithdraw = useCallback(async () => {
    // console.log('withdrawValue', withdrawValue)
    if (withdrawValue) {
      const centsWithdrawValue = withdrawValue * 100
      if (totalAvaiable >= centsWithdrawValue) {
        try {
          await api.post('/v1/users/withdraw', {
            withdraw_value: withdrawValue * 100,
          })
          toast.success('Saque efetuado com sucesso')
          loadData()
          setWithdrawValue(0)
        } catch (error) {
          toast.error(
            `Erro na comunicação com servidor, tente novamente mais tarde`,
          )
        }
      } else {
        toast.error(`Você não possui esse valor para efetuar o saque`)
      }
    } else {
      toast.error(`Digite um valor para efetuar o saque`)
    }
  }, [withdrawValue, totalAvaiable, loadData])

  const handleTranslate = (
    value: 'DIRECT' | 'INDIRECT' | 'IPERSONALITY' | 'WITHDRAW',
  ) => {
    switch (value) {
      case 'DIRECT':
        return 'Venda Direta'
      case 'INDIRECT':
        return 'Venda Indireta'
      case 'WITHDRAW':
        return 'Saque'
      case 'IPERSONALITY':
        return 'Adicionado a conta principal'
      default:
        break
    }
  }

  const handleWithdrawStatus = (value: 'REFUSED' | 'PENDING' | 'COMPLETED') => {
    switch (value) {
      case 'COMPLETED':
        return <span className="text-Green font-bold">APROVADO</span>
      case 'PENDING':
        return <span className="text-yellow font-bold">PENDENTE</span>
      case 'REFUSED':
        return <span className="text-red font-bold">REJEITADO</span>
    }
  }

  const handleUpdateWithdraw = async (
    financial_id: string,
    status: 'REFUSED' | 'PENDING' | 'COMPLETED',
  ) => {
    try {
      const { data } = await api.post('/v1/financial/update', {
        id: financial_id,
        withdraw_status: status,
      })
      console.log('DATA', data)
      loadAdminData()
    } catch (error) {
      console.log('ERROR', error)
      toast.error('Erro ao atualizar status')
    }
  }

  // created by xarlys 21/11/23
  const selectTableExports = async () => {
    const response = await api.get('/v1/export/list')
    // console.log("xarlys", response.data)
    setFileExport(response.data)
  }

  useEffect(() => {
    selectTableExports()
  }, [])

  const handleExportClickCSV = async (file: any) => {
    try {
      // const response = await api.get('/v1/export/all-data', {
      //   responseType: 'blob',
      // });
      // const response = await api.get('/v1/export/all-data')

      // if (response.status === 200) {
        // Crie um link para download para cada arquivo
        // const files = response.data // Supondo que a resposta é um array de nomes de arquivos
        // console.log("🚀files:", files)
        // files.forEach(async () => {
          setExtractLoading(true)
          const fileResponse = await api.get(`/v1/export/unique-data/${file}`, {
            responseType: 'blob',
          })

          // Crie um link para download para cada arquivo
          const url = window.URL.createObjectURL(new Blob([fileResponse.data]))
          const a = document.createElement('a')
          a.href = url
          a.download = `${file}.csv` // Use o nome do arquivo fornecido pelo backend

          // Adicione o link ao documento e clique nele para iniciar o download
          document.body.appendChild(a)
          a.click()
          document.body.removeChild(a)
          setExtractLoading(false)
      //   })
      // } else {
      //   console.error('Erro ao exportar dados:', response.statusText)
      // }
    } catch (error) {
      setExtractLoading(false)
      console.error('Erro ao exportar dados:', error)
      toast.error(`Erro ao exportar dados ${error}`)
    }
  }
  //   // console.log('response onload', response);
  //   // console.log('file', file)

  //   const url = window.URL.createObjectURL(new Blob([response.data]));
  //   // console.log("url", url)
  //   const a = document.createElement('a');
  //   a.href = url;
  //   a.download = `${file}.csv`;

  //   document.body.appendChild(a);
  //   a.click();
  //   document.body.removeChild(a);

  // } catch (error) {
  //   console.error('Erro ao exportar dados:', error);
  //   toast.error(`Erro ao exportar dados ${error}`);
  // }
  // };

  // created by xarlys 21/11/23
  const handleExportClickExcel = async () => {
    try {
      setExtractLoading(true)
      const response = await api.get('/v1/export/all-data-excel', {
        responseType: 'blob',
      })

      const url = window.URL.createObjectURL(new Blob([response.data]))
      const a = document.createElement('a')
      a.href = url
      a.download = 'exported-data.xlsx'

      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)
      setExtractLoading(false)
    } catch (error) {
      setExtractLoading(false)
      console.error('Erro ao exportar dados:', error)
      toast.error(`Erro ao exportar dados ${error}`)
    }
  }

  const SVGLoading = () => {
    return (
      <svg
        aria-hidden="true"
        className="inline w-6 h-6 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
        viewBox="0 0 100 101"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
          fill="currentColor"
        />
        <path
          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
          fill="black"
        />
      </svg>
    )
  }

  return (
    <>
      {/* <ImageModal
        open={isOpenModal}
        image={'/assets/ModalImage.svg'}
        setIsOpenController={setIsOpenModalController}
        onclick={setIsOpenModalController}
        title="Atualização registrada"
        button={true}
      /> */}
      <div className="w-full h-full flex flex-col p-4 lg:p-7 bg-lighterGray gap-4 overflow-hidden overflow-y-auto">
        <div className="w-full bg-white p-3 rounded-lg border-2 border-borderColor flex items-center justify-between">
          <span className="font-semibold text-base">Talentos</span>
        </div>
        <div className="flex gap-4">
          <div className="flex flex-col w-full gap-4">
            <div className="w-full h-20 flex flex-col bg-brightGreen text-black rounded-lg p-3 gap-2">
              {plan.name !== 'ADMIN' ? (
                <>
                  <span className="flex items-center font-semibold text-2xl gap-2">
                    T
                    <StarFour size={20} color="#000" weight="fill" />
                    {new Intl.NumberFormat('pt-BR', {
                      minimumFractionDigits: 2,
                    }).format(totalAvaiable / 100)}
                  </span>
                  <span className="text-xs">Total de talentos</span>
                </>
              ) : (
                <>
                  <span className="flex items-center font-semibold text-2xl gap-2">
                    {adminDashboard.totalQuantity}
                  </span>
                  <span className="text-xs">Vendas</span>
                </>
              )}
            </div>
            {plan.name !== 'ADMIN' && (
              <div className="w-full bg-white p-2 gap-2 rounded-lg border-2 border-borderColor flex flex-col items-start justify-between">
                <span className="font-semibold text-base">
                  Talentos direct share - descritivo
                </span>
                <div className="w-full h-fit min-w-max text-left flex flex-col gap-0.5">
                  <table>
                    <thead>
                      <tr className="text-mediumGray">
                        <td className="px-5 py-1">Título</td>
                        <td>Talentos</td>
                        <td>Valor</td>
                      </tr>
                    </thead>
                    <tbody>
                      {directSells.map((sell: ISellDTO) => {
                        return (
                          <tr
                            key={sell.id}
                            className="bg-lightGray mb-4 w-full text-sm"
                          >
                            <td className="px-5 py-1">{sell.name}</td>
                            <td>{sell.count}</td>
                            <td>
                              {new Intl.NumberFormat('pt-BR', {
                                style: 'currency',
                                currency: 'BRL',
                              }).format(sell.sumValue / 100)}
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            )}
          </div>
          <div className="flex flex-col w-full gap-4">
            <div className="w-full h-20 flex flex-col bg-yellow text-black rounded-lg p-3 gap-2">
              <span className="flex items-center font-semibold text-2xl gap-2">
                {new Intl.NumberFormat('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                }).format(
                  plan.name === 'ADMIN'
                    ? adminDashboard.totalBalance?.value / 100
                    : totalAvaiable / 100,
                )}
              </span>
              <span className="text-xs">
                {plan.name === 'ADMIN' ? 'Valor Líquido' : 'Global Share'}
              </span>
            </div>
            {plan.name !== 'ADMIN' && (
              <div className="w-full bg-white p-2 gap-2 rounded-lg border-2 border-borderColor flex flex-col items-start justify-between">
                <span className="font-semibold text-base">Global share</span>

                <div className="w-full h-fit min-w-max text-left flex flex-col gap-0.5">
                  <table>
                    <thead>
                      <tr className="text-mediumGray">
                        <td className="px-5 py-1">Título</td>
                        <td>Talentos</td>
                        <td>Valor</td>
                      </tr>
                    </thead>
                    <tbody>
                      {indirectSells.map((sell: ISellDTO) => {
                        return (
                          <tr
                            key={sell.id}
                            className="bg-lightGray mb-4 w-full text-sm"
                          >
                            <td className="px-5 py-1">{sell.name}</td>
                            <td>{sell.count}</td>
                            <td>
                              {new Intl.NumberFormat('pt-BR', {
                                style: 'currency',
                                currency: 'BRL',
                              }).format(sell.sumValue / 100)}
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            )}
          </div>
          {plan.name === 'ADMIN' && (
            <div className="flex flex-col w-full gap-4">
              <div className="w-full h-20 flex flex-col bg-lighterBlue text-black rounded-lg p-3 gap-2">
                <span className="flex items-center font-semibold text-2xl gap-2">
                  {new Intl.NumberFormat('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                  }).format(adminDashboard.totalBalance?.total_amount / 100)}
                </span>
                <span className="text-xs">Valor Bruto</span>
              </div>
            </div>
          )}
        </div>
        {plan.name !== 'ADMIN' ? (
          <>
            <div className="w-full bg-white p-2 rounded-lg border-2 border-borderColor flex flex-col items-start gap-4 justify-between">
              <span className="font-semibold text-base">Extrato</span>
              <table className="mb-4 w-full">
                <thead>
                  <tr className="text-mediumGray">
                    <td className="px-5 py-1">Produto</td>
                    <td>Tipo de transação</td>
                    <td>Transação</td>
                    <td>Valor</td>
                  </tr>
                </thead>
                <tbody>
                  {extract.map((extractItem: IFinancialMovementDTO) => {
                    return (
                      <tr
                        key={extractItem.id}
                        className="bg-lightGray mb-4 w-full text-sm border-b-2 border-white"
                      >
                        <td className="px-5 py-1">
                          {extractItem.plan
                            ? extractItem.plan.name
                            : 'Retirada do usuário'}
                        </td>
                        <td>{handleTranslate(extractItem.transaction_type)}</td>
                        <td>
                          {extractItem.transaction === 'INCOME'
                            ? 'ENTRADA'
                            : handleWithdrawStatus(extractItem.withdraw_status)}
                        </td>
                        <td
                          className={
                            (extractItem.transaction === 'INCOME'
                              ? 'text-Green'
                              : 'text-red') + ' font-semibold'
                          }
                        >
                          {new Intl.NumberFormat('pt-BR', {
                            style: 'currency',
                            currency: 'BRL',
                          }).format(extractItem.value / 100)}
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
            <div className="flex gap-2">
              {/* <div className="flex flex-col">
          <span className="text-mediumGray flex items-center justify-between text-sm mb-1">
            Pagar
          </span>
          <button className="flex items-center gap-3 bg-darkRed text-white text-sm rounded-md py-0.5 px-3">
            <Handbag size={18} color="#fff" />
            Clique aqui
          </button>
        </div> */}
              <div className="flex flex-col">
                <span className="text-mediumGray flex items-center justify-between text-sm mb-1">
                  Sacar
                </span>
                <NumericFormat
                  className="p-2 bg-white rounded text-sm outline-0"
                  placeholder="R$ 0,00"
                  thousandSeparator="."
                  decimalSeparator=","
                  prefix="R$ "
                  decimalScale={2}
                  value={withdrawValue}
                  // onValueChange={(value) => setWithdrawValue(value.floatValue)}
                  onValueChange={(values) => {
                    console.log('VALUES', values)
                    setWithdrawValue(Number(values.floatValue))
                  }}
                />
                <button
                  className="flex items-center gap-3 bg-darkBlue text-white text-sm rounded-md py-0.5 px-3"
                  onClick={handleWithdraw}
                >
                  <HandCoins size={18} color="#fff" />
                  Clique aqui
                </button>
              </div>
            </div>
          </>
        ) : (
          <div className="w-full bg-white p-2 rounded-lg border-2 border-borderColor flex flex-col items-start gap-4 justify-between">
            <span className="font-semibold text-base">Saques solicitados</span>
            <table className="mb-4 w-full">
              <thead>
                <tr className="text-mediumGray">
                  <td className="px-5 py-1">Usuário</td>
                  <td>Valor</td>
                  <td>Status</td>
                  <td>Atualizar</td>
                </tr>
              </thead>
              <tbody>
                {adminDashboard.allWithdraw?.map(
                  (extractItem: IFinancialMovementDTO) => {
                    return (
                      <tr
                        key={extractItem.id}
                        className="bg-lightGray mb-4 w-full text-sm border-b-2 border-white"
                      >
                        <td className="px-5 py-1">
                          {extractItem.user_money_destination?.name}{' '}
                          {extractItem.user_money_destination?.lastname}
                        </td>
                        <td className=" font-semibold">
                          {new Intl.NumberFormat('pt-BR', {
                            style: 'currency',
                            currency: 'BRL',
                          }).format(0 - extractItem.value / 100)}
                        </td>
                        <td>
                          {handleWithdrawStatus(extractItem.withdraw_status)}
                        </td>
                        <td>
                          <div className="flex gap-2">
                            <button
                              onClick={() =>
                                handleUpdateWithdraw(
                                  extractItem.id,
                                  'COMPLETED',
                                )
                              }
                              disabled={
                                extractItem.withdraw_status === 'COMPLETED'
                              }
                              className="px-2 py-1 text-white bg-Green rounded-md font-bold disabled:bg-gray disabled:cursor-not-allowed"
                            >
                              APROVAR
                            </button>
                            <button
                              onClick={() =>
                                handleUpdateWithdraw(extractItem.id, 'PENDING')
                              }
                              disabled={
                                extractItem.withdraw_status === 'PENDING'
                              }
                              className="px-2 py-1 text-white bg-yellow rounded-md font-bold disabled:bg-gray disabled:cursor-not-allowed"
                            >
                              PENDENTE
                            </button>
                            <button
                              onClick={() =>
                                handleUpdateWithdraw(extractItem.id, 'REFUSED')
                              }
                              disabled={
                                extractItem.withdraw_status === 'REFUSED'
                              }
                              className="px-2 py-1 text-white bg-red rounded-md font-bold disabled:bg-gray disabled:cursor-not-allowed"
                            >
                              REJEITAR
                            </button>
                          </div>
                        </td>
                      </tr>
                    )
                  },
                )}
              </tbody>
            </table>
          </div>
        )}

          {
            plan.name === 'ADMIN' ?
            <div className="flex col-auto gap-2">
              {/* <button className='bg-lightBlue hover:bg-lightBlueHover p-2 rounded-md text-white font-semibold' onClick={handleExportClickCSV}>Exportar Dados CSV</button> */}
              {extractLoading === false ?
                <>
                  <select
                    name="plan"
                    id="plan"
                    defaultValue={''}
                    className="w-2/2 p-2 bg-white border border-borderColor rounded text-sm outline-0"
                    // onChange={(e) => changeValue(e.target.value)}
                    onChange={(e) => handleExportClickCSV(e.target.value)}
                  >
                    <option value="" disabled>
                      Exportar Dados CSV
                    </option>
                    {fileExport.map((item: string, key) => {
                      if (item !== null) {
                        return (
                          <option value={item} key={key}>
                            {item}
                          </option>
                        )
                      }
                    })}
                  </select>
                  <button
                    className="bg-lightBlue hover:bg-lightBlueHover p-2 rounded-md text-white font-semibold"
                    onClick={handleExportClickExcel}
                  >
                    Exportar Dados Excel
                  </button>
                </>
                : <div className="flex items-center gap-2">
                  {SVGLoading()} Realizando a extração de dados...
                </div>
              }
            </div>
            : <p>not access export</p>
          }
      </div>
    </>
  )
}

export { Financial }
