import React from 'react'
import { zodResolver } from '@hookform/resolvers/zod'
import { SubmitHandler, useForm } from 'react-hook-form'
import api from '../services/api'
import { z } from 'zod'
import { toast } from 'react-toastify'
import { Link } from 'react-router-dom'
import { Translator } from '../components/I18n/Translator'
import packageJson from '../../package.json'

const ForgotPasswordSchema = z.object({
  email: z.string().email('This is not a valid email').min(10, {
    message: 'This email does not have the minimum required characters',
  }),
})

type ForgotPasswordSchemaType = z.infer<typeof ForgotPasswordSchema>

const ForgotPassword: React.FC = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<ForgotPasswordSchemaType>({
    resolver: zodResolver(ForgotPasswordSchema),
  })

  const handleForgotPassword: SubmitHandler<ForgotPasswordSchemaType> = async ({
    email,
  }) => {
    try {
      const response = await api.post('/v1/forgot-password', {
        email,
      })
      if (response.status === 204) {
        toast.success(<Translator path="forgotPassword.success" />)
        reset()
      }
    } catch (error: any) {
      console.log(error)
      const { data, status } = error.response
      toast.error(`${status} - ${data}`)
    }
  }

  return (
    <div className="w-screen h-screen p-4 lg:p-0 flex flex-col items-center justify-center gap-7">
      <div className="overflow-y-hidden h-24 lg:h-28 flex items-center justify-center">
        <img
          className="w-96 lg:w-[30rem]"
          src={'/assets/LogoIPersonality.svg'}
          alt="logo"
        />
      </div>
      <form
        onSubmit={handleSubmit(handleForgotPassword)}
        className="w-full lg:w-1/4 flex flex-col items-center gap-2"
      >
        <span className="text-lg font-bold m-4">
          <Translator path="forgotPassword.title" />
        </span>
        <div className="flex flex-col flex-1 w-full">
          <span>
            <Translator path="forgotPassword.email" />
          </span>
          <input
            className="h-11 my-1 px-3 border border-gray rounded-md focus:outline-none"
            placeholder="Enter your email address"
            type="text"
            id="email"
            {...register('email', { required: true })}
          />
          {errors.email ? (
            <span className="text-red text-xs">{errors.email.message}</span>
          ) : (
            <span className="text-white text-xs">E-mail</span>
          )}
        </div>

        <button className="bg-lightBlue font-semibold px-15 py-2 rounded-md text-white hover:bg-lightBlueHover w-full">
          <Translator path="forgotPassword.btnRecover" />
        </button>
      </form>
      <span className="flex flex-col items-center lg:flex-row gap-2">
        <Translator path="forgotPassword.questionRemember" />
        <Link to={'/'} className="text-red font-bold cursor-pointer">
          <Translator path="forgotPassword.return" />
        </Link>
      </span>
      <div className="flex gap-2 items-center">
        <span>© {new Date().getFullYear()} IPersonality</span>
        <span className="text-sm">V: {packageJson.version}</span>
      </div>
    </div>
  )
}

export { ForgotPassword }
