import React, { useCallback, useEffect, useState } from 'react'

import { PageContainer } from '../components/PageContainer'
import { YoutubeFrame } from '../components/YoutubeFrame'

import api from '../services/api'
import { useAuth } from '../components/hooks/provider/auth'
import { toast } from 'react-toastify'
import { Translator } from '../components/I18n/Translator'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

export interface IPlanDTO {
  id: string
  name:
    | 'FREE'
    | 'TECH_EMPRETENEUR'
    | 'PEOPLE_MASTERY'
    | 'SELF_MASTERY'
    | 'COGNITIVE_BEHAVIORAL_CODE'
    | 'ADMIN'
    | 'SPIRITUAL_INTELLIGENCE'
    | 'HEADHUNTER'
    | 'MULTIPLE_INTELLIGENCES'
    | 'PERSONAL_STRATEGIC_PLANNING'
  description: string
  name_pt:
    | 'GRATIS'
    | 'TEC_EMPREENDEDOR'
    | 'DOMINIO_DE_PESSOAS'
    | 'AUTO_MESTRE'
    | 'CODIGO_COGNITIVO_COMPORTAMENTAL'
    | 'INTELIGENCIA_ESPIRITUAL'
    | 'ADMINISTRADOR'
    | 'HEADHUNTER'
    | 'INTELIGENCIA_MULTIPLAS'
    | 'PLANEJAMENTO_ESTRATEGICO_PESSOAL'
  description_pt: string
  name_es:
    | 'GRATIS'
    | 'TEC_EMPRENDEDOR'
    | 'INTELIGENCIA_ESPIRITUAL'
    | 'AUTO_MAESTRO'
    | 'CODIGO_COGNITIVO_CONDUCTA'
    | 'DOMINIO_DE_LAS_PERSONAS'
    | 'ADMINISTRACION'
    | 'HEADHUNTER'
    | 'INTELIGENCIA_MULTIPLE'
    | 'PLANIFICACION_ESTRATEGICA_PERSONAL'
  description_es: string
  active?: boolean
  price: number
  price_br: number
  price_stripe_id?: string
  scitech_info?: string
}

const Development: React.FC = () => {
  const [products, setProducts] = useState<IPlanDTO[]>([] as IPlanDTO[])
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [error, setError] = useState<string | JSX.Element | null>(null)
  const { user, plan } = useAuth()
  const { i18n } = useTranslation()
  const getStripeCheckout = useCallback(async (plan_id: string) => {
    const { data } = await api.post('/v1/stripe/createPaymentSession', {
      plan_id,
    })
    window.location.replace(data)
  }, [])

  useEffect(() => {
    const ignore = false
    setIsLoading(true)
    const fetchData = async () => {
      try {
        const { data } = await api.get('/v1/plans/find-all')

        if (!ignore) {
          if (data.length === 0 || data === undefined || !data) {
            // toast.error('Nenhuma comunidade encontrada.')
            setError(<Translator path="developYourself.noPlan" />)
          } else {
            setError(null)
          }
          setProducts(data.plans)
        }
      } catch {
        toast.error(<Translator path="developYourself.requestError" />)
        // console.error('Erro ao buscar comunidades:', error)
        setError(<Translator path="developYourself.requestError" />)
      } finally {
        setIsLoading(false)
      }
    }

    fetchData()
    setIsLoading(false)
  }, [])

  const switchLanguageName = (product: IPlanDTO) => {
    switch (i18n.language) {
      case 'pt-BR':
        return product.name_pt.replaceAll('_', ' ')
      case 'en-US':
        return product.name.replaceAll('_', ' ')
      case 'es-ES':
        return product.name_es.replaceAll('_', ' ')
    }
  }

  const switchLanguageDescription = (product: IPlanDTO) => {
    switch (i18n.language) {
      case 'pt-BR':
        return product.description_pt
      case 'en-US':
        return product.description
      case 'es-ES':
        return product.description_es
    }
  }
  const switchBgColor = (product: IPlanDTO) => {
    switch (product.name) {
      case 'TECH_EMPRETENEUR':
        return (
          <div
            className={`w-fit bg-[#FFD98E] text-darkGray rounded-xl font-semibold px-8 text-sm py-1 flex items-center`}
          >
            {switchLanguageName(product)}
          </div>
        )
      case 'PEOPLE_MASTERY':
        return (
          <div
            className={`w-fit bg-[#FFD98E] text-darkGray rounded-xl font-semibold px-8 text-sm py-1 flex items-center`}
          >
            {switchLanguageName(product)}
          </div>
        )
      case 'SELF_MASTERY':
        return (
          <div
            className={`w-fit bg-[#48E2FC] text-darkGray rounded-xl font-semibold px-8 text-sm py-1 flex items-center`}
          >
            {switchLanguageName(product)}
          </div>
        )
      case 'COGNITIVE_BEHAVIORAL_CODE':
        return (
          <div
            className={`w-fit bg-[#FFB2BD] text-darkGray rounded-xl font-semibold px-8 text-sm py-1 flex items-center`}
          >
            {switchLanguageName(product)}
          </div>
        )
      case 'SPIRITUAL_INTELLIGENCE':
        return (
          <div
            className={`w-fit bg-[#04BAD6] text-darkGray rounded-xl font-semibold px-8 text-sm py-1 flex items-center`}
          >
            {switchLanguageName(product)}
          </div>
        )
      case 'MULTIPLE_INTELLIGENCES':
        return (
          <div
            className={`w-fit bg-[#FFB2BD] text-darkGray rounded-xl font-semibold px-8 text-sm py-1 flex items-center`}
          >
            {switchLanguageName(product)}
          </div>
        )
      case 'HEADHUNTER':
        return (
          <div
            className={`w-fit bg-[#48E2FC] text-darkGray rounded-xl font-semibold px-8 text-sm py-1 flex items-center`}
          >
            {switchLanguageName(product)}
          </div>
        )
      case 'PERSONAL_STRATEGIC_PLANNING':
        return (
          <div
            className={`w-fit bg-[#04BAD6] text-darkGray rounded-xl font-semibold px-8 text-sm py-1 flex items-center`}
          >
            {switchLanguageName(product)}
          </div>
        )
    }
  }

  return (
    <PageContainer className="flex-col gap-4">
      <div className="w-full h-fit bg-white p-3 rounded-lg border-2 border-borderColor">
        <span className="font-semibold text-base">
          <Translator path="developYourself.title" />
        </span>
      </div>
      {error && (
        <div className="border border-red-400 rounded-b bg-[#ffd1d1] px-4 py-3 text-red">
          {error}
        </div>
      )}
      {isLoading ? (
        <span>
          <Translator path="community.loading" />
        </span>
      ) : (
        products.map((product: IPlanDTO, index) =>
          product.name !== 'FREE' &&
          product.name !== 'ADMIN' ? (
            index % 2 !== 0 ? (
              <div
                key={product.id}
                className="flex gap-10 bg-white rounded-lg border border-borderColor p-4 transition-shadow hover:shadow-md active:shadow-inner"
                // onClick={() => getStripeCheckout(product.id)}
              >
                <img
                  className="h-32"
                  src={`/assets/${product.name.toLocaleLowerCase()}-${i18n.language.toLocaleLowerCase()}.png`}
                  alt=""
                />
                <div className="flex flex-col w-full gap-3">
                  {switchBgColor(product)}
                  <span className="text-xs whitespace-pre-wrap">
                    {switchLanguageDescription(product)}
                  </span>
                  <span className="text-blueGreen self-end mt-2 font-extrabold text-2xl">
                    {new Intl.NumberFormat(
                      i18n.language === 'pt-BR' ? 'pt-BR' : 'en-US',
                      {
                        style: 'currency',
                        currency: i18n.language === 'pt-BR' ? 'BRL' : 'USD',
                      },
                    ).format(
                      i18n.language === 'pt-BR'
                        ? product.price_br
                        : product.price,
                    )}
                  </span>
                  {/* <div className="flex gap-4 mt-3">
                      
                      <span className=" text-darkGray rounded-xl text-base font-semibold px-2">
                        {product.scitech_info}
                      </span>
                    </div> */}
                  <div className="flex gap-3 self-end">
                    {/* <button className='flex gap-2 bg-orange rounded-full px-8 py-1 items-center'>
                        <img className='w-5 h-5' src="/assets/pix.svg" alt="" />
                        Pagar no pix
                      </button> */}
                      {
                        product.name !== String(plan.name) ?
                        // <button
                        //   onClick={() => getStripeCheckout(product.id)}
                        //   className="flex gap-2 bg-lightGreen rounded-full px-8 py-1 items-center"
                        // >
                        //   <img className="w-5 h-5" src="/assets/card.svg" alt="" />
                        //   <Translator path="developYourself.purchase" />
                        // </button>
                        <Link
                            to={`${import.meta.env.VITE_BASE_URL_STRIPE}${product.price_stripe_id}?client_reference_id=${user ? user.id : null}`}
                            target='_blank'
                            className="flex gap-2 bg-lightGreen rounded-full px-8 py-1 items-center"
                          >
                            <img className="w-5 h-5" src="/assets/card.svg" alt="" />
                            <Translator path="developYourself.purchase" />
                          </Link>
                        :
                        <span className="flex gap-2 bg-lightGreen rounded-full px-8 py-1 items-center cursor-not-allowed"><Translator path="developYourself.acquired" /></span>
                      }
                  </div>
                </div>
              </div>
            ) : (
              <div
                key={product.id}
                className="flex gap-10 bg-white rounded-lg border border-borderColor p-4 transition-shadow hover:shadow-md active:shadow-inner"
                // onClick={() => getStripeCheckout(product.id)}
              >
                <div className="flex w-full flex-col gap-3">
                  {switchBgColor(product)}
                  <span className="text-xs whitespace-pre-wrap">
                    {switchLanguageDescription(product)}
                  </span>
                  <span className="text-blueGreen mt-2 font-extrabold text-2xl">
                    {new Intl.NumberFormat(
                      i18n.language === 'pt-BR' ? 'pt-BR' : 'en-US',
                      {
                        style: 'currency',
                        currency: i18n.language === 'pt-BR' ? 'BRL' : 'USD',
                      },
                    ).format(
                      i18n.language === 'pt-BR'
                        ? product.price_br
                        : product.price,
                    )}
                  </span>
                  {/* <div className="flex gap-4 mt-3">
                      
                      <span className=" text-darkGray rounded-xl text-base font-semibold px-2">
                        {product.scitech_info}
                      </span>
                    </div> */}
                  <div className="flex gap-3">
                    {/* <button className='flex gap-2 bg-orange rounded-full px-8 py-1 items-center'>
                        <img className='w-5 h-5' src="/assets/pix.svg" alt="" />
                        Pagar no pix
                      </button> */}
                      {
                        product.name !== String(plan.name) ?
                          // <button
                          //   onClick={() => getStripeCheckout(product.id)}
                          //   className="flex gap-2 bg-lightGreen rounded-full px-8 py-1 items-center"
                          // >
                          //   <img className="w-5 h-5" src="/assets/card.svg" alt="" />
                          //   <Translator path="developYourself.purchase" />
                          // </button>
                          <Link
                            to={`${import.meta.env.VITE_BASE_URL_STRIPE}${product.price_stripe_id}?client_reference_id=${user ? user.id : null}`}
                            target='_blank'
                            className="flex gap-2 bg-lightGreen rounded-full px-8 py-1 items-center"
                          >
                            <img className="w-5 h-5" src="/assets/card.svg" alt="" />
                            <Translator path="developYourself.purchase" />
                          </Link>
                        :
                        <span className="flex gap-2 bg-darkBlue text-white rounded-full px-8 py-1 items-center cursor-not-allowed">
                          <Translator path="developYourself.acquired" />
                        </span>
                      }
                  </div>
                </div>
                <img
                  className="h-32 justify-self-end"
                  src={`/assets/${product.name.toLocaleLowerCase()}-${i18n.language.toLocaleLowerCase()}.png`}
                  alt=""
                />
              </div>
            )
          ) : null,
        )
      )}
    </PageContainer>
  )
}

export { Development }

{
  /* <div className="w-full flex flex-col lg:flex-row gap-4">
        <div className="w-full">
          <YoutubeFrame
            title={<Translator path="developYourself.videoTitle" />}
            height="250"
            link="https://www.youtube.com/"
          ></YoutubeFrame>
        </div>
        <div className="flex flex-col gap-2 w-full">
          {error && (
            <div className="border border-red-400 rounded-b bg-[#ffd1d1] px-4 py-3 text-red">
              {error}
            </div>
          )}
          {isLoading ? (
              <span><Translator path="community.loading" /></span>
          ) : (
            products.map((product: IPlanDTO) =>
              product.name !== 'FREE' &&
              product.name !== 'ADMIN' &&
              product.name !== String(plan.name) ? (
                <div
                  key={product.id}
                  className="flex flex-col bg-white rounded-lg border border-borderColor p-4 cursor-pointer transition-shadow hover:shadow-md active:shadow-inner"
                  onClick={() => getStripeCheckout(product.id)}
                >
                  <span className="text-xs whitespace-pre-wrap">
                    {switchLanguageDescription(product)}
                  </span>
                  <span className="text-blueGreen mt-2 font-extrabold text-baser">
                    {new Intl.NumberFormat('pt-BR', {
                      style: 'currency',
                      currency: 'BRL',
                    }).format(product.price / 100)}
                  </span>
                  <div className="flex gap-4 mt-3">
                    <div className="bg-orange text-darkGray rounded-xl  text-xs font-semibold px-3 flex items-center">
                      {switchLanguageName(product)}
                    </div>
                    <span className=" text-darkGray rounded-xl text-base font-semibold px-2">
                      {product.scitech_info}
                    </span>
                  </div>
                </div>
              ) : null,
            )
          )}
        </div>
      </div> */
}
