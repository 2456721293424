import React, { useEffect, useState } from 'react'
import Chart from 'react-apexcharts'
import { YoutubeFrame } from '../components/YoutubeFrame'

import { ChartLineUp, Users, CurrencyCircleDollar } from '@phosphor-icons/react'
import { IResponseCardsDTO, IResponseDashDTO } from '../services/homeDTO'
import api from '../services/api'
import { toast } from 'react-toastify'
import { InviteCard } from '../components/InviteCard'
import { ApexOptions } from 'apexcharts'
import { Translator } from '../components/I18n/Translator'

export type Plan = {
  id: string
  active: boolean
  name: string
  price: number
}

const Home: React.FC = () => {
  // código refatorado
  const [cards, setCards] = useState<IResponseCardsDTO>({} as IResponseCardsDTO)
  const [graphics, setGraphics] = useState<IResponseDashDTO>(
    {} as IResponseDashDTO,
  )
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [error, setError] = useState<string | JSX.Element | null>(null)

  const plan = JSON.parse(
    String(localStorage.getItem('@ipersonality:plan')),
  ) as Plan
  // const parsePlano = plano ? JSON.parse(plano) : null;

  const fetchData = async (
    endpoint: string,
    stateSetter: React.Dispatch<React.SetStateAction<any | null>>,
    errorMsg: string,
  ) => {
    setIsLoading(true)
    try {
      const { data } = await api.get(endpoint)
      // console.log(`Dados recebidos de ${endpoint}:`, data);
      if (data || data.length > 0) {
        stateSetter(data)
      } else {
        const msg = <Translator path="home.msg" />
        toast.error(msg)
        setError(msg)
      }
    } catch (error) {
      const msg = <Translator path="home.requestError" />
      toast.error(msg)
      console.error(msg, error)
      setError(msg)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    let ignore = false
    // console.log("parsePlano.name", parsePlano.name)
    if (plan.name === 'ADMIN') {
      fetchData('/v1/dashboard/cards', setCards, 'encontrado')
      fetchData('/v1/dashboard/graphics', setGraphics, 'dados para o gráfico')
    }

    return () => {
      ignore = true
    }
  }, [])

  // conversoes e armazenamento dos dados convertidos
  const {
    percent_user_free,
    percent_user_premium,
    count_free,
    count_premium,
    count_assessments_complete,
    count_assessments_not_complete,
  } = graphics

  const convertValue = (value: number | undefined) => {
    return value !== 0 && value !== undefined ? value : 0
  }

  const convertedValues = {
    free: Number(percent_user_free).toFixed(2),
    premium: Number(percent_user_premium).toFixed(2),
    totalComplete: convertValue(graphics.total_assessments_complete_percent),
    totalNotComplete: convertValue(
      graphics.total_assessments_not_complete_percent,
    ),
  }

  const donutChartOptions: ApexOptions = {
    dataLabels: {
      enabled: true,
    },
    tooltip: {
      enabled: true,
    },
    series: [Number(count_free), Number(count_premium)],
    colors: ['#F94144', '#F9C74F'],
    stroke: {
      colors: ['#fff'],
    },
    chart: {
      type: 'donut',
      height: 300,
    },
    labels: ['Paid users', 'Free users'],
    legend: {
      position: 'bottom',
    },
  }

  const pieChartOptions: ApexOptions = {
    dataLabels: {
      enabled: true,
    },
    tooltip: {
      enabled: true,
    },
    series: [
      Number(count_assessments_complete),
      Number(count_assessments_not_complete),
    ],
    colors: ['#5CB25F', '#006598'],
    chart: {
      type: 'pie',
    },
    labels: ['Did the test', "Didn't take the test"],
    legend: {
      show: true,
      position: 'bottom',
    },
  }

  return (
    <div className="w-full h-full flex flex-col p-4 lg:p-7 bg-lighterGray gap-4 overflow-auto">
      {plan && plan.name === 'ADMIN' ? (
        <>
          <div className="w-full bg-white p-3 rounded-lg border-2 border-borderColor">
            <span className="font-semibold text-base">
              <Translator path="home.dashboard" />
            </span>
          </div>
          <div className="flex flex-col lg:flex-row w-full justify-between items-start gap-4">
            <div className="w-full lg:w-fit h-full flex flex-col">
              <div className="w-full lg:max-w-md flex bg-white border border-Blue rounded p-1.5 mb-4">
                <div className="mr-2">
                  <ChartLineUp className="text-textBlue" size={25} />
                </div>
                <div className="flex flex-col">
                  <div className="text-xl font-semibold text-textBlue">
                    {(Object.keys(cards).length !== 0
                      ? cards.total_assessments_primary
                      : '0.00') +
                      ' - ' +
                      (Object.keys(cards).length !== 0
                        ? (
                            Number(cards.percent_assessments_primary_answered) *
                            100
                          ).toPrecision(3)
                        : '0.00') +
                      '%'}
                  </div>
                  <span className="text-xs font-semibold">
                    <Translator path="home.cardOne" />
                  </span>
                </div>
              </div>
              <div className="w-full lg:max-w-sm flex bg-white border border-blueGreen rounded p-1.5 mb-4">
                <div className="mr-2">
                  <Users className="text-blueGreen" size={25} />
                </div>
                <div className="flex flex-col">
                  <div className="text-xl font-semibold text-blueGreen">
                    {(Object.keys(cards).length !== 0
                      ? cards.total_assessments_primary_invited
                      : '0.00') +
                      ' - ' +
                      (cards.percent_assessments_primary_answered_invited !==
                      undefined
                        ? (
                            Number(
                              cards.percent_assessments_primary_answered_invited,
                            ) * 100
                          ).toPrecision(3)
                        : '0.00') +
                      '%'}
                  </div>
                  <span className="text-xs font-semibold">
                    <Translator path="home.cardTwo" />
                  </span>
                </div>
              </div>
              <div className="w-full lg:max-w-sm flex bg-lightGreen border border-darkGreen rounded p-1.5 mb-4">
                <div className="mr-2">
                  <CurrencyCircleDollar
                    className="text-darkGreen"
                    size={25}
                    weight="fill"
                  />
                </div>
                <div className="flex flex-col">
                  <div className="text-xl font-semibold text-darkGreen">
                    {cards.dash_cards
                      ? new Intl.NumberFormat('pt-BR', {
                          style: 'currency',
                          currency: 'BRL',
                        }).format(
                          Number(cards.dash_cards.total_receivable) / 100,
                        )
                      : 'R$ 0,00'}
                  </div>
                  <span className="text-xs font-semibold ">
                    <Translator path="home.cardThree" />
                  </span>
                </div>
              </div>
              <div className="w-full lg:max-w-sm flex bg-white border border-darkGreen rounded p-1.5 ">
                <div className="mr-2">
                  <CurrencyCircleDollar
                    className="text-darkGreen"
                    size={25}
                    weight="fill"
                  />
                </div>
                <div className="flex flex-col">
                  <div className="text-xl font-semibold text-darkGreen">
                    {cards.dash_cards
                      ? new Intl.NumberFormat('pt-BR', {
                          style: 'currency',
                          currency: 'BRL',
                        }).format(
                          Number(cards.dash_cards.total_transaction_plataform) /
                            100,
                        )
                      : 'R$ 0,00'}
                  </div>
                  <span className="text-xs font-semibold ">
                    <Translator path="home.cardFour" />
                  </span>
                </div>
              </div>
            </div>

            <div className="w-full lg:w-1/2 max-w-md flex flex-col justify-around items-center h-full bg-white rounded p-2">
              <span className="text-sm">
                <Translator path="home.graphic" />
              </span>
              <Chart
                options={donutChartOptions}
                series={donutChartOptions.series}
                type="donut"
              />
            </div>
            <div className="w-full lg:w-1/2 max-w-md flex flex-col justify-around items-center  h-full bg-white rounded p-2">
              <span className="text-sm">
                <Translator path="home.graphic" />
              </span>
              <Chart
                options={pieChartOptions}
                series={pieChartOptions.series}
                type="pie"
              />
            </div>
          </div>
        </>
      ) : (
        <div className="w-full bg-white p-3 rounded-lg border-2 border-borderColor">
          <span className="font-semibold text-base">
            <Translator path="home.salutation" />
          </span>
        </div>
      )}

      <div className="flex flex-col lg:flex-row gap-4 w-full justify-between">
        <YoutubeFrame
          title={<Translator path="home.videoTitle" />}
          height="300"
          link="https://www.youtube.com/"
        />
        <InviteCard
          icon={true}
          title={<Translator path="home.inviteTitle" />}
        />
      </div>
    </div>
  )
}

export { Home }
// console.log("CARDS DADOS", Object.keys(cards).length === 0 )
// backup código antigo
// const [cards, setCards] = useState<IResponseCardsDTO>({} as IResponseCardsDTO)
//   const [graphics, setGraphics] = useState<IResponseDashDTO>(
//     {} as IResponseDashDTO,
//   )
//   const [isLoading, setIsLoading] = useState<boolean>(false)
//   const [error, setError] = useState<string | null>(null)
//   const { ref_code } = useAuth()

//   useEffect(() => {
//     let ignore = false
//     const fetchDataCards = async () => {
//       setIsLoading(true)
//       try {
//         const { data } = await api.get('/v1/dashboard/cards')
//         // console.log('data', data)
//         if (!ignore) {
//           if (!data || data.length === 0) {
//             const msg = 'Nenhum dado encontrado.'
//             toast.error(msg)
//             setError(msg)
//           }
//           setCards(data)
//         }
//       } catch (error) {
//         const msg =
//           'Houve um erro ao buscar as dados. Por favor, tente novamente mais tarde.'
//         toast.error(msg)
//         console.error(msg, error)
//         setError(msg)
//       } finally {
//         setIsLoading(false)
//       }
//     }

//     const fetchDataGraphs = async () => {
//       setIsLoading(true)
//       try {
//         const { data: graphics } = await api.get('/v1/dashboard/graphics')

//         if (!ignore) {
//           if (!graphics || graphics.length === 0) {
//             const msg = 'Nenhum dado para o gráfico encontrado.'
//             toast.error(msg)
//             setError(msg)
//           }
//           setGraphics(graphics)
//         }
//       } catch (error) {
//         const msg =
//           'Houve um erro ao buscar as dados. Por favor, tente novamente mais tarde.'
//         toast.error(msg)
//         console.error(msg, error)
//         setError(msg)
//       } finally {
//         setIsLoading(false)
//       }
//     }

//     fetchDataCards()
//     fetchDataGraphs()
//     return () => {
//       ignore = true
//     }
//   }, [])

// backup 2 parte do código
// const free_converted = Number(graphics.percent_user_free).toFixed(2)
//   // console.log("graphics.percent_user_free", graphics.percent_user_free)
//   // console.log("free_converted", free_converted)
//   const premium_converted = Number(graphics.percent_user_premium).toFixed(2)
//   const total_complete_converted =
//     graphics.total_assessments_complete_percent !== 0 &&
//     graphics.total_assessments_complete_percent !== undefined
//       ? graphics.total_assessments_complete_percent
//       : 0
//   const total_not_complete_converted =
//     graphics.total_assessments_not_complete_percent !== 0 &&
//     graphics.total_assessments_not_complete_percent !== undefined
//       ? graphics.total_assessments_not_complete_percent
//       : 0

//   const options: ApexOptions = {
//     dataLabels: {
//       enabled: true,
//     },
//     tooltip: {
//       enabled: true,
//     },
//     series: [Number(graphics.count_free), Number(graphics.count_premium)],
//     colors: ['#F94144', '#F9C74F'],
//     stroke: {
//       colors: ['#fff'],
//     },
//     chart: {
//       type: 'donut',
//       height: 300,
//     },
//     labels: ['Usuários pagos', 'Usuários gratuitos'],
//     legend: {
//       position: 'bottom',
//     },
//     // responsive: [
//     //   {
//     //     breakpoint: -1400,
//     //     options: {
//     //       chart: {
//     //         type: 'donut',
//     //       },
//     //       legend: {
//     //         position: 'right',
//     //       },
//     //     },
//     //   },
//     // ],
//   }
//   const secondOptions: ApexOptions = {
//     dataLabels: {
//       enabled: true,
//     },
//     tooltip: {
//       enabled: true,
//     },
//     series: [
//       Number(graphics.count_assessments_complete),
//       Number(graphics.count_assessments_not_complete),
//     ],
//     colors: ['#5CB25F', '#006598'],
//     chart: {
//       type: 'pie',
//     },
//     labels: ['Fez o teste', 'Não fez o teste'],

//     legend: {
//       show: true,
//       position: 'bottom',
//     },
//   }

//   const plano = localStorage.getItem('@ipersonality:plan')
//   const parsePlano = plano ? JSON.parse(plano) : null
//   // console.log("🚀 ~ file: Home.tsx:161 ~ parsePlano:", parsePlano)
//   const isNotFreePlan = plano ? parsePlano.name !== 'FREE' : true
