import React from 'react'
import { AuthProvider } from './auth'

type AppProps = {
  children?: JSX.Element // React.ReactNode | any; // 👈️ type children
}

// function AppProvider(props: AppProps) {
const AppProvider: React.FC<AppProps> = ({ children }) => {
  return <AuthProvider>{children}</AuthProvider>
}

export default AppProvider
