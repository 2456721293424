import React, { useCallback, useEffect, useState } from 'react'

import { PageContainer } from '../components/PageContainer'

import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
// import { ImageModal } from '../components/ImageModal'
import api from '../services/api'
import { toast } from 'react-toastify'

interface CarreerPathFormData {
  id: string
  percent_remuneration: number
  percent_shared_one_nine: number
  percent_shared_ten_twenty: number
  percent_shared_more_than_twenty: number
  direct_sell_comission: number
  indirect_sell_comission: number
  selling_limit: number
  plan: {
    name: string
  }
}

const CareerPath: React.FC = () => {
  const navigate = useNavigate()
  // const setIsOpenModalController = () => setIsOpenModal((state) => !state)

  // const [isOpenModal, setIsOpenModal] = useState(false)
  // const [isLoading, setIsLoading] = useState<boolean>(false)
  // const [error, setError] = useState<string | null>(null)
  const [carrers, setCarrers] = useState<CarreerPathFormData[]>([])
  const [id, setId] = useState<string | null>(null)

  const changeValue = useCallback(
    (id: string) => {
      setId(id)
      const returnedCarrers = carrers.find((carrer) => carrer.id === id)
      if (returnedCarrers) {
        setValue(
          'percent_remuneration',
          parseFloat((returnedCarrers.percent_remuneration * 100).toFixed(1)),
        )
        setValue(
          'percent_shared_one_nine',
          parseFloat(
            (returnedCarrers.percent_shared_one_nine * 100).toFixed(1),
          ),
        )
        setValue(
          'percent_shared_ten_twenty',
          parseFloat(
            (returnedCarrers.percent_shared_ten_twenty * 100).toFixed(1),
          ),
        )
        setValue(
          'percent_shared_more_than_twenty',
          parseFloat(
            (returnedCarrers.percent_shared_more_than_twenty * 100).toFixed(1),
          ),
        )
        setValue(
          'direct_sell_comission',
          parseFloat((returnedCarrers.direct_sell_comission * 100).toFixed(1)),
        )
        setValue(
          'indirect_sell_comission',
          parseFloat(
            (returnedCarrers.indirect_sell_comission * 100).toFixed(1),
          ),
        )
        setValue(
          'selling_limit',
          parseFloat(returnedCarrers.selling_limit.toFixed(1)),
        )
      }
    },
    [carrers],
  )

  useEffect(() => {
    const fetchData = async () => {
      const { data: returnedCarrers } = await api.get(
        `/v1/financial/career/find-all`,
      )
      setCarrers(returnedCarrers)
      setValue(
        'percent_remuneration',
        parseFloat((returnedCarrers[0].percent_remuneration * 100).toFixed(1)),
      )
      setValue(
        'percent_shared_one_nine',
        parseFloat(
          (returnedCarrers[0].percent_shared_one_nine * 100).toFixed(1),
        ),
      )
      setValue(
        'percent_shared_ten_twenty',
        parseFloat(
          (returnedCarrers[0].percent_shared_ten_twenty * 100).toFixed(1),
        ),
      )
      setValue(
        'percent_shared_more_than_twenty',
        parseFloat(
          (returnedCarrers[0].percent_shared_more_than_twenty * 100).toFixed(1),
        ),
      )
      setValue(
        'direct_sell_comission',
        parseFloat((returnedCarrers[0].direct_sell_comission * 100).toFixed(1)),
      )
      setValue(
        'indirect_sell_comission',
        parseFloat(
          (returnedCarrers[0].indirect_sell_comission * 100).toFixed(1),
        ),
      )
      setValue(
        'selling_limit',
        parseFloat(returnedCarrers[0].selling_limit.toFixed(1)),
      )
      // setId(id)

      // const { data } = await api.get(`/v1/financial/career/details/${id}`)
    }

    fetchData()
  }, [])

  const handleUpdate = async (data: CarreerPathFormData) => {
    const result = await api.put(`/v1/financial/career/update/${id}`, data)

    if (result.status === 200) {
      toast.success('Métricas atualizadas com sucesso')
      navigate(`/careerPath`)
      // setIsOpenModalController()
    }
  }

  const { register, handleSubmit, setValue } = useForm<CarreerPathFormData>()

  return (
    <PageContainer>
      {/* {isOpenModal ? (
        <div className="fixed inset-0 bg-black bg-opacity-75 brightness-50 transition-opacity"></div>
      ) : null}
      <ImageModal
        open={isOpenModal}
        image={ModalImage}
        setIsOpenController={setIsOpenModalController}
        onclick={setIsOpenModalController}
        title="Atualização registrada"
        button={true}
      ></ImageModal> */}
      <form onSubmit={handleSubmit(handleUpdate)}>
        <div className="w-96 h-fit p-3 flex flex-col bg-white rounded-lg ">
          <span className="text-sm font-semibold">
            Configuração de métricas
          </span>
          <span className="text-sm font-semibold mt-4 mb-2">
            Métrica de Direct Share (D.S.)
          </span>
          <div className="flex flex-col text-black w-full">
            <span className="text-sm leading-7">Escolha o plano</span>
            <select
              name="plan"
              id="plan"
              className="w-full p-2 bg-white border border-borderColor rounded text-sm outline-0"
              onChange={(e) => changeValue(e.target.value)}
            >
              {carrers.map((item) => (
                <option value={item.id} key={item.id}>
                  {item.plan.name}
                </option>
              ))}
            </select>
          </div>
          <div className="flex flex-col text-black w-full">
            <span className="text-sm leading-7">% de remuneração</span>
            <input
              className="w-full p-2 bg-white border border-borderColor rounded text-sm outline-0"
              type="number"
              id="percent_remuneration"
              placeholder="00,0%"
              step=".1"
              {...register('percent_remuneration', { required: true })}
            />
          </div>
          <div className="flex flex-col text-black w-full">
            <span className="text-sm leading-7">
              Compartilhando 1 a 9 produtos - comissão %
            </span>
            <input
              className="w-full p-2 bg-white border border-borderColor rounded text-sm outline-0"
              type="number"
              id="percent_shared_one_nine"
              placeholder="00,0%"
              step=".1"
              {...register('percent_shared_one_nine', { required: true })}
            />
          </div>
          <div className="flex flex-col text-black w-full">
            <span className="text-sm leading-7">
              Compartilhando 10 a 20 produtos - comissão %
            </span>
            <input
              className="w-full p-2 bg-white border border-borderColor rounded text-sm outline-0"
              type="number"
              id="percent_shared_ten_twenty"
              placeholder="00,0%"
              step=".1"
              {...register('percent_shared_ten_twenty', { required: true })}
            />
          </div>
          <div className="flex flex-col text-black w-full">
            <span className="text-sm leading-7">
              Compartilhando mais de 20 produtos - comissão %
            </span>
            <input
              className="w-full p-2 bg-white border border-borderColor rounded text-sm outline-0"
              type="number"
              id="percent_shared_more_than_twenty"
              placeholder="00,0%"
              step=".1"
              {...register('percent_shared_more_than_twenty', {
                required: true,
              })}
            />
          </div>
          <div className="flex flex-col text-black w-full">
            <span className="text-sm leading-7">Valor limite de comissão</span>
            <input
              className="w-full p-2 bg-white border border-borderColor rounded text-sm outline-0"
              type="number"
              id="selling_limit"
              placeholder="00,0%"
              step=".1"
              {...register('selling_limit', { required: true })}
            />
          </div>
          <button
            type="submit"
            className="text-white p-2 bg-textBlue w-1/4 self-end rounded text-xs mt-2"
          >
            Atualizar
          </button>
        </div>
      </form>
    </PageContainer>
  )
}

export { CareerPath }

/*
// useEffect(() => {
  //   const fetchData = async () => {
  //     setIsLoading(true);
  //     try {
  //       const { data } = await api.get('/v1/financial/career/update', {
  //         params: id,
  //       });
  //       if (data.length === 0) {
  //         toast.error('Nenhuma comunidade encontrada.');
  //         setError('Nenhuma comunidade encontrada.');
  //       }
  //       setDataCarrer(data);
  //     } catch (error) {
  //       toast.error('Houve um erro ao buscar as comunidades. Por favor, tente novamente mais tarde.');
  //       setError('Houve um erro ao buscar as comunidades. Por favor, tente novamente mais tarde.');
  //     } finally {
  //       setIsLoading(false);
  //     }
  //   };
d
  //   fetchData();
  // }, []); */
