import React from 'react'

interface Props {
  image?: React.ReactElement | string | any
  title: string
  description?: string
  price?: number
  juros?: string
  onclick?: () => void
  index?: number
}

const ProductCard: React.FC<Props> = ({
  image,
  title,
  description,
  price,
  juros,
  onclick,
  index,
}) => {
  return (
    <div className={`bg-white rounded-lg border-2 border-borderColor flex flex-col items-start p-2 w-full gap-1 ${index && index > 3 ? 'row-start-2' : null}`}>
      <img className="w-full self-center h-48 bg-black rounded-lg" src={image} alt="SelfKnowledge" />
      <span className="font-semibold">{title}</span>
      <span className="text-sm">{description}</span>
      <div className="flex flex-col text-darkBlueGreen text-base font-semibold">
        <span className='text-2xl'>
          {price
            ? new Intl.NumberFormat('pt-BR', {
                style: 'currency',
                currency: 'BRL',
              }).format(price)
            : 'R$ 0,00'}

          <span className="text-base font-normal"> à vista</span>
        </span>
        <span className="text-xs font-normal text-black">
          {' '}
          {'ou ' +
            juros +
            'x de R$ ' +
            (price! / parseFloat(juros!)).toFixed(2) +
            ' sem juros'}
          {/* ou 3x de R$ 174,11 sem juros */}
        </span>
      </div>

      <button
        onClick={onclick}
        className="w-full mt-auto gap-4 flex items-center justify-center self-center bg-darkBlueGreen text-sm text-white px-4 py-1 rounded-md"
      >
        Ver detalhes
      </button>
    </div>
  )
}

export { ProductCard }
