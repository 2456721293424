import React, { useEffect, useState } from 'react'

import { PageContainer } from '../components/PageContainer'
// import { ImageModal } from './../components/ImageModal'

import api from '../services/api'
import { useAuth } from '../components/hooks/provider/auth'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
// import { useNavigate } from 'react-router-dom'

import { Camera } from '@phosphor-icons/react'
import { useProfile } from '../components/hooks/queries/useProfile'
import { Translator } from '../components/I18n/Translator'

interface ProfileFormData {
  id: string
  name: string
  lastname: string
  email: string
  ref_by_user_id: string
  wiaard_step: string
  ref_code: string
  avatar: string
  phone: string
  gender: string
  plan_id: string
  nickname: string
  fileName: string
}

const Profile: React.FC = () => {
  // const [isUpdated, setIsUpdated] = useState(false)
  // const setIsUpdatedController = () => setIsUpdated((state) => !state)

  // const [isOpenModal, setIsOpenModal] = useState(false)
  // const setIsOpenModalController = () => setIsOpenModal((state) => !state)
  // const [userId, setUserId] = useState<string | undefined>('')
  // // const [refCode, setRefCode] = useState<string>('');
  // // const [apelido, setApelido] = useState<string>('');

  // const {
  //   register,
  //   // handleSubmit,
  //   // watch,
  //   // formState: { errors },
  //   setValue,
  // } = useForm<ProfileFormData>()

  // const { user } = useAuth()
  // useEffect(() => {
  //   const fetchData = async () => {
  //     console.log('DAIANE', user?.id)
  //     setUserId(user?.id)
  //     const { data } = await api.get(`/v1/profile/${userId}`)

  //     setValue('id', data.id)
  //     setValue('name', data.name)
  //     setValue('lastname', data.lastname)
  //     setValue('email', data.email)
  //     setValue('ref_by_user_id', data.ref_by_user_id)
  //     setValue('wiaard_step', data.wiaard_step)
  //     setValue('ref_code', data.ref_code)
  //     setValue('avatar', data.avatar)
  //     setValue('phone', data.phone)
  //     setValue('gender', data.gender)
  //     setValue('plan_id', data.plan_id)

  //     // setRefCode(data.ref_code)
  //     // setApelido(data: {name+las})
  //   }

  //   fetchData()
  // }, [userId])
  // const navigate = useNavigate()
  const [isUpdated, setIsUpdated] = useState(false)
  // const setIsUpdatedController = () => setIsUpdated((state) => !state)
  // const [isOpenModal, setIsOpenModal] = useState(false)
  // const setIsOpenModalController = () => setIsOpenModal((state) => !state)
  const { register, setValue, handleSubmit, reset } = useForm<ProfileFormData>()
  const { user, refreshUserData } = useAuth()
  // const [profileData, setProfileData] = useState<ProfileFormData | null>(null)
  const [localAvatar, setLocalAvatar] = useState<File>()
  const { data: profileData, refetch } = useProfile(String(user?.id))
  console.log('🚀 ~ file: Profile.tsx:84 ~ profileData:', profileData)

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       if (user?.id) {
  //         const { data } = await api.get(`/v1/profile/${user.id}`)
  //         setProfileData(data)
  //         console.log('Dados do perfil:', data)
  //       }
  //     } catch (error) {
  //       console.error('Erro ao buscar dados do perfil:', error)
  //     }
  //   }

  //   fetchData()
  // }, [user])

  useEffect(() => {
    if (profileData) {
      setValue('id', profileData.id)
      setValue('name', profileData.name)
      setValue('lastname', profileData.lastname)
      setValue('email', profileData.email)
      setValue('ref_by_user_id', profileData.ref_by_user_id)
      setValue('wiaard_step', profileData.wiaard_step)
      setValue('ref_code', profileData.ref_code)
      // setValue('avatar', profileData.avatar)
      setValue('phone', profileData.phone)
      // setValue('gender', profileData.gender)
      setValue('plan_id', profileData.plan_id)
      // console.log('AVATAR', user?.avatar)

      setValue('nickname', profileData.nickname)

      setValue('gender', profileData.gender)

      const fileName = `${profileData.name} ${`.png`}`
      setValue('fileName', fileName)
      console.log(
        'Dados do perfil definidos nos campos do formulário:',
        profileData,
      )
    }
  }, [profileData, setValue])

  const handleUpdate = async (data: ProfileFormData) => {
    try {
      const result = await api.put(`/v1/users/update/${user?.id}`, data)
      console.log('passou da rota')
      if (result.status === 200) {
        toast.success(<Translator path="profile.success" />)
        // navigate(`/profile`)
        refetch()
        setIsUpdated(false)
        console.log('Atualizado com sucesso')
      } else {
        // Lógica para lidar com outros cenários de resposta da API, se necessário.
        console.error('Erro ao atualizar perfil:', result.data)
      }
    } catch (error) {
      console.error('Erro ao atualizar perfil:', error)
    }
  }

  const handleUpdateAvatar = async (avatar: File) => {
    try {
      const form = new FormData()
      form.append('avatar', avatar)
      await api.post(`/v1/users/update-avatar`, form, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
      refreshUserData()
      toast.success(<Translator path="profile.avatar" />)
    } catch (error) {
      console.log('error', error)
      toast.error(<Translator path="profile.errorAvatar" />)
    }
  }

  return (
    <PageContainer>
      {/* <div className="flex items-center justify-center h-screen">
        {isOpenModal ? (
          <div className="fixed inset-0 bg-black bg-opacity-75 brightness-50 transition-opacity"></div>
        ) : null}

        <ImageModal
          open={isOpenModal}
          image={'/assets/ModalImage.svg'}
          setIsOpenController={setIsOpenModalController}
          onclick={setIsOpenModalController}
          title="Deseja realmente atualizar seus dados?"
          buttons={true}
        ></ImageModal>
      </div> */}
      <form
        onSubmit={handleSubmit(handleUpdate)}
        className="w-full h-fit lg:m-4 p-3 flex flex-col bg-white rounded-lg border-2 border-borderColor"
      >
        <span className="text-sm font-semibold">
          <Translator path="profile.title" />
        </span>
        <div className="w-full flex flex-col gap-2 lg:gap-0 lg:flex-row lg:items-end justify-between mt-2">
          <div className="relative">
            {user?.avatar || localAvatar ? (
              <img
                src={
                  localAvatar ? URL.createObjectURL(localAvatar) : user?.avatar
                }
                alt="profile"
                className="rounded-full w-20 h-20 object-cover"
              />
            ) : (
              <img src={'/assets/ProfileLogo.svg'} alt="profile" />
            )}
            <label
              htmlFor="changeImage"
              className="absolute left-12 bottom-0 bg-[#c4c4c4] cursor-pointer rounded-full p-2 hover:bg-gray"
            >
              <Camera size={22} className="text-textBlue" />
            </label>
          </div>
          <input
            type="file"
            className="hidden"
            id="changeImage"
            name="changeImage"
            onChange={(e) => {
              if (e.target.files) {
                setLocalAvatar(e.target.files[0])
                handleUpdateAvatar(e.target.files[0])
              }
            }}
          />
          <div className="flex flex-col text-textGray lg:w-1/2">
            <span className="text-sm leading-7">
              <Translator path="profile.typeAccess" />
            </span>
            <input
              className="p-2 bg-borderColor rounded text-sm outline-0"
              type="text"
              // name="plan_id"
              id="plan_id"
              disabled={true}
              placeholder="Usuário Free"
              {...register('plan_id', { required: true })}
            />
          </div>
        </div>
        <div className="w-full flex flex-col lg:flex-row items-end justify-between mt-2 gap-2">
          <div className="flex flex-col text-textGray w-full lg:w-1/2">
            <span className="text-sm leading-7">
              <Translator path="profile.name" />
            </span>
            <input
              className="p-2 bg-white border border-borderColor rounded text-sm outline-0"
              type="text"
              // name="name"
              id="name"
              disabled={!isUpdated}
              placeholder="Nome"
              {...register('name', { required: true })}
            />
          </div>
          <div className="flex flex-col text-textGray w-full lg:w-1/2">
            <span className="text-sm leading-7">
              <Translator path="profile.surname" />
            </span>
            <input
              className="p-2 bg-white border border-borderColor rounded text-sm outline-0"
              type="text"
              // name="lastname"
              id="lastname"
              disabled={!isUpdated}
              placeholder="Sobrenome"
              {...register('lastname', { required: true })}
            />
          </div>
        </div>
        <div className="w-full flex  items-end justify-end mt-2 gap-2">
          <div className="flex flex-col text-textGray w-full">
            <span className="text-sm leading-7">
              <Translator path="profile.nickname" />
            </span>
            <input
              className="p-2 bg-white border border-borderColor rounded text-sm outline-0"
              type="text"
              // name="name"
              id="nickname"
              disabled={!isUpdated}
              placeholder="Apelido"
              defaultValue={`${profileData?.name} ${profileData?.lastname}`}
              {...register('nickname', { required: true })}
            />
            <span className="text-xs font-medium px-2">
              <Translator path="profile.informationNickname" />
            </span>
          </div>
        </div>
        <div className="flex flex-col text-textGray w-full">
          <span className="text-sm leading-7">
            <Translator path="profile.gender" />
          </span>
          <div className="flex gap-4 lg:gap-2 ">
            <div className="lg:w-1/6 flex items-center p-2 pl-4 lg:pl-2 space-x-3 border border-gray rounded cursor-pointer">
              <input
                id="male"
                type="radio"
                value="male"
                required
                {...register('gender', { required: true })}
              />
              <label
                htmlFor="male"
                className="w-full text-md cursor-pointer pr-4 lg:pr-0"
              >
                <Translator path="profile.genderMen" />
              </label>
            </div>

            <div className="lg:w-1/6 flex items-center p-2 pl-4 lg:pl-2 space-x-3 border border-gray rounded cursor-pointer">
              <input
                id="female"
                type="radio"
                value="female"
                required
                {...register('gender', { required: true })}
              />
              <label
                htmlFor="female"
                className="w-full text-md cursor-pointer pr-4 lg:pr-0"
              >
                <Translator path="profile.genderWoman" />
              </label>
            </div>
          </div>
        </div>

        {/* <div className="w-full flex  items-center justify-between mt-2 gap-2">
          <div className="flex flex-col text-textGray w-full">
            <span className="text-sm leading-7">Atualizar foto de perfil</span>

            <div
              className="flex items-center justify-between px-4 py-2 bg-inputGray rounded text-sm
            outline-0 text-black"
            >
              <span>nomedoarquivo.png</span>

              <input
                className="hidden"
                type="file"
                // name="name"
                id="avatar"
                placeholder="nome do arquivo"
                {...register('avatar', { required: true })}
              />
              <button className="bg-darkGray text-white px-3 py-1 rounded text-xs">
                Alterar
              </button>
            </div>
          </div>
        </div> */}
        <div className="w-full flex  items-center justify-between mt-2 gap-2">
          <div className="flex flex-col text-textGray w-full">
            <span className="text-sm leading-7">
              <Translator path="profile.email" />
            </span>

            <input
              className="disabled:opacity-50 disabled:cursor-not-allowed p-2 bg-white border border-borderColor rounded text-sm outline-0"
              type="email"
              id="email"
              placeholder="e-mail"
              disabled={true}
              {...register('email', { required: true })}
            />
          </div>
        </div>
        {/* <div className="flex flex-col mt-2">
          <span>Sexo</span>
          <div className="flex gap-4">
            <div className="flex border border-borderColor rounded w-1/5 items-center justify-between px-4 py-2 text-sm text-mediumGray">
              <input
                className="bg-white w-5"
                type="radio"
                // name="female"
                id="gender"
                {...register('gender', { required: true })}
              />
              <span>Feminino</span>
            </div>
            <div className="flex border border-borderColor rounded w-1/5 items-center justify-between px-4 py-2 text-sm text-mediumGray">
              <input
                className="bg-white w-5"
                type="radio"
                // name="male"
                id="gender"
                {...register('gender', { required: true })}
              />
              <span>Masculino</span>
            </div>
          </div>
        </div> */}
        <div className="flex flex-col text-textGray w-full mt-2">
          <span className="text-sm leading-7">
            <Translator path="profile.code" />
          </span>
          <div className="flex">
            <span className="flex items-center text-xs px-2 bg-inputGray text-black rounded-l-lg">
              {`${import.meta.env.VITE_DOMAIN}/invite/`}
            </span>
            <input
              className="p-2 bg-white border border-borderColor rounded-r-lg text-sm outline-0"
              type="text"
              disabled={true}
              id="ref_code"
              placeholder="Código"
              {...register('ref_code', { required: true })}
            />
          </div>
          <span className="text-xs text-textGray mt-1">
            <Translator path="profile.theCode" />
            <strong>
              {profileData ? profileData.ref_code : 'não possui código'}{' '}
            </strong>{' '}
            <Translator path="profile.informationCode" />
          </span>
        </div>
        {isUpdated ? (
          <div className="flex gap-2 justify-end items-center mt-2">
            <button
              onClick={(e) => {
                setIsUpdated(false)
                reset({
                  name: profileData?.name,
                  lastname: profileData?.lastname,
                  nickname: profileData?.nickname,
                  gender: profileData?.gender,
                })
                e.preventDefault()
              }}
              className="text-white p-2 bg-red w-1/4 self-end rounded text-xs"
            >
              <Translator path="profile.buttonCancel" />
            </button>
            <button
              type="submit"
              // onClick={setIsOpenModalController}
              className="text-white p-2 bg-lightGreen w-1/4 self-end rounded text-xs"
            >
              <Translator path="profile.buttonSave" />
            </button>
          </div>
        ) : (
          <button
            // type="submit"
            onClick={(e) => {
              setIsUpdated(true)
              e.preventDefault()
              // setIsUpdatedController()
              // handleSubmit(handleUpdate)()
            }}
            className="text-white p-2 bg-textBlue w-1/4 self-end rounded text-xs mt-2"
          >
            <Translator path="profile.buttonUpdate" />
          </button>
        )}
        {/* <button
          type="submit"
          // onClick={}
            className="text-white p-2 bg-textBlue w-1/4 self-end rounded text-xs">Opa</button> */}
      </form>
    </PageContainer>
  )
}

export { Profile }
