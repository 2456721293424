import { ArrowLeft, ArrowRight } from '@phosphor-icons/react'
import React from 'react'
import { Translator } from '../../components/I18n/Translator'
import { Trans } from 'react-i18next'

interface NextStageProps {
  stage: number
  next: () => void
  back: () => void
}

const NextStage: React.FC<NextStageProps> = ({ stage, next, back }) => {
  return (
    <div className="flex flex-col h-fit pb-8 gap-3 lg:gap-6 bg-white rounded-lg items-center">
      <p className="text-center max-w-2xl">
        {stage === 2 ? (
          <Translator path="selfKnowledge.nextStage.first.firstText" />
        ) : (
          <Translator path="selfKnowledge.nextStage.second.firstText" />
        )}
      </p>
      {stage === 2 ? (
        <p className="text-center max-w-2xl">
          <Trans
            i18nKey="selfKnowledge.nextStage.first.secondText"
            components={{ b: <b /> }}
          />
        </p>
      ) : (
        <p className="text-center max-w-2xl">
          <Translator path="selfKnowledge.nextStage.second.secondText" />
        </p>
      )}
      <hr />
      <div className="flex w-full gap-2">
        <button
          className="w-1/3 py-3 flex items-center justify-center gap-2 lg:gap-4 bg-white text-red rounded-lg border-red border-2 disabled:opacity-50"
          onClick={back}
        >
          <ArrowLeft size={25} color="red" />{' '}
          <Translator path="selfKnowledge.nextStage.btnBack" />
        </button>
        <button
          className="w-2/3 py-3 flex items-center justify-center gap-2 lg:gap-4 bg-[#0369A1] text-white rounded-lg disabled:opacity-50"
          onClick={next}
          // disabled={selected.length !== 4}
        >
          <Translator path="selfKnowledge.nextStage.btnStart" />{' '}
          <ArrowRight size={25} color="#FFFFFF" />
        </button>
      </div>
    </div>
  )
}

export { NextStage }
