import React from 'react'

interface FrameProps {
  title: string | React.ReactNode
  link?: string
  height?: string
}

const YoutubeFrame: React.FC<FrameProps> = ({ title, height }) => {
  return (
    <div className="w-full max-w-xl flex flex-col bg-white rounded-lg border-2 border-borderColor p-2 gap-2">
      <span className="text-sm font-semibold ">{title}</span>
      <video
        className={'w-full h-44 mx-auto lg:w-[500px] lg:h-[275px]'}
        controls
        autoPlay={false}
      >
        <source
          src={
            'https://ipersonality.app/assets/deibson-entrada-plataforma-ipersonality.mp4'
          }
          type="video/mp4"
        />
      </video>
    </div>
  )
}

export { YoutubeFrame }
