// componente refatorado
import React, { useEffect, useState } from 'react'
import { Eye, UserCircle } from '@phosphor-icons/react'
import api from '../services/api'
import { toast } from 'react-toastify'
import { Translator } from './I18n/Translator'
import '../styles/scrollbar.css'
import { useAuth } from './hooks/provider/auth'

interface ListCommunityProps {
  modal?: React.Dispatch<React.SetStateAction<boolean>>
  type: 'community' | 'community_modal'
  userSelectedId?: string | null
  setUserSelectedId?: (userSelectedId: string) => void
  filter?: string
  search?: string
  userSelectedData: object
  setUserSelectedData: (userSelectedData: object) => void
}

interface ICommunitiesDTO {
  id?: string
  name?: string
  description?: string
  active?: boolean
  user_id?: string
  created_at?: Date
  updated_at?: Date
  user_in_community: {
    id: string
    name: string
    email: string
    avatar?: string
    Plan: {
      id: string
      name: string
    }
    Assessment: [
      {
        id: string
        status: string
        report_status: string
      },
    ]
  }
}

interface ITableBodyDTO {
  type: string
  community: ICommunitiesDTO
  setUserSelectedId: any
  modal: any
  setUserSelectedData: (userSelectedData: object) => void
}

const TableHeader: React.FC<{ type: string }> = ({ type }) => {
  switch (type) {
    case 'community':
      return (
        <>
          <th className="pb-2 text-base pl-2">
            <Translator path="community.name" />
          </th>
          <th className="hidden lg:table-cell pb-2 text-base lg:w-30">
            <Translator path="community.userPlan" />
          </th>
          <th className="hidden lg:table-cell pb-2 text-base lg:w-28">
            <Translator path="community.testStatus" />
          </th>
          <th className="pb-2 text-base lg:w-24 mr-0">
            <Translator path="community.seeData" />
          </th>
        </>
      )
    case 'community_modal':
      return (
        <>
          <th className="pb-2 text-sm text-left">Avatar</th>
          <th className="pb-2 text-sm text-left">
            <Translator path="community.name" />
          </th>
          <th className="pb-2 text-sm text-left">
            <Translator path="community.userPlan" />
          </th>
          <th className="pb-2 text-sm text-left">
            <Translator path="community.yourCommunity" />
          </th>
        </>
      )
    default:
      return null
  }
}

const TableBody: React.FC<ITableBodyDTO> = ({
  type,
  community,
  setUserSelectedId,
  modal,
  setUserSelectedData,
}) => {
  const { user } = useAuth()
  // console.log("community.user_in_community", community.user_in_community)
  switch (type) {
    case 'community':
      return (
        <>
          <td className="pl-2 ">
            <div className="flex gap-2 items-center">
              <UserCircle size={32} className="self-center" />
              <div className="flex flex-col">
                {community.user_in_community.name}
                {import.meta.env.VITE_PARTNER_IDS?.split(',').includes(
                  user?.id,
                ) && <span>{community.user_in_community.email}</span>}
              </div>
            </div>
          </td>

          <td className="hidden lg:table-cell truncate text-center ">
            {community.user_in_community.Plan &&
            community.user_in_community.Plan.name ===
              'CODIGO_COGNITIVO_COMPORTAMENTAL'
              ? 'COMPORTAMENTAL'
              : community.user_in_community.Plan &&
                community.user_in_community.Plan.name
              ? community.user_in_community.Plan.name
              : null}
          </td>
          <td className="">
            {community.user_in_community.Assessment[0] &&
            community.user_in_community.Assessment[0].status === 'COMPLETED'
              ? 'COMPLETO'
              : community.user_in_community?.Assessment[0]?.status}
          </td>
          <td className="mr-0">
            <button
              onClick={() => {
                if (modal) {
                  modal(true)
                  setUserSelectedId!(community.user_in_community.id)
                  setUserSelectedData(community.user_in_community)
                }
              }}
              className="bg-[#004165] rounded-md px-3 py-1 font-light text-white"
            >
              <Translator path="community.seeData" />
            </button>
          </td>
        </>
      )
    case 'community_modal':
      return (
        <>
          <td className="text-center items-center justify-center">
            <UserCircle size={24} className="self-center" />
          </td>
          <td className="pl-2 flex items-center gap-2 h-10">
            {community.user_in_community.name}
          </td>
          <td className="truncate">{community.user_in_community.Plan.name}</td>
          <td className="w-28 mr-0">
            <button
              className="bg-[#004165] rounded-md px-3 py-1 font-light text-white"
              onClick={() => {
                setUserSelectedId!(community.user_in_community.id)
                setUserSelectedData(community.user_in_community)
              }}
            >
              <Eye size={24} />
            </button>
          </td>
        </>
      )
    default:
      return null
  }
}

const ListCommunity: React.FC<ListCommunityProps> = ({
  modal,
  type,
  userSelectedId,
  setUserSelectedId,
  filter,
  search,
  setUserSelectedData,
}) => {
  const [communities, setCommunities] = useState<ICommunitiesDTO[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [error, setError] = useState<string | JSX.Element | null>(null)
  // console.log('filter xarlys', filter)
  // console.log('search xarlys', filter)
  // console.log('userSelectedId', userSelectedId)

  useEffect(() => {
    let ignore = false
    const fetchData = async () => {
      setIsLoading(true)

      const params = {
        userSelectedId: userSelectedId || null,
        filter_test: filter || filter !== '' ? filter : null,
        search: search || search !== '' ? search : null,
      }

      try {
        const { data } = await api.get(
          '/v1/communities/find-users-in-community',
          { params },
        )
        if (!ignore) {
          if (data.length === 0 || !data) {
            // toast.error('Nenhuma comunidade encontrada.')
            setError(<Translator path="community.noUser" />)
            setIsLoading(false)
          } else {
            setError(null)
          }
          setCommunities(data)
        }
      } catch (error) {
        toast.error(<Translator path="community.requestError" />)
        // console.error('Erro ao buscar comunidades:', error)
        setError(<Translator path="community.requestError" />)
      } finally {
        setIsLoading(false)
        // setError(null)
      }
    }

    fetchData()
    return () => {
      ignore = true
    }
  }, [userSelectedId, filter, search])

  return (
    <div
      className={` w-full max-w-screen-md mx-auto h-fit ${
        error ? '' : 'max-h-[75vh]'
      }  overflow-scroll scroll`}
    >
      {error && (
        <div className="border border-red-400 rounded-b bg-[#ffd1d1] px-4 py-3 text-red">
          {error}
        </div>
      )}
      <table className="bg-white lg:table-fixe w-full ">
        <thead>
          {!error && (
            <tr>
              <TableHeader type={type} />
            </tr>
          )}
        </thead>
        <tbody>
          {isLoading ? (
            <tr>
              <td>
                <Translator path="community.loading" />
              </td>
            </tr>
          ) : (
            communities.map((community) => (
              <tr
                className="bg-gray-100 border-b border-gray-200"
                key={community.user_in_community.id}
              >
                <TableBody
                  type={type}
                  community={community}
                  setUserSelectedId={setUserSelectedId}
                  setUserSelectedData={setUserSelectedData}
                  modal={modal}
                />
              </tr>
            ))
          )}
        </tbody>
        <tfoot>
          <tr>
            <td colSpan={4} className="px-2 py-2">
              Total: {communities.length}
            </td>
            {/* <td colSpan={4}><span className="self-end w-fit mt-4">Listando resultados</span></td> */}
          </tr>
        </tfoot>
      </table>
    </div>
  )
}

export { ListCommunity }
