import React, { useState } from 'react'
import { PageContainer } from '../components/PageContainer'
import {
  DownloadSimple,
  MagnifyingGlass,
  UserCircle,
} from '@phosphor-icons/react'
import { InviteCard } from '../components/InviteCard'
import { ListCommunity } from '../components/ListCommunity'
import { ModalRight } from '../components/ModalRight'
import { Translator } from '../components/I18n/Translator'
import i18n from '../i18n'
import api from '../services/api'
import { ButtonLoading } from '../components/ButtonLoading'
import { useAuth } from '../components/hooks/provider/auth'

interface UserSelectedData {
  name?: string
  lastName?: string
  email?: string
  avatar?: string | null
  Plan?: {
    name: string
  }
  Assessment?: [
    {
      id?: string
      status?: string
      report_status: string
    },
  ]
}

const Community: React.FC = () => {
  const [open, setOpen] = useState<boolean>(false)
  const [userSelectedId, setUserSelectedId] = useState<string | null>(null)
  const [userSelectedData, setUserSelectedData] = useState<UserSelectedData>(
    {} as UserSelectedData,
  )
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [filter, setFilter] = useState<string>('')
  const [search, setSearch] = useState<string>('')

  const { user } = useAuth()

  const filter_test = [
    { name: <Translator path="community.notStarted" />, value: 'NOT_STARTED' },
    { name: <Translator path="community.inProgress" />, value: 'IN_PROGRESS' },
    { name: <Translator path="community.concluded" />, value: 'COMPLETED' },
  ]

  const handleDownloadPdf = async () => {
    if (i18n.language.toLocaleLowerCase() === 'en') {
      i18n.changeLanguage('en-US')
    }
    setIsLoading(true)
    const verify_assessment_id =
      userSelectedData?.Assessment && userSelectedData?.Assessment![0]
        ? userSelectedData?.Assessment![0].id
        : null
    const result = await api.get('/v1/generatePdf/download', {
      params: {
        assessmentId: verify_assessment_id, // userSelectedId,
        language: i18n.language.toLocaleLowerCase(),
      },
      responseType: 'blob',
    })
    setIsLoading(false)
    const url = window.URL.createObjectURL(result.data)
    const link = document.createElement('a')
    link.href = url
    if (link) {
      link.setAttribute(
        'download',
        `iPersonality_-_Relatorio_de_${userSelectedData?.name}.pdf`,
      )
      document.body.appendChild(link)
      link.click()
      link.parentNode!.removeChild(link)
    }
  }

  return (
    <PageContainer className="gap-6 relative flex flex-col lg:flex-row h-full">
      <div className="flex flex-col p-2 lg:max-w-[60%] w-full h-fit bg-white rounded-md">
        <div className="flex w-full items-center gap-2 mb-10">
          <h2 className="text-lg font-semibold w-full">
            <Translator path="community.community" />
          </h2>
          <select
            className="hidden lg:block w-full p-2 bg-white border rounded text-xs outline-0"
            id="filter_test"
            placeholder="Filtrar"
            name="filter_test"
            onChange={(e) => setFilter((e.target as HTMLSelectElement).value)}
          >
            <option value="" key={0}>
              <Translator path="community.select" />
            </option>
            {filter_test.map((item, key) => (
              <option value={item.value} key={key}>
                {item.name}
              </option>
            ))}
          </select>

          {/* <button className="bg-[#004165] rounded-md p-2 text-white font-light text-sm whitespace-nowrap ">
            Filtrar quem não fez o teste
          </button> */}
          <div className="flex rounded-md border px-1 items-center ">
            <input
              type="text"
              placeholder="search..."
              className="p-1 w-32 outline-none"
              onChange={(e) => setSearch((e.target as HTMLInputElement).value)}
            />
            <MagnifyingGlass size={24} />
          </div>
        </div>

        <ListCommunity
          filter={filter}
          search={search}
          modal={setOpen}
          type="community"
          setUserSelectedId={setUserSelectedId}
          userSelectedData={userSelectedData}
          setUserSelectedData={(value: UserSelectedData) =>
            setUserSelectedData(value)
          }
        />
      </div>
      <div className="flex flex-col w-full lg:max-w-[40%] gap-4">
        <InviteCard
          title={<Translator path="community.inviteTitle" />}
          description={<Translator path="community.inviteDescription" />}
        />
      </div>
      <ModalRight
        title=""
        open={open}
        setIsOpenController={() => setOpen(!open)}
      >
        <div className="bg-white py-4 flex gap-4 flex-col">
          <div className="flex w-full gap-5">
            <UserCircle size={72} className="self-center" />
            <div className="flex flex-col gap-2 w-[40%] text-sm">
              <span>
                <Translator path="community.modal.name" />
              </span>
              <b>{userSelectedData?.name?.toUpperCase()}</b>
              {import.meta.env.VITE_PARTNER_IDS?.split(',').includes(
                user?.id,
              ) && (
                <span className="font-semibold">
                  {userSelectedData?.email?.toLowerCase()}
                </span>
              )}
              <span className="">Status do Autoconhecimento:</span>

              {userSelectedData?.Assessment &&
              userSelectedData?.Assessment![0] &&
              userSelectedData?.Assessment![0].status === 'COMPLETED' ? (
                <span className="bg-Green w-[60%] text-center justify-center text-white px-2 py-1 rounded-sm">
                  {userSelectedData?.Assessment &&
                  userSelectedData?.Assessment![0]
                    ? userSelectedData?.Assessment![0].status
                    : 'not status'}
                </span>
              ) : (
                <span className="bg-buttonRed w-[60%] text-center justify-center text-white px-2 py-1 rounded-sm">
                  {' '}
                  {userSelectedData?.Assessment &&
                  userSelectedData?.Assessment![0]
                    ? userSelectedData?.Assessment![0].status
                    : 'not status'}
                </span>
              )}
            </div>
            <div className="flex flex-col gap-2 w-[40%]">
              <span>
                <Translator path="community.userPlan" />
              </span>
              <span className="truncate">{userSelectedData?.Plan?.name}</span>
              <span>
                <Translator path="community.modal.seeResult" />
              </span>
              {userSelectedData?.Assessment &&
              userSelectedData?.Assessment![0] &&
              userSelectedData?.Assessment![0].report_status === 'CREATED' &&
              userSelectedData?.Assessment![0].status === 'COMPLETED' ? (
                <ButtonLoading
                  isLoading={isLoading}
                  onClick={handleDownloadPdf}
                  disabled={
                    userSelectedData?.Assessment &&
                    userSelectedData?.Assessment![0] &&
                    userSelectedData?.Assessment![0].report_status !==
                      'CREATED' &&
                    userSelectedData?.Assessment![0].status !== 'COMPLETED'
                  }
                  css_class="disabled:opacity-50 text-md disabled:bg-red w-30 flex items-center justify-around bg-Blue text-white px-2 py-2 rounded-lg"
                  title="Relatório"
                >
                  {userSelectedData?.Assessment &&
                  userSelectedData?.Assessment![0] &&
                  userSelectedData?.Assessment![0].report_status !==
                    'CREATED' &&
                  userSelectedData?.Assessment![0].status !== 'COMPLETED' ? (
                    <svg
                      aria-hidden="true"
                      className="inline w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                      viewBox="0 0 100 101"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="currentColor"
                      />
                      <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="black"
                      />
                    </svg>
                  ) : (
                    <DownloadSimple color="#fff" size={18} />
                  )}
                  {userSelectedData?.Assessment &&
                  userSelectedData?.Assessment![0] &&
                  userSelectedData?.Assessment![0].report_status !==
                    'CREATED' &&
                  userSelectedData?.Assessment![0].status !== 'COMPLETED' ? (
                    'Gerando seu relatório'
                  ) : (
                    <Translator path="community.modal.testDone" />
                  )}
                </ButtonLoading>
              ) : (
                <span className="bg-red text-white px-2 py-1 rounded-sm">
                  <Translator path="community.modal.testNotDone" />
                </span>
              )}
            </div>
          </div>
          <span className="text-center font-bold bg-gray p-2 rounded-sm">
            <Translator path="community.modal.info" />
          </span>
          <ListCommunity
            type="community_modal"
            userSelectedId={userSelectedId}
            setUserSelectedId={setUserSelectedId}
            userSelectedData={userSelectedData}
            setUserSelectedData={(value: object) => setUserSelectedData(value)}
          />
        </div>
      </ModalRight>
    </PageContainer>
  )
}

export { Community }

// const handleFilterType = async (event: any) => {
//   try {
//     setIsLoading(true)
//     const result = await api.get('/v1/marketplace/find-all', {
//       params: {
//         filter_test: event.target.value
//       }
//     })

//     console.log("result", result)

//     // if (result.status === 200) {
//     //   toast.success('Marketplace criado com sucesso')
//     // }
//     setIsLoading(false)

//   } catch (error) {
//     toast.error('Erro ao criar o markeplace, tente novamente mais tarde!')
//   } finally {
//     setIsLoading(false)
//   }
//   console.log("filter leoooo", event.target.value)
//   // setFilterType(event.target.value)
// }
