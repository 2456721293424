import React, { useEffect, useState } from 'react'
import { YoutubeFrame } from '../components/YoutubeFrame'
import { PageContainer } from '../components/PageContainer'
import api from '../services/api'

export interface IPlanDTO {
  id?: string
  name:
    | 'FREE'
    | 'TECH_EMPRETENEUR'
    | 'PEOPLE_MASTERY'
    | 'TRAINING'
    | 'MASTER'
    | 'ADMIN'
  description: string
  active?: boolean
  price?: number
  price_stripe_id?: string
  scitech_info?: string
}

const Business: React.FC = () => {
  const [products, setProducts] = useState<IPlanDTO[]>([] as IPlanDTO[])
  const [isLoading, setIsLoading] = useState<boolean>(false)

  useEffect(() => {
    setIsLoading(true)
    const fetchData = async () => {
      const { data } = await api.get('/v1/plans/find-all')
      // console.log('business return', data)
      setProducts(data.plans)
    }

    fetchData()
    setIsLoading(false)
  }, [])

  return (
    <PageContainer>
      <div className="flex w-full h-1/3 gap-4">
        <div className="w-1/2">
          <YoutubeFrame title="SicTehBusiness"></YoutubeFrame>
        </div>
        <div className="flex flex-col gap-4 w-full">
          {products.map((product: IPlanDTO) => {
            return (
              <div
                key={product.id}
                className="flex flex-col bg-white rounded-lg border border-borderColor p-4 w-3/5"
              >
                <span className="text-xs ">{product.description}</span>
                <div className="flex gap-4 mt-3">
                  <button className="bg-orange text-darkGray rounded-xl  text-xs font-semibold px-3">
                    {product.name}
                  </button>
                  <span className=" text-darkGray rounded-xl text-base font-semibold px-2">
                    {product.scitech_info}
                  </span>
                </div>
              </div>
            )
          })}
          {/* [ ] TODO - Fazer uma forma alternando cores de text e background de span */}
          <div className="flex flex-col bg-white rounded-lg border border-borderColor p-4 w-full">
            <span className="text-xs ">
              Para se tornar um empreendedor, e lucrar com nossos produtos
              habilite se ao programa Tranner
            </span>
            <div className="flex gap-4 mt-3">
              <button className="bg-orange text-darkGray rounded-xl  text-xs font-semibold px-3">
                TRANNER
              </button>
              <span className=" text-darkGray rounded-xl text-base font-semibold px-2">
                SciTech Business
              </span>
            </div>
          </div>
          <div className="flex flex-col bg-white rounded-lg border border-borderColor p-4 w-full">
            <span className="text-xs ">
              Para ter acesso a todo o programa de ganhos seja um{' '}
              <strong> People Mastery.</strong>
            </span>
            <div className="flex items-center justify-between gap-2 mt-3">
              <button className="bg-lighterBlue text-darkGray rounded-xl  text-xs font-semibold px-1 py-1">
                PEOPLE MASTERY
              </button>
              <span className=" text-darkGray rounded-xl  text-base font-semibold px-2">
                SciTech Business
              </span>
            </div>
          </div>
        </div>
      </div>
    </PageContainer>
  )
}

export { Business }
