/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react'

import { PageContainer } from '../../components/PageContainer'
// import { Form } from './Form_old'
import { Introduction } from './Introduction'
import { Initial } from './Initial'
import { Form } from './Form'
import api from '../../services/api'
import { toast } from 'react-toastify'

// import { Introduction } from './Introduction'
import { Result } from './Result'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '../../components/hooks/provider/auth'
import { ErrorReport } from './ErrorReport'

const SelfKnowledge: React.FC = () => {
  const [step, setStep] = useState(null as number | null)
  const [statusReport, setStatusReport] = useState('')
  const [assessmentId, setAssessmentId] = useState('')
  const [error, setError] = useState<string | null>(null)
  const { i18n } = useTranslation()

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const navigate = useNavigate()
  const { user } = useAuth()

  useEffect(() => {
    const handleGetUserAssessment = async () => {
      try {
        if (
          JSON.parse(String(window.localStorage.getItem('form-steps'))) != null
        ) {
          setStep(JSON.parse(String(window.localStorage.getItem('form-steps'))))
        }

        const result = await api.get(`/v1/assessments/find-by-user-assessments`)

        if (!user || user === undefined) {
          toast.error('Usuário não encontrado')
          return
        }

        if (
          !result ||
          !result.data ||
          result.data === undefined ||
          result === undefined
        ) {
          const dataAssements = {
            user_id: user.id,
            is_paid: true,
            assessment_type: 'PRIMARY',
            status: 'NOT_STARTED',
            invited_by_user_id: 1 || null,
            started_at: new Date(),
            report_status: 'CREATED', // Alterado dia 17-01 para atender a alteração do jonas de NOT_STARTED para CREATED
          }

          try {
            setIsLoading(true)
            const created_assessments = await api.post(
              '/v1/assessments/create',
              dataAssements,
            )
            console.log('created_assessments', created_assessments)

            setIsLoading(false)
          } catch (error) {
            toast.error(`${error}`)
            setIsLoading(false)
            navigate(`/home`)
          }

          setStep(0)
        }

        if (result.status === 200 && result.data.status === 'NOT_STARTED') {
          setStep(0)
          setAssessmentId(result.data.id)
        }

        if((result.status === 200 && result.data.status === 'COMPLETED') && result.data.report_status !== 'CREATED') {
          setStep(4)
          setStatusReport(result.data.report_status)
          setAssessmentId(result.data.id)
        }else if ((result.status === 200 && result.data.status === 'COMPLETED')) {
          setStep(3)
          setStatusReport(result.data.report_status)
          setAssessmentId(result.data.id)
          // toast.success('Formulário já preenchido')
        }
      } catch (error) {
        console.log('Error finding form', error)
        // toast.error(
        //   'Houve um erro ao salvar o questionário por favor tente novamente',
        // )
      }
    }

    handleGetUserAssessment()
  }, [])

  function changeStep(step: number) {
    setStep(step)
    window.localStorage.setItem('form-steps', String(step))
  }

  function clearLocalStorage() {
    window.localStorage.removeItem('form-steps')
    window.localStorage.removeItem('form-data')
  }

  const pageStep = () => {
    switch (step) {
      case 0:
        return <Initial next={() => changeStep(1)} />
      case 1:
        return <Introduction next={() => changeStep(2)} />
      case 2:
        // return <Form setFormStep={(value: number) => setStep(value)} />
        return <Form formResult={() => changeStep(3)} />
      case 3:
        return (
          <Result statusReport={statusReport} assessmentId={assessmentId} />
        )
      case 4:
        return (
          <ErrorReport statusReport={statusReport} assessmentId={assessmentId} />
        )
    }
  }

  return (
    <PageContainer className="justify-center relative h-full">
      {pageStep()}
    </PageContainer>
  )
}

export { SelfKnowledge }

{
  /* Descomentar dps que puxar os dados se o usuário realizou o teste */
}
{
  /* {step === 1 || step === 2 ? (
  <button
    onClick={() => changeStep(step - 1)}
    className="bg-white rounded-lg border border-[#EAECEF]  shadow-sm flex items-center justify-center gap-4 p-4 h-fit absolute top-[2rem] left-[1.5rem]"
  >
    <img src="/assets/IconBack.svg" alt="" />
    Voltar
  </button>
) : null} */
}
