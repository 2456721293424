export default {
  translations: {
    login: {
      title: 'Faça login em sua conta',
      email: 'Endereço de email',
      errorEmail: 'Email é obrigatório',
      password: 'Senha',
      errorPassword: 'Senha é obrigatório',
      forgotPassword: 'Esqueceu sua senha?',
      btn: 'Conecte-se',
    },
    invitePage: {
      title: 'Cadastre-se para criar sua conta',
      name: 'Nome',
      errorName: 'Nome é obrigatório',
      lastName: 'Sobrenome',
      errorLastName: 'Nome é obrigatório',
      emailAddress: 'Endereço de Email',
      errorEmail: 'Email é obrigatório',
      password: 'Senha',
      errorPassword: 'Senha é obrigatório',
      nickname: 'Apelido',
      gender: 'Gênero',
      male: 'Masculino',
      female: 'Feminino',
      errorPage: 'Referência de convite não encontrada.',
      btnNext: 'Próximo',
      btnRegister: 'Registrar',
      error: 'Erro ao criar o registro!',
      success: 'Registro realizado com sucesso!',
    },
    sideBar: {
      plan: 'Plano',
      home: 'Início',
      profile: 'Perfil',
      selfKnowledge: 'Autoconhecimento',
      documents: 'Documentos',
      developYourself: 'Desenvolva-se',
      community: 'Comunidade',
      chat: 'Bate papo',
      marketplace: 'Mercado',
      events: 'Eventos',
      financial: 'Financeiro',
      myConscience: 'Minha consciência',
      careerPath: 'Plano de carreira',
      sicTehBusiness: 'Negócio e tecnologia',
      viewMore: 'Ver mais',
      brief: 'Breve',
      new: 'Novo',
      logout: 'Sair',
    },
    home: {
      dashboard: 'Painel',
      cardOne: 'Total Avaliações Primárias e (%) Respondidas',
      cardTwo: 'Total Avaliações por Convite (%) Respondidas',
      cardThree: 'Á receber perfil IPersonality',
      cardFour: 'Σ de transações registradas',
      graphic: 'Quantidade e % de usuários',
      requestError:
        'Houve um erro ao buscar os dados por favor, tente novamente mais tarde.',
      msg: 'Nenhum dado encontrado',
      salutation: 'Boas vindas',
      videoTitle: 'Vídeo de boas vindas',
      inviteTitle:
        'Ajude mais pessoas a se descobrir, compartilhando seu link abaixo.',
      errorShare:
        'Houve um erro ao gerar link de compartilhamento. Por favor, tente novamente mais tarde.',
    },
    profile: {
      title: 'Perfil',
      typeAccess: 'Tipo de acesso/graduação',
      name: 'Nome',
      surname: '',
      nickname: 'Apelido',
      informationNickname:
        ' * Este é o nome que será exibido para outros usuários e nos relatórios gerados pela plataforma.',
      gender: 'Gênero',
      genderMen: 'Masculino',
      genderWoman: 'Feminino',
      email: 'E-mail',
      code: 'Código Afiliado',
      theCode: 'O código',
      informationCode:
        'será usado para identificar os usuários que você convidar para a plataforma.',
      buttonCancel: 'Cancelar',
      buttonSave: 'Salvar',
      buttonUpdate: 'Atualizar',
      success: 'Perfil atualizado com sucesso',
      avatar: 'Avatar atualizada com sucesso',
      errorAvatar: 'Erro ao atualizar avatar',
    },
    selfKnowledge: {
      initial: {
        title: 'Descubra seu código cognitivo comportamental!',
        description: 'Inicie o inventário agora e desvende seus traços únicos',
        btn: 'Descobrir',
      },
      introduction: {
        hello: 'Olá, ',
        firstText: 'Código Cognitivo Comportamental',
        secondText:
          'Ao <b>INICIAR</b>, você poderá explorar dez grupos de palavras com quatro diferentes adjetivos em cada grupo;',
        thirdText:
          'Em cada tela dê nota de 1 a 4 aos adjetivos e classifique conforme os critérios a seguir:',
        list: {
          one: 'Nota 01 em <b>1° (primeiro)</b> lugar para o adjetivo que melhor te descreve.',
          two: 'Nota 02 em <b>2° (segundo)</b> lugar para o adjetivo que melhor te identifica em sequência.',
          three:
            'Nota 03 em <b>3° (terceiro) e nota 04 para o 4° (quarto)</b> e último adjetivo que será aquele que menos te descreve.',
          four: '',
        },
        fourthText:
          'Se precisar, clique no ícone 🕕 para ler o significado do adjetivo em caso de dúvida',
        btn: 'Iniciar',
      },
      form: {
        step: 'Etapa',
        btnHowAnswer: 'como responder',
        btnNext: 'Próxima seção',
        btnBack: 'Voltar',
        success: 'Questionário preenchido com sucesso',
        error:
          'Houve um erro ao preencher o questionário por favor tente novamente, ou entre em contato com nosso suporte',
        howAnswer: {
          title: 'Como responder o questionário?',
          step: {
            one: 'Em cada tela serão apresentados 4 adjetivos para que você enumere conforme o seu grau de identificação.',
            two: 'Em cada tela serão apresentados 10 adjetivos para que você enumere conforme o seu grau de identificação.',
          },
          textOne: 'Cada número corresponde a um grau de relevância, sendo:',
          list: {
            veryRelevant: 'Muito relevante',
            relevant: 'Relevante',
            someRelevance: 'Alguma relevância',
            lowRelevance: 'Baixa relevância',
          },
          textTwo:
            'Selecione um número <b>clicando ou tocando no botão</b> correspondente ao seu grau de identificação com o adjetivo. Para desfazer a seleção, basta clicar ou tocar novamente no botão.',
          textThree:
            'Caso tenha alguma dúvida referente ao significado de algum adjetivo, clique no ícone 🕕 para saber a definição e verificar se é o adjetivo que melhor te identifica.',
          btn: 'Fechar',
        },
      },
      nextStage: {
        first: {
          firstText:
            'Responda novamente ao mapeamento de características, mas desta vez retratando como você acredita que as pessoas que convivem com você no seu dia-a-dia pessoal e profissional precisam que você seja para ter uma melhor conexão, desempenho e resultados.',
          secondText:
            'Agora, o propósito é pontuar as características de 1 a 4 na posição que retrata como os OUTROS esperam que você seja e aja.',
        },
        second: {
          firstText:
            'A partir de agora traremos para você 10 etapas, cada uma delas com 10 colocações que você pontuar de 1 a 10 da melhor forma como acredita que deve para retratar os seus motivadores. Classificando sempre do 1, primeiro e o maior (Mais importante) ao 10, último e o Menor (Menos relevante).',
          secondText:
            'Pense exatamente no que é significativo em sua vida, nos princípios e valores que você usa como bússola para tomar suas decisões, naquilo que te motiva e guia suas ações diárias.',
        },
        btnStart: 'Iniciar',
        btnBack: 'Voltar',
      },
      result: {
        title: 'Código cognitivo comportamental',
        description:
          'Baixe em PDF o seu Código Cognitivo Comportamental gratuitamente clicando no botão abaixo',
        btnTitle: 'Relatório',
        btnLoading: 'Gerando seu relatório',
        btnCreated: 'Baixe seu relatório',
        videoTitle: 'Autoconhecimento',
      },
    },
    developYourself: {
      title: 'Desenvolva-se',
      noPlan: 'Nenhum plano encontrado.',
      requestError:
        'Houve um erro ao buscar os planos. Por favor, tente novamente mais tarde.',
      purchase: 'Adquirir',
      acquired: 'Adquirido',
    },
    community: {
      community: 'Comunidade',
      select: 'Selecione a Opção Para Filtar',
      notStarted: 'NAO INICIADO',
      inProgress: 'EM PROGRESSO',
      concluded: 'CONCLUIDO',
      name: 'Nome',
      userPlan: 'Plano Usuário',
      testStatus: 'Status do Teste',
      seeData: 'Ver dados',
      loading: 'Carregando...',
      yourCommunity: 'Sua comunidade',
      inviteTitle: 'Faça conexões',
      inviteDescription:
        'Clique aqui e selecione um usuário para conversar dentro da plataforma',
      noUser:
        'Você não possui usuário na sua comunidade, utilize seu link de convite para convidar novos usuários.',
      requestError:
        'Houve um erro ao buscar as comunidades. Por favor, tente novamente mais tarde.',
      modal: {
        name: 'Nome Usuário',
        seeResult: 'Ver status do relatório',
        testDone: 'Autoconhecimento preenchido',
        testNotDone: 'Autoconhecimento não preenchido',
        status: 'Autoconhecimento não preenchido',
        info: 'Informações e dados da sua comunidade',
        errorInfo:
          'Você não possui usuário na sua comunidade, utilize seu link de convite para convidar novos usuários.',
      },
    },
    recoverPassword: {
      redefinePassword: 'Redefinir senha',
      emailAddress: 'Email',
      password: 'Senha',
      confirmPassword: 'Confirmar senha',
      changePassword: 'Alterar a senha',
      success: 'Senha atualizada com sucesso!',
    },
    forgotPassword: {
      title: 'Recuperar Senha',
      email: 'Endereço de email',
      btnRecover: 'Enviar Link de Recuperação',
      questionRemember: 'Lembrou a senha?',
      return: 'Ir para o login',
      success: 'Um e-mail foi enviando para você recuperar sua senha',
    },
    component: {
      inviteCard: {
        info: 'Link copiado para a área de transferência',
        error:
          'Houve um erro ao gerar link de compartilhamento. Por favor, tente novamente mais tarde.',
      },
      privateRoute: 'Erro - Necessário estar logado para acessar essa rota!',
    },
  },
}
