import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'
import jwt_decode from 'jwt-decode'
import api from '../../../services/api'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

interface SignInCredentials {
  email: string
  password: string
}

export interface IUserDTO {
  id: string
  name: string
  email: string
  avatar?: string
  ref_code?: string
  plan_id?: string
}

interface IPlanDTO {
  id?: string
  name: string
}

interface IAuthContextData {
  logout: () => void
  isAuthenticated: boolean
  login: ({ email, password }: SignInCredentials) => Promise<void>
  plan: IPlanDTO
  user?: IUserDTO
  ref_code?: string
  refreshUserData: () => void
  token: string
}

interface ITokenPayload {
  iat: number
  exp: number
  user: IUserDTO
  plan: IPlanDTO
}

interface AuthState {
  token: string
  user: IUserDTO
  plan: IPlanDTO
}

interface IAuthProviderProps {
  children: React.ReactNode
}

interface IErro {
  error: {
    response: {
      data?: string
      status: number
    }
  }
}

const AuthContext = createContext<IAuthContextData>({} as IAuthContextData)

export const AuthProvider: React.FC<IAuthProviderProps> = ({ children }) => {
  const navigate = useNavigate()
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const [data, setData] = useState<AuthState>(() => {
    const token = localStorage.getItem('@ipersonality:token')
    const user = localStorage.getItem('@ipersonality:user')
    const plan = localStorage.getItem('@ipersonality:plan')
    // const invite = localStorage.getItem('@ipersonality:invite')
    if (token && user && plan) {
      api.defaults.headers.common.Authorization = `Bearer ${token}`
      return { token, user: JSON.parse(user), plan: JSON.parse(plan) }
    }
    return {} as AuthState
  })

  const setAuthorizationHeader = (token: string) => {
    api.defaults.headers.common.Authorization = `Bearer ${token}`
  }

  const checkAuthenticationCookie = () => {
    //verifica se existe cookie de auth
    const isAuthenticated = document.cookie.split(';').some((cookie) => cookie.trim().startsWith('loggedIn='));
    return isAuthenticated;
  };

  useEffect(() => {
    const token = localStorage.getItem('@ipersonality:token')

    if (token) {
      const decoded = jwt_decode<ITokenPayload>(token)

      if (decoded.exp < Date.now() / 1000) {
        navigate('/')
        toast.error('Session expired, please sign in to your account')
      }

      const userIsLoggedIn = checkAuthenticationCookie()
      setIsLoggedIn(userIsLoggedIn)

      setAuthorizationHeader(token)
      setData({
        token,
        user: JSON.parse(localStorage.getItem('@ipersonality:user')!),
        plan: JSON.parse(localStorage.getItem('@ipersonality:plan')!),
      })

      navigate('/home')
      toast.success('Welcome back!')
    }
  }, [])

  const handleLogin = useCallback(
    async ({ email, password }: SignInCredentials) => {

      try {
        // const {
        //   data: { token }, status
        // } =
        if(isLoggedIn){
          toast.error('Você já está logado em outro navegador ou aba!')
        }else {
          const response = await api.post('/v1/login', { email, password })
          const { token, status } = response.data
          if (response instanceof Error) {
            toast.error(`${response.message}`)
          } else {
            const decoded = jwt_decode<ITokenPayload>(token)

            const { user, plan } = decoded
            if (!plan || !plan.name || plan === null) {
              throw new Error('Usuário sem plano!')
            } else if (status === 'NOT_STARTED') {
              navigate('/selfKnowledge')
            } else {
              navigate('/home')
            }
            setAuthorizationHeader(response.data.token)
            setData({ token: response.data.token, user, plan: decoded.plan })
            localStorage.setItem('@ipersonality:token', response.data.token)
            localStorage.setItem('@ipersonality:user', JSON.stringify(user))
            localStorage.setItem('@ipersonality:plan', JSON.stringify(plan))
            localStorage.setItem(
              '@ipersonality:ref_code',
              user.ref_code ? user.ref_code : '',
            )
            document.cookie = 'loggedIn=true;path=/'; // Defina um cookie de autenticação
            setIsLoggedIn(true);
          }
        }

      } catch (error: IErro | any) {
        if (error?.response.status === 400) {
          toast.error(`${error?.response.data}`)
        } else {
          toast.error(`${error}`)
        }
      }
    },
    [navigate],
  )

  const handleLogout = useCallback(() => {
    localStorage.clear()

    setData({} as AuthState)
    navigate('/')

    document.cookie = 'loggedIn=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/'; // Define a data de expiração para remover o cookie
    setIsLoggedIn(false);
    toast.info("Você foi deslogado com sucesso!");

  }, [navigate])

  const refreshUserData = async () => {
    const { data: user } = await api.get('/v1/users/details/' + data.user.id)
    const { data: plan } = await api.get('/v1/plans/details/' + user.plan_id)
    localStorage.setItem('@ipersonality:user', JSON.stringify(user))
    localStorage.setItem('@ipersonality:plan', JSON.stringify(plan))
    setData({ ...data, user, plan })
  }

  const isAuthenticated = Boolean(data.token && data.user && data.plan)

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        login: handleLogin,
        logout: handleLogout,
        user: data.user,
        plan: data.plan,
        ref_code: data.user?.ref_code,
        refreshUserData,
        token: data.token,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export const useAuth = () => useContext(AuthContext)
