import React, { useState } from 'react'

import { YoutubeFrame } from '../../components/YoutubeFrame'
import { DownloadSimple, ShoppingCart } from '@phosphor-icons/react'
import api from '../../services/api'
import { ButtonLoading } from '../../components/ButtonLoading'
import { useAuth } from '../../components/hooks/provider/auth'
import { useTranslation } from 'react-i18next'
import { Translator } from '../../components/I18n/Translator'

interface IResultProps {
  statusReport: string
  assessmentId: string
}

const Result: React.FC<IResultProps> = ({ statusReport, assessmentId }) => {
  const [isLoading, setIsLoading] = useState(false)
  const { user } = useAuth()
  const { i18n } = useTranslation()
  const handleDownloadPdf = async () => {
    if (i18n.language.toLocaleLowerCase() === 'en') {
      i18n.changeLanguage('en-US')
    }
    setIsLoading(true)
    const result = await api.get('/v1/generatePdf/download', {
      params: {
        assessmentId,
        language: i18n.language.toLocaleLowerCase(),
      },
      responseType: 'blob',
    })
    setIsLoading(false)
    const url = window.URL.createObjectURL(result.data)
    const link = document.createElement('a')
    link.href = url
    if (link) {
      link.setAttribute(
        'download',
        `iPersonality_-_Relatorio_de_${user?.name}.pdf`,
      )
      document.body.appendChild(link)
      link.click()
      link.parentNode!.removeChild(link)
    }
    setIsLoading(false)
    window.open(url, '_blank');
  }

  const handleUpdateStatus = async () => {
    const formData = {
      report_status: 'CREATED'
    }
    const result = await api.put('/v1/assessments/update', formData)
    setIsLoading(false)
  }

  return (
    <div className="w-full h-fit flex flex-col-reverse lg:flex-row gap-4">
      <div className="flex flex-col gap-4">
        <div className="bg-white rounded-lg border-2 border-borderColor flex flex-col justify-between p-4 w-full lg:w-80 gap-2">
          <span className="font-semibold text-sm">
            <Translator path="selfKnowledge.result.title" />
          </span>
          <img
            className="w-40 self-center"
            src={'/assets/SelfKnowledgeImage.svg'}
            alt="SelfKnowledge"
          />
          <span className=" self-center text-sm font-semibold text-center">
            <Translator path="selfKnowledge.result.description" />
          </span>
          {
            statusReport === 'NOT_STARTED' ? (
              <button
                className='w-60 flex items-center justify-around self-center bg-red text-white px-2 py-2 rounded-lg'
                onClick={handleUpdateStatus}
              >Houve um erro para gerar seu relatório, clique aqui para corrigir</button>
            ) : (
              <ButtonLoading

              isLoading={isLoading}
              onClick={handleDownloadPdf}
              disabled={false} // statusReport !== 'CREATED'
              css_class="disabled:opacity-50 disabled:bg-red w-60 flex items-center justify-around self-center bg-Blue text-white px-2 py-2 rounded-lg"
              title={<Translator path="selfKnowledge.result.btnTitle" />}
            >

              <Translator path="selfKnowledge.result.btnCreated" />
            </ButtonLoading>
            )
          }


        </div>
        {/* Descomentar após realização da funcionalidade */}
        {/* <div className="bg-white rounded-lg border-2 border-borderColor flex flex-col justify-between p-4 w-80 gap-2">
          <img
            className="w-40 self-center"
            src={'/assets/ModalImage.svg'}
            alt="ModalImage"
          />
          <span className=" self-center text-xs text-center">
            Agora você pode dar mais um passo em direção a se decifrar.
            Desbloqueei completamente seu Código Cognitivo Comportamental.
          </span>
          <button className="w-40 flex items-center justify-around self-center bg-Green text-white px-2 py-2 rounded-lg">
            <ShoppingCart size={25} color="#fff" weight="fill" />
            Desbloquear
          </button>
        </div> */}
      </div>
      <YoutubeFrame
        title={<Translator path="selfKnowledge.result.videoTitle" />}
      ></YoutubeFrame>
    </div>
  )
}

export { Result }

// [ ] Retirado validação do Botão a pedido de Mattiuzzi e Jonas em reunião que aconteceu com IPersonality
// no dia 18/01/2024 - Feito por xarlys
 {/* {statusReport === 'CREATING' ? (
                <svg
                  aria-hidden="true"
                  className="inline w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="currentColor"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="black"
                  />
                </svg>
              ) : (
                <DownloadSimple color="#fff" size={25} />
              )}
              {statusReport === 'CREATING' ? (
                <Translator path="selfKnowledge.result.btnLoading" />
              ) : (
                <Translator path="selfKnowledge.result.btnCreated" />
)} */}


{/* <button
  onClick={handleDownloadPdf}
  disabled={statusReport !== 'CREATED'}
  className="disabled:opacity-50 disabled:bg-red w-60 flex items-center justify-around self-center bg-Blue text-white px-2 py-2 rounded-lg"
>
  {statusReport !== 'CREATED' ? (
    <svg
      aria-hidden="true"
      className="inline w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
      viewBox="0 0 100 101"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
        fill="currentColor"
      />
      <path
        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
        fill="black"
      />
    </svg>
  ) : (
    <DownloadSimple color="#fff" size={25} />
  )}
  {statusReport !== 'CREATED'
    ? 'Gerando seu relatório'
    : 'Baixe seu relatório'}
</button> */}
