import React from 'react'

import { PaperPlaneRight } from '@phosphor-icons/react'

import { Message } from '../components/Message'

const MyConscience: React.FC = () => {
  return (
    <div className="w-full h-screen flex flex-col p-7 bg-lighterGray gap-4 overflow-hidden">
      <div className="w-full bg-white p-3 rounded-lg border-2 border-borderColor flex items-center justify-between">
        <span className="font-semibold text-base">Minha consciência</span>
      </div>
      <div className="w-full h-full flex flex-col rounded-lg">
        <div className="p-3 w-full h-full flex gap-1 flex-col items-center justify-end pt-2 pb-2 bg-[url('/assets/ChatBackground.svg')] rounded-lg">
          <div className="w-full flex flex-col gap-3 mb-4 p-1">
            <Message sent={false} message="text send" />
            <Message sent={true} message="text received" />
          </div>
          <div className="flex w-full ">
            <div className="bg-white px-4 py-1 rounded-full flex items-center gap-0.5 w-full">
              <input
                type="text"
                className="w-full color-gray"
                placeholder="Escreva sua mensagem"
              />
            </div>
            <button className="flex items-center justify-center bg-chatBlueGreen rounded-full p-1.5 ml-3">
              <PaperPlaneRight size={32} color="#fff" weight="fill" />
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export { MyConscience }
