import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'

import { ForgotPassword } from './../pages/ForgotPassword'
import { Home } from './../pages/Home'
import { Layout } from '../pages/layout'
import { Community } from '../pages/Community'
import { Profile } from './../pages/Profile'
import { Business } from './../pages/Business'
import { SelfKnowledge } from '../pages/SelfKnowledge'
import { Login } from '../pages/Login'
import { PrivateRoute } from './PrivateRoute'
import { NotFound } from '../pages/NotFound'
import { Development } from './../pages/Development'
import { CareerPath } from './../pages/CareerPath'
import { Documents } from './../pages/Documents'
import { Marketplace } from './../pages/Marketplace'
import { Financial } from './../pages/Financial'
import { RecoverPassword } from '../pages/RecoverPassword'
import { Register } from '../pages/Register'
import { MyConscience } from './../pages/MyConscience'
import { SuccessPurchase } from '../pages/SuccessPurhcase'
import { Events } from '../pages/Events'

const RoutesPage: React.FC = () => (
  <Routes>
    <Route path="/" caseSensitive={false} element={<Login />} />
    <Route path="/not-found" caseSensitive={false} element={<NotFound />} />

    <Route path="invite/:id" caseSensitive={false} element={<Register />} />

    <Route
      path="/forgotPassword"
      caseSensitive={false}
      element={<ForgotPassword />}
    />
    <Route
      path="/recoverPassword"
      caseSensitive={false}
      element={<RecoverPassword />}
    />
    <Route path="/" element={<Layout />}>
      <Route
        path="/home"
        caseSensitive={false}
        element={
          <PrivateRoute>
            <Home />
          </PrivateRoute>
        }
      />

      <Route
        path="/profile"
        caseSensitive={false}
        element={
          <PrivateRoute>
            <Profile />
          </PrivateRoute>
        }
      />

      {/* <Route path="/profile" caseSensitive={false} element={<Profile />} /> */}

      <Route
        path="/business"
        caseSensitive={false}
        element={
          <PrivateRoute>
            <Business />
          </PrivateRoute>
        }
      />

      <Route
        path="/selfKnowledge"
        caseSensitive={false}
        element={
          <PrivateRoute>
            <SelfKnowledge />
          </PrivateRoute>
        }
      />
      <Route
        path="/development"
        caseSensitive={false}
        element={
          <PrivateRoute>
            <Development />
          </PrivateRoute>
        }
      />

      <Route path="/documents" caseSensitive={false} element={<Documents />} />

      <Route
        path="/community"
        caseSensitive={false}
        element={
          <PrivateRoute>
            <Community />
          </PrivateRoute>
        }
      />
      <Route
        path="/marketplace"
        caseSensitive={false}
        element={
          <PrivateRoute>
            <Marketplace />
          </PrivateRoute>
        }
      />
      {/* <Route
        path="/events"
        caseSensitive={false}
        element={
          <PrivateRoute>
            <Events />
          </PrivateRoute>
        }
      /> */}

      <Route
        path="/financial"
        caseSensitive={false}
        element={
          <PrivateRoute>
            <Financial />
          </PrivateRoute>
        }
      />

      <Route
        path="/careerPath"
        caseSensitive={false}
        element={
          <PrivateRoute>
            <CareerPath />
          </PrivateRoute>
        }
      />

      <Route
        path="/myConscience"
        caseSensitive={false}
        element={
          <PrivateRoute>
            <MyConscience />
          </PrivateRoute>
        }
      />
      <Route
        path="/successPurchase"
        caseSensitive={false}
        element={<SuccessPurchase />}
      />

      <Route path="*" element={<Navigate to="/not-found" />} />
    </Route>
  </Routes>
)

export { RoutesPage }
