import { useQuery } from 'react-query'
import api from '../../../services/api'

async function verifyRefCode(id: string): Promise<boolean> {
  const { data } = await api.get(`/v1/users/verify_ref_code/${id}`)

  if (data) {
    return data
  }

  return false
}

export function useVerifyRefCode(id: string) {
  return useQuery(['verify_ref_code', id], () => verifyRefCode(id))
}
