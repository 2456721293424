import { ArrowLeft, ArrowRight } from '@phosphor-icons/react'
import { FormHeader } from '../../../components/FormHeader'
import { Link } from 'react-router-dom'

import { question } from './questions'
import { questionEN } from './questions-en'
import { questionES } from './questions-es'

import { ChoiceCard } from '../../../components/ChoiceCard'
import { useEffect, useState } from 'react'
import { NextStage } from '../NextStage'
import { toast } from 'react-toastify'
import api from '../../../services/api'
import { HowAnswer } from '../../../components/HowAnswer'
import { Translator } from '../../../components/I18n/Translator'
import { useTranslation } from 'react-i18next'

export interface QuestionInfo {
  step: number
  type: string
  name: string
  description: string
}

interface FormProps {
  formResult: () => void
}

const Form: React.FC<FormProps> = ({ formResult }) => {
  const [errorMessage, setErrorMessage] = useState('');


  const [pageNumber, setPageNumber] = useState(1)
  const [answerQuantity, setAnswerQuantity] = useState(4)
  const [questions, setQuestions] = useState([] as QuestionInfo[])
  const [answerArray, setAnswerArray] = useState<
    Array<string | null | undefined>
  >([null])
  const { i18n } = useTranslation()
  //inicio - definindo um idoma padrão para o usuário - refatorado por xarlys 08/02/2024
  let idioma = i18n.language.toLocaleLowerCase();
  const languageDataMap: { [key: string]: (string | number | null)[][] } = {
    'en-us': questionEN,
    'es-es': questionES,
    'pt-br': question,
  }
  // i18n.language.toLocaleLowerCase() === 'en-US'
  //fim - refatorado por xarlys 08/02/2024

  const [step, setStep] = useState(1)
  const [storageAnswerArray, setStorageAnswerArray] = useState<
    Array<Array<Array<string | null | undefined>>>
  >([[[null]]])
  const [showIntroduction, setShowIntroduction] = useState(false)
  const [howAnswer, setHowAnswer] = useState(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  // Verificar depois
  const [teste, setTeste] = useState(true)

  useEffect(() => {
    const localStorageAnswerArray = JSON.parse(
      String(localStorage.getItem('@ipersonality:answers')),
    )

    if (localStorageAnswerArray) {
      if (localStorageAnswerArray.length === 3) {
        setAnswerQuantity(10)
      }
      setStorageAnswerArray(localStorageAnswerArray)
      setStep(localStorageAnswerArray.length)

      setPageNumber(
        localStorageAnswerArray[localStorageAnswerArray.length - 1].length,
      )
      setAnswerArray(
        localStorageAnswerArray[localStorageAnswerArray.length - 1][
          localStorageAnswerArray[localStorageAnswerArray.length - 1].length - 1
        ],
      )
    }
  }, [])

  useEffect(() => {


    if (['en-us', 'es-es', 'pt-br'].includes(idioma)) {

      const questionsData = languageDataMap[idioma];
      const data = questionsData.map((item) => {
        return {
          step: item[0],
          type: item[1],
          name: item[2],
          description: item[3],
        };
      });

      const filtrado = data
        ?.filter((item) => item.step === step)
        ?.map((item: any) => item)
        .slice((pageNumber - 1) * answerQuantity, pageNumber * answerQuantity)
      setQuestions(filtrado)

      console.log('filtrado', filtrado)
      console.log('filtrado.length', filtrado.length)
      if( filtrado.length === 0){
        setErrorMessage('Error loading questions, you need to choose a valid language');
      }

    } else {
      idioma = 'en-us'
      const data = questionEN.map((item) => {
        return {
          step: item[0],
          type: item[1],
          name: item[2],
          description: item[3],
        }
      })

      const filtrado = data
        ?.filter((item) => item.step === step)
        ?.map((item: any) => item)
        .slice((pageNumber - 1) * answerQuantity, pageNumber * answerQuantity)
      setQuestions(filtrado)

      if( filtrado.length === 0 || filtrado === undefined ){
        setErrorMessage('Error loading questions, you need to choose a valid language');
      }
    }


  }, [pageNumber, answerQuantity, idioma])

  const handleAnswerQuestion = (type: string, value: number) => {
    const answerArrayCopy = answerArray
    if (answerArray[value] && answerArray[value] === type) {
      answerArrayCopy[value] = null
    } else {
      answerArrayCopy[value] = type
    }
    // console.log('answerArray.includes(null)', answerArray.includes(null))
    // console.log(
    //   'step !== 3 && answerArray.length !== 4',
    //   step !== 3 && answerArray.length !== 4,
    // )
    // console.log(
    //   'step === 3 && answerArray.length !== 10',
    //   step === 3 && answerArray.length !== 10,
    // )
    setAnswerArray(answerArrayCopy)
    handleStoreQuestion()
    setTeste(!teste)
  }

  const handleGetAnswerStatus = (
    type: string,
    value: number,
  ): 'DISABLED' | 'ENABLED' | 'QUESTION_ENABLED' => {
    if (answerArray[value] && answerArray[value] === type) {
      return 'QUESTION_ENABLED'
    } else if (answerArray[value] || answerArray.includes(type)) {
      return 'DISABLED'
    } else {
      return 'ENABLED'
    }
  }

  const handleStoreQuestion = () => {
    const storageAnswerArrayCopy = storageAnswerArray
    if (storageAnswerArrayCopy[step - 1]) {
      storageAnswerArrayCopy[step - 1][pageNumber - 1] = answerArray
    } else {
      storageAnswerArrayCopy.push([answerArray])
    }
    localStorage.setItem(
      '@ipersonality:answers',
      JSON.stringify(storageAnswerArrayCopy),
    )
    setStorageAnswerArray(storageAnswerArrayCopy)
  }

  const handleNextSession = () => {
    if (showIntroduction) {
      setShowIntroduction(!showIntroduction)
    } else {
      handleStoreQuestion()
      const actualPage = pageNumber + 1
      if (actualPage !== 11) {
        setAnswerArray(
          storageAnswerArray[step - 1][actualPage - 1]
            ? storageAnswerArray[step - 1][actualPage - 1]
            : [],
        )
        setPageNumber(actualPage)
      } else if (actualPage === 11 && step !== 3) {
        const nextStep = step + 1
        if (nextStep === 3) {
          setAnswerQuantity(10)
        }
        setPageNumber(1)
        setStep(step + 1)
        if (storageAnswerArray[nextStep - 1]) {
          setAnswerArray(
            storageAnswerArray[nextStep - 1][0]
              ? storageAnswerArray[nextStep - 1][0]
              : [],
          )
        } else {
          setAnswerArray([])
        }

        setShowIntroduction(true)
      } else {
        handleSubmitForms()
      }
    }
  }

  const handlePreviousSession = () => {
    if (showIntroduction) {
      setShowIntroduction(!showIntroduction)
      const previousStep = step - 1
      if (previousStep === 2 || previousStep === 1) {
        setAnswerQuantity(4)
      }
      setAnswerArray(storageAnswerArray[previousStep - 1][9])
      setStep(previousStep)
      setPageNumber(10)
    } else {
      if (pageNumber === 1) {
        setShowIntroduction(true)
      } else {
        const actualPage = pageNumber - 1
        setPageNumber(actualPage)
        setAnswerArray(storageAnswerArray[step - 1][actualPage - 1])
      }
    }
  }

  const handleSubmitForms = async () => {
    let ignore = false
    const dataFormSubmit = {
      status: 'COMPLETED',
      is_paid: true,
      answer: storageAnswerArray,
      steps_json: step,
      report_status: 'CREATED', // Alterado dia 17-01 para atender a alteração do jonas de NOT_STARTED para CREATED
      completed_at: new Date(),
    }

    setIsLoading(true)

    try {
      const result = await api.put(`/v1/assessments/update`, dataFormSubmit)
      if (!ignore) {
        if (result.status === 200) {
          toast.success(<Translator path="selfKnowledge.form.success" />)
          formResult()
        }
      }
    } catch (error) {
      console.log('error', error)
      toast.error(<Translator path="selfKnowledge.form.error" />)
    } finally {
      setIsLoading(false)
    }

    return () => {
      ignore = true
    }
  }

  return (
    <div className="flex w-full lg:min-w-[30rem] lg:w-[65%] flex-col h-fit px-3 lg:px-8 py-8 gap-6 bg-white rounded-lg">
      <FormHeader step={step} />

      {errorMessage && (
        <div className="bg-red rounded-md text-white text-center p-2">
          {errorMessage}
        </div>
      )}

      {showIntroduction ? (
        <NextStage
          next={() => handleNextSession()}
          back={() => handlePreviousSession()}
          stage={step}
        />
      ) : (
        <>
          { !errorMessage && (
            <>
              <div className="flex flex-col lg:flex-row w-full gap-4 lg:gap-8 items-center lg:justify-between">
                <div className="">
                  <h2 className="font-semibold text-xl">Seção {step}</h2>
                </div>
                <div className="flex lg:w-fit h-fit bg-[#F3F5F6] rounded-md gap-2 p-2">
                  <span
                    className={`rounded-full w-5 h-5 ${
                      pageNumber === 1 ? 'bg-[#4C5562]' : 'bg-[#D9D9D9]'
                    }`}
                  ></span>
                  <span
                    className={`rounded-full w-5 h-5 ${
                      pageNumber === 2 ? 'bg-[#4C5562]' : 'bg-[#D9D9D9]'
                    }`}
                  ></span>
                  <span
                    className={`rounded-full w-5 h-5 ${
                      pageNumber === 3 ? 'bg-[#4C5562]' : 'bg-[#D9D9D9]'
                    }`}
                  ></span>
                  <span
                    className={`rounded-full w-5 h-5 ${
                      pageNumber === 4 ? 'bg-[#4C5562]' : 'bg-[#D9D9D9]'
                    }`}
                  ></span>
                  <span
                    className={`rounded-full w-5 h-5 ${
                      pageNumber === 5 ? 'bg-[#4C5562]' : 'bg-[#D9D9D9]'
                    }`}
                  ></span>
                  <span
                    className={`rounded-full w-5 h-5 ${
                      pageNumber === 6 ? 'bg-[#4C5562]' : 'bg-[#D9D9D9]'
                    }`}
                  ></span>
                  <span
                    className={`rounded-full w-5 h-5 ${
                      pageNumber === 7 ? 'bg-[#4C5562]' : 'bg-[#D9D9D9]'
                    }`}
                  ></span>
                  <span
                    className={`rounded-full w-5 h-5 ${
                      pageNumber === 8 ? 'bg-[#4C5562]' : 'bg-[#D9D9D9]'
                    }`}
                  ></span>
                  <span
                    className={`rounded-full w-5 h-5 ${
                      pageNumber === 9 ? 'bg-[#4C5562]' : 'bg-[#D9D9D9]'
                    }`}
                  ></span>
                  <span
                    className={`rounded-full w-5 h-5 ${
                      pageNumber === 10 ? 'bg-[#4C5562]' : 'bg-[#D9D9D9]'
                    }`}
                  ></span>
                </div>
              </div>
              <div className="flex items-center">
                <hr className="w-full hidden lg:block text-[#BDBDBD]" />
                <button
                  onClick={() => setHowAnswer(!howAnswer)}
                  className={`${
                    howAnswer ? 'bg-[#F6C615]' : 'bg-[#E5E7EB]'
                  } w-full lg:w-fit flex items-center justify-center gap-4 min-w-fit rounded py-2 px-4 font-semibold`}
                >
                  <Translator path="selfKnowledge.form.btnHowAnswer" />{' '}
                  <img src={'/assets/Question.svg'} alt="" />
                </button>
                <hr className="w-full hidden lg:block text-[#BDBDBD]" />
              </div>
              </>
            )
          }

          {howAnswer ? (
            <HowAnswer close={() => setHowAnswer(false)} step={step} />
          ) : (
            questions.map((questionInfo: QuestionInfo) => {
              return (
                <ChoiceCard
                  key={questionInfo.type}
                  question={questionInfo.name}
                  type={questionInfo.type}
                  description={questionInfo.description}
                  questions={questions}
                  onAnswerQuestion={handleAnswerQuestion}
                  handleGetAnswerStatus={handleGetAnswerStatus}
                  step={step}
                />
              )
            })
          )}
          <hr className="w-full text-[#BDBDBD] mt-2" />
          <div className="flex gap-2">
            <button
              className="w-1/3 py-3 flex items-center justify-center gap-2 lg:gap-4 bg-white text-red rounded-lg border-red border-2 disabled:opacity-50 disabled:cursor-not-allowed"
              onClick={handlePreviousSession}
              disabled={pageNumber === 1 && step === 1}
            >
              <ArrowLeft size={25} color="red" />{' '}
              <Translator path="selfKnowledge.form.btnBack" />
            </button>
            <button
              onClick={handleNextSession}
              className="w-2/3 py-3 flex items-center justify-center gap-2 lg:gap-4 bg-[#0369A1] text-white rounded-lg disabled:opacity-50 disabled:cursor-not-allowed"
              disabled={
                answerArray.includes(null) ||
                answerArray.includes(undefined) ||
                (step !== 3 && answerArray.length !== 4) ||
                (step === 3 && answerArray.length !== 10) ||
                isLoading === true
              }
            >
              <Translator path="selfKnowledge.form.btnNext" />{' '}
              <ArrowRight size={25} color="#FFFFFF" />
            </button>
          </div>
        </>
      )}

      <div className="flex justify-between font-semibold">
        <span>Precisa de ajuda ou tem alguma dúvida?</span>
        <Link to={'/'} className="text-[#2C8BB9]">
          Entre em contato conosco
        </Link>
      </div>
    </div>
  )
}

export { Form }

// if (i18n.language.toLocaleLowerCase() === 'en-us') {
//   const data = questionEN.map((item) => {
//     return {
//       step: item[0],
//       type: item[1],
//       name: item[2],
//       description: item[3],
//     }
//   })

//   const filtrado = data
//     ?.filter((item) => item.step === step)
//     ?.map((item: any) => item)
//     .slice((pageNumber - 1) * answerQuantity, pageNumber * answerQuantity)
//   setQuestions(filtrado)
// }

// if (i18n.language.toLocaleLowerCase() === 'es-es') {
//   const data = questionES.map((item) => {
//     return {
//       step: item[0],
//       type: item[1],
//       name: item[2],
//       description: item[3],
//     }
//   })

//   const filtrado = data
//     ?.filter((item) => item.step === step)
//     ?.map((item: any) => item)
//     .slice((pageNumber - 1) * answerQuantity, pageNumber * answerQuantity)
//   setQuestions(filtrado)
// }

// if (i18n.language.toLocaleLowerCase() === 'pt-br') {
//   const data = question.map((item) => {
//     return {
//       step: item[0],
//       type: item[1],
//       name: item[2],
//       description: item[3],
//     }
//   })

//   const filtrado = data
//     ?.filter((item) => item.step === step)
//     ?.map((item: any) => item)
//     .slice((pageNumber - 1) * answerQuantity, pageNumber * answerQuantity)
//   setQuestions(filtrado)
// }

// // se nenhuma das opcoes forem escolhidas -> refatorado por xarlys 08/02/2024
// if (i18n.language.toLocaleLowerCase() === 'pt'
//   || i18n.language.toLocaleLowerCase() === 'es'
//   || i18n.language.toLocaleLowerCase() === 'us'
//   || i18n.language.toLocaleLowerCase() === 'en') {
//   const data = question.map((item) => {
//     return {
//       step: item[0],
//       type: item[1],
//       name: item[2],
//       description: item[3],
//     }
//   })

//   const filtrado = data
//     ?.filter((item) => item.step === step)
//     ?.map((item: any) => item)
//     .slice((pageNumber - 1) * answerQuantity, pageNumber * answerQuantity)
//   setQuestions(filtrado)
// }
