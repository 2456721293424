import React from 'react'
import { ShareNetwork, Link, Clipboard } from '@phosphor-icons/react'
import { useAuth } from './hooks/provider/auth'
import { toast } from 'react-toastify'
import { Translator } from './I18n/Translator'

interface Props {
  icon?: boolean
  title: string | JSX.Element
  description?: string | JSX.Element
  className?: string
}

const InviteCard: React.FC<Props> = ({
  icon,
  title,
  description,
  className,
}) => {
  const { ref_code } = useAuth()

  return (
    <div
      className={`flex flex-col p-2 gap-2 rounded-md bg-white h-fit ${className}`}
    >
      <h2 className=" flex items-center gap-2 font-semibold leading-4 lg:text-lg">
        {icon ? (
          <ShareNetwork
            className="self-center justify-self-stretch text-Blue"
            size={36}
          />
        ) : null}
        {title}
      </h2>
      {description ? <p>{description}</p> : null}
      {ref_code ? (
        <div className="bg-lightGray border border-borderColor shadow-sm flex items-center p-2 m-2 rounded">
          <Link size={22} color="#6B7280" />
          <span className="w-full ml-1 lg:pl-2 truncate text-sm lg:text-base">
            {import.meta.env.VITE_DOMAIN}/invite/{ref_code}
          </span>
          <button
            onClick={() => {
              navigator.clipboard.writeText(
                `${import.meta.env.VITE_DOMAIN}/invite/${ref_code}`,
              )
              toast.info(<Translator path="component.inviteCard.info" />)
            }}
          >
            <Clipboard size={22} color="#21abeb" />
          </button>
        </div>
      ) : (
        <div className="border border-red-400 rounded-b bg-[#ffd1d1] px-4 py-3 text-red">
          <Translator path="component.inviteCard.error" />
        </div>
      )}
    </div>
  )
}

export { InviteCard }
