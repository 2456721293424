import React from 'react'

interface FlagProps {
  image?: string
  isSelected?: boolean
  props?: React.ReactNode
  onClick?: () => void
}

const I18nFlag: React.FC<FlagProps> = ({
  image,
  isSelected,
  onClick,
  ...props
}) => {
  return (
    <img
      alt="flag"
      src={image}
      className={`filter grayscale-[0.5]  ${
        isSelected ? 'filter grayscale-0 hover:grayscale-0' : ''
      }`}
      onClick={onClick}
      {...props}
    />
  )
}

export { I18nFlag }
